import './index.scss'
import { useState, useEffect } from 'react';
import MaskedInput from './../../../MaskedInput/MaskedInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserChildren } from './../../../../redux/users/children/selectors';
import { setUserChildrenDataElemEdit } from '../../../../redux/users/children/childrenSlice';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import { ChildClass, City, School } from '../../../Filters/FiltersList';
import { isCorrectDate, getDateFormatFuncServer, correctorName } from './../../../../services/clientHome';




const EditChild = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()
    const { checkChild } = useSelector(selectUserChildren);

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},

    })

    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    useEffect(()=>{
        if(checkChild.id !== 0) setFormData({...checkChild, classNumber: checkChild.classObj.number, classLetter: checkChild.classObj.letter})
        else setFormData({
            id: 0,
            name: '',
            birthDay: '',
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classNumber: '',
            classLetter: '',
            classObj: {id: 0, number: '', letter: ''},
        })
    }, [popupBack, checkChild.id])

    useEffect(()=>{
        setActiveList('')
        setError('')
    }, [popupBack])

    const editChildFunc = () =>{
        const {id, name, birthDay, city, school, classObj} = formData;

        let editData = {
            id, 
            name: correctorName(name),
            birthDay: getDateFormatFuncServer(birthDay), 
            cityId: city.id, 
            schoolId: school.id, 
            classId: classObj.id
        };
        

        if(correctorName(name) && (editData.birthDay && formData.birthDay !== '' && formData.birthDay.indexOf('_') === -1 && isCorrectDate(formData.birthDay)) && editData.cityId && editData.schoolId && editData.classId){
            setLoading(true)
            axiosProxyCatchPost({
                url: '/api/v1/parental/profile/update-refused-students', 
                data:{data: [editData]},
                resolved: () =>{
                    dispatch(setUserChildrenDataElemEdit({id, name, birthDay, city, school, classObj}))
                    closePopup()
                    setLoading(false)
                },
                rejected: (error) =>{                    
                    if(error.data && error.data.detail)setError(error.data.detail)
                    setLoading(false)
                }
            })
        }
        else if(!isCorrectDate(formData.birthDay) && formData.birthDay !== '' && formData.birthDay.indexOf('_') === -1){
            setError('Некорректная дата рождения*')
        }
        else{
            setError('Необходимо заполнить все поля*')
        }
    }

    const childIsEdited = (formData, checkData) =>{
        const {name, birthDay, city, school, classObj} = formData;
        let editData = {}
        if(name !== checkData.name) editData.name = name;
        if(birthDay !== checkData.birthDay) editData.birthDay = birthDay;
        if(city.id !== checkData.city.id) editData.cityId = city;
        if(school.id !== checkData.school.id) editData.schoolId = school;
        if(classObj.id !== checkData.classObj.id) editData.classId = classObj.id;
        
        return Object.keys(editData).length
    }

    return(
        <div className={`popup__container user-popup__container edit-profile`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
                setSearchData({city: '', school: ''})
            }
        }}>
            <label className="popup__label mb-4">ФИО</label>
            <input type={'text'} value={formData.name} className="popup__input mb-16" onInput={(e)=>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>

            <label className="popup__label mb-4">Дата рождения</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.birthDay}
                name = 'endDate'
                readOnly={false}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, birthDay: e.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
                classes={''}
            />    

            <div className='popup__error'>{error}</div>     

            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading || !childIsEdited(formData, checkChild)? 'deactive-button': ''}`} onClick={editChildFunc}>Сохранить</button>
            </div>
        </div>
    )
}

export default EditChild;