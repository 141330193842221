import MaskedInput from './../../../../../MaskedInput/MaskedInput';
import PasswordIcon from '../../../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../../../img/autorization-password-check-icon.svg'
import { useState } from 'react';

const UserBlock = ({formData, setFormData, setCommentIsActive, error, setError, closePopup, childList, checkChildId, setCheckChildId, childEdited, cancelChanges, postFunc, editChild, loading}) =>{
    const [displayPass, setDisplayPass] = useState(false)

    return(
        <form className="popup__container">
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className="popup__title">Верификация</div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label className="popup__label mb-4">ФИО</label>
            <input type={'text'} value={formData.name} className="popup__input mb-16" onInput={(event)=>{
                if(event.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !event.target.value) setFormData({...formData, name: event.target.value})
            }}/>

            <label className="popup__label mb-4">E-mail</label>
            <input type={'email'} readOnly={true} value={formData.email} className="popup__input mb-16" onInput={(event)=>{
                if((event.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && event.target.value.indexOf('..') === -1) || !event.target.value) setFormData({...formData, email: event.target.value})
            }}/>

            <label className="popup__label mb-4">Пароль</label>
            <div className="autorization__content-form-field d-flex align-center justify-between mb-16 h-54">
                <input autoComplete="new-password" type={(displayPass?'text':'password')} value={formData.password} className="autorization__content-form-field-input" onInput={(event)=>{
                    if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, password: event.target.value})
                }}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                <span></span>
            </div>

            <label className="popup__label mb-4">Телефон</label>
            <MaskedInput
                className={`popup__input mb-24`}
                mask="+7(___) ___-__-__"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.phone}
                name = 'endDate'
                readOnly={false}
                placeholder=''
                onChange={(event)=>{
                    setFormData({...formData, phone: event.target.value})
                }}
            />

            <div className='admin-popup__application-user-verification-child-list'>
                {
                    childList.map(item=>{
                        const {id, name} = item;
                        return(
                            <div className={`admin-popup__application-user-verification-child-list-item d-flex align-center ${checkChildId === id? 'active': ''}`} key={`admin-popup__application-user-verification-child-list-item-${id}`} onClick={()=>{
                                if(childEdited){
                                    setError('Чтобы сменить ребенка необходимо сохранить или отменить изменения*')
                                }
                                else{
                                    setCheckChildId(id)
                                }
                            }}>
                                <div className='admin-popup__application-user-verification-child-list-item-image'/>
                                <div className='admin-popup__application-user-verification-child-list-item-name'>{name}</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='popup__error'>{error}</div>
            

            {
                (childEdited)?(
                    <>
                        <div className={`popup__button mb-16 ${!formData.id? 'deactive-button': ''}`} onClick={editChild}>Сохранить изменения</div>
                        <div className={`popup__button cancel  ${!formData.id? 'deactive-button': ''}`} onClick={cancelChanges}>Отменить изменения</div>
                    </>
                ):(
                    <>
                        <div className={`popup__button mb-16 ${!formData.id || loading? 'deactive-button': ''}`} onClick={postFunc}>Принять</div>
                        <div className={`popup__button cancel  ${!formData.id || loading? 'deactive-button': ''}`} onClick={()=>{
                            setCommentIsActive()
                        }}>Отклонить</div>
                    </>
                )
            }
        </form>
    )
}

export default UserBlock;