import EditTeacher from "./EditTeacher";
import TeachersFilters from "./TeachersFilters";


const TeachersPopup = ({unDisplay, popupBack, type, props}) =>{
    return(        
        (type === "AddTeacher" || type === 'EditTeacher')? <EditTeacher closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "TeachersFilters")? <TeachersFilters closePopup={unDisplay} popupBack={popupBack}/>
        : null
    )
}

export default TeachersPopup;