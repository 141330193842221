import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminFinanceUserDiscountData = createAsyncThunk(
  'AdminFinanceUser/fetchAdminFinanceUserDiscountData',
  async (dataObj={id: 0}) => {
    const { data:{discount} } = axiosProxyCatchGet({
      url: `/fetchAdminFinanceUserDiscountData?`+ new URLSearchParams(dataObj), 
      resolved: ()=>{
        return {userData: discount};
      },
      rejected: ()=>{

      }
    })
  },
);


