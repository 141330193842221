import Calendar from 'react-calendar';
import "./index.scss";

function Calend({checkDate, setCheckDate, isRange=false, classes='', style=null}){
    return (
        <div className={`filters-full ${classes}`} style={style}>
            <div className={`filters-full__calendar-container`}>
                {
                    (checkDate !== null)?<Calendar onChange={setCheckDate} selectRange={isRange} value={isRange? ((checkDate !== null && typeof checkDate === 'object' && checkDate.length !== 0)? checkDate: null ): ((typeof checkDate === 'object')? checkDate: null)} className={isRange? '': 'not-range'}/>: null              
                }
            </div>         
        </div>
    )
}
export default Calend;
