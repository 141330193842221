// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__program-edit-program-type {
  width: calc(50% - 8px);
  padding: 16px;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  transition: .3s all;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  cursor: pointer; }
  .admin-popup__program-edit-program-type.active {
    border-color: #B158FF; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Programs/ProgramEdit/index.scss"],"names":[],"mappings":"AAEI;EACI,sBAAsB;EAEtB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe,EAAA;EAXlB;IAeO,qBAAqB,EAAA","sourcesContent":[".admin-popup__program-edit{\n\n    &-program-type{\n        width: calc(50% - 8px);\n        // height: 58px;\n        padding: 16px;\n        border: 1px solid #E6E6E6;\n        border-radius: 8px;\n        transition: .3s all;\n        font-size: 18px;\n        font-weight: 500;\n        line-height: 27px;\n        text-align: center;\n        cursor: pointer;\n\n\n        &.active{\n            border-color: #B158FF;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
