import './index.scss'
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { getDateFormatFuncServer, monthData, isCorrectDate, downloadFilePost, downloadFilePostP } from './../../../../../services/clientHome';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import MaskedInput from './../../../../MaskedInput/MaskedInput';
import Calend from './../../../../Calendar/Calendar';
import { Month, MonthYear } from '../../../../Filters/FiltersList';
import PopupDropListElem from './../../../../PopupDropListElem/index';

const FinanceExport1C = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        month: {id: 0, label: ''}
    })

    const [error, setError] = useState('')
    const [calendActive, setCalendActive] = useState(false)
    const [dateActive, setDateActive] = useState('')
    const [loading, setLoading] = useState([])
    const [activeList, setActiveList] = useState('')

    useEffect(()=>{
        setFormData({
            startDate: '',
            endDate: '',
            month: {id: 0, label: ''}
        })
        setCalendActive(false)
    }, [popupBack])

    const downloadPayments = () =>{
        setError('')
        if((typeof formData.startDate === 'object' || (formData.startDate.length === 10 && formData.startDate.indexOf('_') === -1 && isCorrectDate(formData.startDate))) && (typeof formData.endDate === 'object' || (formData.endDate.length === 10 && formData.endDate.indexOf('_') === -1 && isCorrectDate(formData.endDate)))){
            let postData = {
                from: typeof formData.startDate === 'object'? getDateFormatFuncServer(formData.startDate.toLocaleDateString()): getDateFormatFuncServer(formData.startDate),
                to: typeof formData.endDate === 'object'? getDateFormatFuncServer(formData.endDate.toLocaleDateString()): getDateFormatFuncServer(formData.endDate)
                // from: typeof formData.startDate === 'object'? formData.startDate: getDateFormatFuncServer(formData.startDate),
                // to: typeof formData.endDate === 'object'? formData.endDate: getDateFormatFuncServer(formData.endDate)
            }
            downloadFilePostP({url:'/api/v1/administrative/finance/get-payment-data', name:'Оплаты', type:'.json', postData, setLoading:(result)=>setLoading({...loading, paymentsLoading: result})})
        }
        else{
            setError('Необходимо выбрать период*')
        }      
        
    }

    const downloadServices = () =>{
        setError('')
        if(formData.month.id){
            downloadFilePostP({url:'/api/v1/administrative/finance/get-uslugi-data', name:'Начисления', type:'.json', postData: {month: formData.month.id}, setLoading:(result)=>setLoading({...loading, servicesLoading: result})})
        }
        else{
            setError('Необходимо выбрать месяц*')
        }
    }

    const downloadContragents = () =>{
        downloadFilePostP({url:'/api/v1/administrative/finance/get-contragent-data', name:'Контрагенты', type:'.json', postData: {data:{}}, setLoading:(result)=>setLoading({...loading, contragentsLoading: result})})
    }


    return(
        <div className={`popup__container`} onClick={(e)=>{
            if(calendActive && e.target.closest(".admin-popup__finance-export1c-calendar") === null){
                setCalendActive(false)
            }
            if(e.target.closest('.admin-popup__finance-export1c-drop-list-container') === null || e.target.classList.contains('popup__drop-list-elem')){
                setActiveList('')
            }
        }}>

            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title fs-20'>
                    Выгрузка файлов для 1С
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            

            <div className='admin-popup__finance-export1c-file-block mb-16'>
                <div className='admin-popup__finance-export1c-title d-flex justify-between align-center'>
                    <div className='admin-popup__finance-export1c-title-text'>Оплаты <span>(выберите период перед скачиванием)</span></div>
                    <div className={`admin-popup__finance-export1c-title-icon ${loading.paymentsLoading? 'deactive-button': ''}`} onClick={downloadPayments}/>
                </div>
                <div className='admin-popup__finance-export1c-date-container d-flex'>
                    <div className="wd-50-mr-16">
                        {/* <label htmlFor="" className='admin-popup__finance-export1c-label mb-4'>С какого числа</label>   */}
                        <div className='admin-popup__finance-export1c-date'>
                            <MaskedInput
                                className={`admin-popup__finance-export1c-date-input`}
                                mask="__.__.____"
                                replacement={{_ : /[0-9]/}}
                                defaultValue={(formData.startDate !== null && typeof formData.startDate === 'object')? `${formData.startDate.toLocaleDateString()}г`: formData.startDate}
                                name = 'startDate'
                                readOnly={false}
                                placeholder='С какого числа'
                                onChange={(event)=>{
                                    setFormData({...formData, startDate: event.target.value})
                                }}
                            />
                            <div className='admin-popup__finance-export1c-date-icon' onClick={()=>{
                                setDateActive('start')
                                setCalendActive(!calendActive)
                            }}/>
                        </div>
                    </div>
                    <div className="wd-50-mr-16">
                        {/* <label htmlFor="" className='admin-popup__finance-export1c-label mb-4'>По какое число</label> */}
                        <div className='admin-popup__finance-export1c-date'>
                            <MaskedInput
                                className={`admin-popup__finance-export1c-date-input`}
                                mask="__.__.____"
                                replacement={{_ : /[0-9]/}}
                                showMask={true}
                                separate={true}
                                defaultValue={(formData.endDate !== null && typeof formData.endDate === 'object')? `${formData.endDate.toLocaleDateString()}г`: formData.endDate}
                                name = 'endDate'
                                readOnly={false}
                                placeholder='По какое число'
                                onChange={(event)=>{
                                    setFormData({...formData, endDate: event.target.value})
                                }}
                            />
                            <div className='admin-popup__finance-export1c-date-icon' onClick={()=>{
                                setDateActive('end')
                                setCalendActive(!calendActive)
                            }}/>
                        </div>
                    </div>
                    <Calend classes={`admin-popup__finance-export1c-calendar ${calendActive? 'active': ''}`} checkDate={(dateActive === 'start')? formData.startDate: formData.endDate} setCheckDate={(data)=>{
                        if(dateActive === 'start')setFormData({...formData, startDate: data}) 
                        if(dateActive === 'end')setFormData({...formData, endDate: data}) 
                        setCalendActive(false)
                        setDateActive('')
                    }}/>
                </div>
                {/* <button type='button' className='admin-popup__finance-export1c-download-button'>Скачать</button> */}
            </div>


            <div className='admin-popup__finance-export1c-file-block mb-16'>
                <div className='admin-popup__finance-export1c-title d-flex justify-between align-center'>
                    <div className='admin-popup__finance-export1c-title-text'>Начисления <span>(выберите месяц перед скачиванием)</span></div>
                    <div className={`admin-popup__finance-export1c-title-icon ${loading.servicesLoading? 'deactive-button': ''}`} onClick={downloadServices}/>
                </div>
                <div className={`admin-popup__finance-export1c-drop-list-container`}>
                    <div className={`admin-popup__finance-export1c-drop-list-checked ${(activeList === 'monthList')? 'active': ''} ${(!formData.month.label)? 'label-active': ''}`} onClick={()=>{
                        setActiveList(activeList === 'monthList'? '': 'monthList')          
                    }}>
                        {(formData.month.id)? formData.month.label: 'Месяц'}
                    </div>
                    <div className={`admin-popup__finance-export1c-drop-list ${(activeList === 'monthList')? 'active': ''}`}>
                        <div className='admin-popup__finance-export1c-drop-list-scroll'>
                            {
                                monthData.map(item=>PopupDropListElem(item.label, 
                                    ()=>{
                                        setFormData({...formData, month: item})
                                    },
                                    (formData.month.id === item.id? 'active': ''),
                                    `popup-droplist-month-${item.id}`
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* <button type='button' className='admin-popup__finance-export1c-download-button'>Скачать</button> */}
            </div>

            <div className='admin-popup__finance-export1c-file-block last'>
                <div className='admin-popup__finance-export1c-title contragent d-flex justify-between align-center'>
                    <div className='admin-popup__finance-export1c-title-text'>Контрагенты</div>
                    <div className={`admin-popup__finance-export1c-title-icon ${loading.contragentsLoading? 'deactive-button': ''}`} onClick={downloadContragents}/>
                </div>
                {/* <button type='button' className='admin-popup__finance-export1c-download-button'>Скачать</button> */}
            </div>

            <div className='popup__error height-auto'>{error}</div>
        </div>
    )
}

export default FinanceExport1C;