import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminProgramsSchoolsData } from './asyncActions';

const initialState = {
  adminProgramsSchoolsData:[
  ],

  adminProgramsSchoolsPageLast: false,
  adminProgramsSchoolsScrollReset: false,
  adminProgramsSchoolsLoading: false,

  adminProgramsCheckedSchool: sessionStorage.getItem('adminProgramsCheckedSchool')? JSON.parse(sessionStorage.getItem('adminProgramsCheckedSchool')): {
    id: 0,
    city: {id: 0, name: ''},
    name: '',
    fullName: '',
    director: '',
    recalculate: false
  },

  adminProgramsSchoolFilters: {
    city: {id: 0, name: ''}
  }
};

const adminProgramsSchools = createSlice({
  name: 'AdminProgramsSchools',
  initialState,
  reducers: {
    setAdminProgramsSchoolsData(state, action) {
      state.adminProgramsSchoolsData = action.payload;      
    },
    setAdminProgramsSchoolsDataEdit(state, action) {
      state.adminProgramsSchoolsData = state.adminProgramsSchoolsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminProgramsSchoolsDataAdd(state, action) {
      state.adminProgramsSchoolsData = [...state.adminProgramsSchoolsData, action.payload];
    },
    setAdminProgramsSchoolsDataDelete(state, action) {
      state.adminProgramsSchoolsData = state.adminProgramsSchoolsData.filter(item => item.id !== action.payload);
    },
    setAdminProgramsSchoolsScrollReset(state, action) {
        state.adminProgramsSchoolsScrollReset = action.payload;      
    },


    setAdminProgramsCheckedSchool(state, action) {
        sessionStorage.setItem('adminProgramsCheckedSchool', JSON.stringify({...state.adminProgramsCheckedSchool, ...action.payload}))
        state.adminProgramsCheckedSchool = {...state.adminProgramsCheckedSchool, ...action.payload};
    },

    setAdminProgramsSchoolFilters(state, action) {
        state.adminProgramsSchoolFilters = {...state.adminProgramsSchoolFilters, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProgramsSchoolsData.pending, (state, action) => {
      state.adminProgramsSchoolsLoading = true;
    });
    builder.addCase(fetchAdminProgramsSchoolsData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
          if(action.payload.isScrolling){
            state.adminProgramsSchoolsData = [...state.adminProgramsSchoolsData, ...action.payload.data];
          }
          else
              state.adminProgramsSchoolsData = action.payload.data;

          if(action.payload.page === 0) state.adminProgramsSchoolsScrollReset = true;

          if(action.payload.data.length < 55) state.adminProgramsSchoolsPageLast = true;
          else state.adminProgramsSchoolsPageLast = false;
          
          state.adminProgramsSchoolsLoading = false;
        }
    });
    builder.addCase(fetchAdminProgramsSchoolsData.rejected, (state, action) => {
      state.adminProgramsSchoolsLoading = false;
    });
  },

});

const { actions, reducer } = adminProgramsSchools;


export default reducer;

export const { 
    setAdminProgramsSchoolsData,
    setAdminProgramsSchoolsDataEdit,
    setAdminProgramsSchoolsDataAdd,
    setAdminProgramsSchoolsDataDelete,
    setAdminProgramsSchoolsScrollReset,

    setAdminProgramsCheckedSchool,

    setAdminProgramsSchoolFilters
} = actions;

