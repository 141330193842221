import axiosProxy from '../../../../../../axiosProxy';
import { downloadFilePost, UploadFileFunc } from '../../../../../../services/clientHome';
import { getStatus } from '../../../../../User/Documents/DocumentElem';
import { useState } from 'react';


const downloadFileFunc = (e, id, sendFunc, docTypeId, setLoading) =>{    
    UploadFileFunc(e,
        (uploadFileData)=>{
            if(uploadFileData){
                let resultFile = {
                    id: id,
                    extension: uploadFileData.extension,
                    status: 1,
                    link: uploadFileData.link,
                    fileBytes: uploadFileData.fileBytes,
                };
                sendFunc(resultFile, docTypeId, setLoading)
            }  
        }
    )          
}

const FinanceDocumentElem = ({item, childId, docTypeId, confirmLoading, sendFunc=()=>{}, deleteFunc=()=>{}, confirmFunc=()=>{}, refuseFunc=()=>{}}) =>{
    const {id, layout, appId, documentId, name, date, status, extension='', comment='', link} = item;
    const statusObj = getStatus(status)
    const [loading, setLoading] = useState(false)
    return(
        <div className={`admin-finance-popup__documents-line d-flex justify-between align-center ${(status === 2)? 'confirm-line': ''}`}>
            <div className='admin-finance-popup__documents-line-item name d-flex align-center'>
                <div className='admin-finance-popup__documents-line-item-document-icon'/>
                <div className='admin-finance-popup__documents-line-item-name'>{name}</div>
            </div>
            <div className='admin-finance-popup__documents-line-item date'>{date}</div>
            <div className={`admin-finance-popup__documents-line-item status ${statusObj.color}`}>
                <div className='admin-finance-popup__documents-line-item-status-label'>{statusObj.label}</div>
                {
                    (status === -1 && comment)? <div className='admin-finance-popup__documents-line-item-status-title' data-tooltip={comment}/>: null
                }                    
            </div>
            <div className='admin-finance-popup__documents-line-item buttons d-flex justify-end align-center'>
                {
                    (status === 0)?(
                        <>
                            <div className={`admin-finance-popup__documents-line-item-button confirm ${(loading || confirmLoading === id || confirmLoading === layout)? 'deactive-button': ''}`} onClick={()=>confirmFunc(id, layout, appId, status)}/>
                            <div className={`admin-finance-popup__documents-line-item-button refuse admin-finance-popup__documents-line-item-button-refuse ${loading || confirmLoading === id || confirmLoading === layout? 'deactive-button': ''}`} onClick={(e)=>refuseFunc(e, id, appId, status)}/>
                            <div className={`admin-finance-popup__documents-line-item-button-line`}/>
                        </>
                    ): null
                }

                <div className={`admin-finance-popup__documents-line-item-button download ${(loading || confirmLoading === id || confirmLoading === layout)? 'deactive-button': ''}`} onClick={() => downloadFilePost({url:(link && status !== -1)? '/api/v1/administrative/finance/get-file?': '/api/v1/administrative/finance/get-layout?', name, type: (extension && extension.indexOf('.') === -1)? '.'+extension: extension, postData: (link && status !== -1)? {documentId: documentId? documentId: id}: {layoutId: (status === -1)? layout :id, studentId: childId}, setLoading: setLoading})}/>

                {/* <div className={`admin-finance-popup__documents-line-item-button send ${(status === 1 || status === 4)? '': 'deactive-button'}`} onClick={()=>sendFunc()}/> */}
                
                <input type="file" name='files' accept="" className='user-page__documents-doc-line-button d-none' id={`finance-documents-doc-download-input-${id}`} onChange={(e)=>downloadFileFunc(e, id, sendFunc, docTypeId, setLoading)}/>
                <label className={`admin-finance-popup__documents-line-item-button send ${(confirmLoading !== 'blocked' && (status === -1 || !status) && !(loading || confirmLoading === id || confirmLoading === layout))? '': 'deactive-button'}`} htmlFor={`finance-documents-doc-download-input-${id}`}/>

                <div className='admin-finance-popup__documents-line-item-button-line'/>
                <div className={`admin-finance-popup__documents-line-item-button delete ${(status === 1 && !(loading || confirmLoading === id || confirmLoading === layout))? '': 'deactive-button'}`} onClick={()=>deleteFunc(id, docTypeId)}/>
            </div>
        </div>
    )
}

export default FinanceDocumentElem;