import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import { useDispatch } from 'react-redux';
import { setAdminProgramsCheckedClass } from '../../../../../redux/admin/programs/classes/classesSlice';


const ClassesListElem = ({item, programsLinkRef, setPopupType}) =>{
    const {id, number, letter, studentsLength} = item;
    const dispatch = useDispatch();


    return(
        <div className={`admin__programs-list-elem cursor-def`} key={`admin__programs-classes-list-elem-${id}`} onClick={(e)=>{
            if(!e.target.classList.contains('click-button'))
                programsLinkRef.current.click()
            dispatch(setAdminProgramsCheckedClass(item))
        }}>
            <div className='admin__programs-list-elem-top d-flex justify-between'>
                <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                    <div className="admin__programs-list-elem-title-icon schcool"/>
                    <div className="admin__programs-list-elem-title-label">{number}{letter === 'группа'? ` ${letter}`: `${letter} класс`}</div>
                </div>
                <div className='admin__programs-list-elem-btn-container click-button'>
                    <div className='admin__programs-list-elem-btn setting click-button' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('EditClass')
                    }}/>
                </div>
            </div>
            <div className="admin__programs-list-elem-additionaly d-flex justify-between align-center cursor-pointer">
                <div className="admin__programs-list-elem-additionaly-icon people"/>
                <div className="admin__programs-list-elem-additionaly-label">{studentsLength} учеников</div>
            </div>
        </div>
    )
}

export default ClassesListElem;