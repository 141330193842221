import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import './index.scss'
import { convertNumber } from '../../../../../services/clientHome';
import { setTeachersName } from '../../../Programs/Programs/ProgramsListElem/ProgramsListElem';
import PopupDropListElem from '../../../../PopupDropListElem';
import { selectAdminProgramsPagePrograms } from './../../../../../redux/admin/programs/programs/selectors';
import { selectAdminProgramsClasses } from './../../../../../redux/admin/programs/classes/selectors';
import { setAdminProgramsAdditionalyProgramsDataAdd, setAdminProgramsAdditionalyProgramsDataEdit, setAdminProgramsProgramsDataAdd, setAdminProgramsProgramsDataEdit } from '../../../../../redux/admin/programs/programs/programsSlice';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { ProgramTeachers } from '../../../../Filters/FiltersList';
import { selectAdminProgramsSchools } from '../../../../../redux/admin/programs/schools/selectors';



const programType = [
    {
        id: 1,
        label: 'Основная',
    },
    {
        id: 2,
        label: 'Дополнительная',
    },
]

const ProgramEdit = ({type, popupBack, closePopup, setPopupType}) =>{
    const dispatch = useDispatch()
    const { adminProgramsCheckedProgram:checkProgram } = useSelector(selectAdminProgramsPagePrograms);
    const { adminProgramsCheckedClass:checkClass } = useSelector(selectAdminProgramsClasses);
    const { adminProgramsCheckedSchool:checkSchool } = useSelector(selectAdminProgramsSchools);

    const [loading, setLoading] = useState(false)
    const [activeList, setActiveList] = useState('')
    const [error, setError] = useState('')
    const [activeProgramType, setActiveProgramType] = useState(1)
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        lessonsCount: '',
        teachers: [],
        type: 1 
    })

    const [searchData, setSearchData] = useState({teachers: ''})    

    useEffect(()=>{
        if(type === 'AddProgram'){
            setFormData({
                name: '',
                price: '',
                lessonsCount: '',
                teachers: [],
                type: 1 
            })
            setActiveProgramType(1)
        }
        else{
            setFormData(checkProgram)
            setActiveProgramType(checkProgram.type)
        }
        setActiveList('')      
    }, [popupBack])

    const AddOrEditClassFunc = () =>{
        setError('')
        if(formData.name && formData.price && (formData.lessonsCount || type === 'EditProgram')){
        // if(formData.name && formData.summ && formData.lessonsCount && formData.teachers.length){
            let postData = {classId: checkClass.id}
            if(type === 'EditProgram') postData.id = checkProgram.id
            let resultData = {}
            // if(type === 'AddProgram'){
            //     postData = {
            //         ...postData,
            //         name: formData.name,
            //         price: +formData.price,
            //         lessensCount: +formData.lessonsCount,        
            //         type: formData.type,
            //         ////////////
            //         // septemberPrice: 916,
            //         // contractName:formData.name + ' ' + checkSchool.abbreviation,
            //     }
            //     if(formData.teachers.length) postData.teachers = formData.teachers.map(item => item.id)
            //     resultData = {
            //         name: formData.name,
            //         price: formData.price,
            //         lessonsCount: formData.lessonsCount,        
            //         type: formData.type,
            //         teachers: formData.teachers    
            //     }
            // }
            // else{
            //     postData.id = checkProgram.id;
            //     // if(formData.name !== checkProgram.name) postData.name = formData.name
            //     if(formData.type !== checkProgram.type) postData.type = formData.type
            //     if(formData.teachers.map(item=> item.id).join('') !== checkProgram.teachers.map(item=> item.id).join('')) postData.teachers = formData.teachers.map(item=> item.id)

            //     resultData = {
            //         id: checkProgram.id,
            //         type: formData.type,
            //         teachers: formData.teachers
            //     }
            // }


            postData = {
                ...postData,
                name: formData.name,
                price: +formData.price,
                lessensCount: +formData.lessonsCount,        
                type: formData.type,
                ////////////
                // septemberPrice: 916,
                // contractName:formData.name + ' ' + checkSchool.abbreviation,
            }
            if(formData.teachers.length) postData.teachers = formData.teachers.map(item => item.id)
            resultData = {
                id: checkProgram.id,
                name: formData.name,
                price: formData.price,
                lessonsCount: formData.lessonsCount,        
                type: formData.type,
                teachers: formData.teachers    
            }
            
            if(type === 'AddProgram' || Object.keys(postData).length > 1){      
                setLoading(true)
                axiosProxyCatchPost({
                    url: type === 'AddProgram'? '/api/v1/administrative/programs/create-program': '/api/v1/administrative/programs/update-program',
                    data: postData,
                    resolved: (data)=>{
                        if(type === 'AddProgram'){
                            if(formData.type === 1)dispatch(setAdminProgramsProgramsDataAdd(resultData))
                            else dispatch(setAdminProgramsAdditionalyProgramsDataAdd(resultData))
                        }
                        else{
                            if(formData.type === 1)dispatch(setAdminProgramsProgramsDataEdit(resultData))
                            else dispatch(setAdminProgramsAdditionalyProgramsDataEdit(resultData))
                        }
                        closePopup()
                        setLoading(false) 
                    },
                    rejected: (error)=>{
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                        setLoading(false) 
                    }
                })
            }
            else setError('Необходимо произвести какие-то изменения перед сохранением*')
        }
        else setError('Необходимо заполнить все поля*')
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox')){
                setActiveList('')
                setSearchData({teachers: ''})
            }
        }}> 
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    {(type==='AddProgram')? 'Создание программы': 'Настройка программы'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            <label htmlFor="" className='popup__label mb-4'>Название программы</label>
            <input name='' type="text" placeholder='' value={formData.name} className={`popup__input mb-16  ${(type === 'AddProgram')? '': 'popup__disable-input'}`} onChange={(e) => setFormData({...formData, name: e.target.value})}/>

            <div className='d-flex justify-between align-center mb-16'>
                <div className='wd-50-mr-16'>
                    <label htmlFor="" className='popup__label mb-4'>Стоимость</label>
                    <div className={`popup__additionaly-input ${(type === 'AddProgram')? '': 'popup__disable-input'}`}>
                        <input name='' type="number" placeholder='' value={formData.price} className={`popup__additionaly-input-input`} onChange={(e) => setFormData({...formData, price: e.target.value})}/>
                        <div className='popup__additionaly-input-val'>
                            {(formData.price)? convertNumber(formData.price) + '₽': ''}
                            <span>/год</span>
                        </div>
                    </div>
                </div>
                <div className='wd-50-mr-16'>
                    <label htmlFor="" className='popup__label mb-4'>Уроки</label>
                    <div className={`popup__additionaly-input ${(type === 'AddProgram')? '': 'popup__disable-input'}`}>
                        <input name='' type="number" placeholder='' value={formData.lessonsCount} className={`popup__additionaly-input-input`} onChange={(e) => setFormData({...formData, lessonsCount: e.target.value})}/>
                        <div className='popup__additionaly-input-val'>
                            {(formData.lessonsCount)? convertNumber(formData.lessonsCount) + 'урока': ''}
                            <span>/год</span>
                        </div>
                    </div>
                </div>                
            </div>

            <ProgramTeachers
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                schoolId={checkSchool.id}
                classId={checkClass.id}
                url={'/api/v1/administrative'}
            />
            
            <label htmlFor="" className='popup__label mb-4'>Тип программы</label>
            <div className='d-flex justify-between'>
                {
                    programType.map(item=>{
                        const {id, label} = item;
                        return(
                            <div key={`admin-popup__program-edit-program-type-${id}`} className={`admin-popup__program-edit-program-type ${(activeProgramType === id)? 'active': ''}`} onClick={()=>{
                                setActiveProgramType(id)
                                setFormData({...formData, type: id})
                            }}>
                                {label}
                            </div>
                        )
                    })
                }
            </div>
            <div className={`popup__error`}>{error}</div> 
            <button className={`popup__button ${loading? 'deactive-button': ''}`} onClick={AddOrEditClassFunc}>{(type==='AddProgram')? 'Создать': 'Сохранить'}</button>
            {
                (type !== 'AddProgram')? <button className={`popup__button cancel delete mt-16 ${loading? 'deactive-button': ''}`} onClick={()=>{
                    closePopup()
                    setPopupType('Delete')
                }}>Удалить программу</button>: null
            }
        </div>
    )
}

export default ProgramEdit;