// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__finance-page-payment-type-val {
  padding: 14px; }
  .admin-popup__finance-page-payment-type-val-elem, .admin-popup__finance-page-payment-type-val-drop-list-elem-label {
    width: max-content;
    padding: 4px 6px;
    font-size: 12px;
    border-radius: 4px; }
    .admin-popup__finance-page-payment-type-val-elem.sbp, .admin-popup__finance-page-payment-type-val-drop-list-elem-label.sbp {
      color: #EE7326;
      background-color: #EE73261A; }
    .admin-popup__finance-page-payment-type-val-elem.tincoff, .admin-popup__finance-page-payment-type-val-drop-list-elem-label.tincoff {
      color: #000;
      background-color: #FAE04B4D; }
    .admin-popup__finance-page-payment-type-val-elem.sber, .admin-popup__finance-page-payment-type-val-drop-list-elem-label.sber {
      color: #08A54A;
      background-color: #08A54A1A; }
    .admin-popup__finance-page-payment-type-val-elem.vtb, .admin-popup__finance-page-payment-type-val-drop-list-elem-label.vtb {
      color: #002284;
      background-color: #0022841A; }
    .admin-popup__finance-page-payment-type-val-elem.alfabank, .admin-popup__finance-page-payment-type-val-drop-list-elem-label.alfabank {
      color: #EF3124;
      background-color: #EF31241A; }

.admin-popup__finance-page-user-card-calendar {
  top: 60px;
  left: 0; }
  @media (max-height: 770px) {
    .admin-popup__finance-page-user-card-calendar {
      top: 0px; } }
  @media (max-height: 630px) {
    .admin-popup__finance-page-user-card-calendar {
      top: -100px; } }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Finance/UserPaymentHistoryFilters/index.scss"],"names":[],"mappings":"AAIQ;EACI,aAAa,EAAA;EAEb;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,kBAAkB,EAAA;IAJrB;MAOO,cAAc;MACd,2BAA2B,EAAA;IARlC;MAWO,WAAW;MACX,2BAA2B,EAAA;IAZlC;MAeO,cAAc;MACd,2BAA2B,EAAA;IAhBlC;MAmBO,cAAc;MACd,2BAA2B,EAAA;IApBlC;MAuBO,cAAc;MACd,2BAA2B,EAAA;;AAU/C;EACI,SAAS;EACT,OAAO,EAAA;EACP;IAHJ;MAIQ,QAAQ,EAAA,EAKf;EAHG;IANJ;MAOQ,WAAW,EAAA,EAElB","sourcesContent":[".admin-popup__finance-page{\n\n    &-payment-type{\n\n        &-val{\n            padding: 14px;\n\n            &-elem, &-drop-list-elem-label{\n                width: max-content;\n                padding: 4px 6px;\n                font-size: 12px;\n                border-radius: 4px;\n    \n                &.sbp{\n                    color: #EE7326;\n                    background-color: #EE73261A;\n                }\n                &.tincoff{\n                    color: #000;\n                    background-color: #FAE04B4D;\n                }\n                &.sber{\n                    color: #08A54A;\n                    background-color: #08A54A1A;\n                }\n                &.vtb{\n                    color: #002284;\n                    background-color: #0022841A;\n                }\n                &.alfabank{\n                    color: #EF3124;\n                    background-color: #EF31241A;\n                }\n            }\n        }\n\n\n        \n    }    \n}\n\n.admin-popup__finance-page-user-card-calendar{\n    top: 60px;\n    left: 0;\n    @media (max-height: 770px){\n        top: 0px;\n    }\n    @media (max-height: 630px){\n        top: -100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
