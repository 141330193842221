import ApplicationsPage from './Application';
import FinancePage from './Finance';
import './index.scss'
import ProgramsPage from './Programs';
import Sidebar from './Sidebar/Sidebar';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { setDropListName } from '../../redux/dropDownList/dropDownListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropDownListData } from './../../redux/dropDownList/selectors';
import { useEffect } from 'react';
import TeachersPage from './Teachers';
import TabelPage from './Tabel/index';

const Admin = () =>{
    const dispatch = useDispatch()
    const { listName } = useSelector(selectDropDownListData)

    

    return (
        <div className={`admin`} onClick={(e)=>{
            if(listName !== '' && !e.target.classList.contains('popupBlock-click')){
                dispatch(setDropListName(''))
            }
        }}>
            <Sidebar/>
            <Routes>
                <Route path='/' element={<ApplicationsPage/>} exact/>
                <Route path='/schools/*' element={<ProgramsPage/>} exact/>
                <Route path='/finance/*' element={<FinancePage/>} exact/>
                <Route path='/teachers/*' element={<TeachersPage/>} exact/>
                <Route path='/tabel/*' element={<TabelPage/>} exact/>
                <Route path='/*' element={<div>Страница не найдена</div>} exact/>
            </Routes>
        </div>
    )
}

export default Admin;