import { createSlice } from '@reduxjs/toolkit';
import { tabel, tabelTeacherPassDates } from '../../../defaultData/admin/tabel/tabel';
import { fetchTeacherTabelData } from './asyncActions';

const initialState = {
  teacherTabelData: [
    // ...tabel,
  ],
  teacherTabelTeacherPassDates: [
    // ...tabelTeacherPassDates
  ],

  teacherTabelIsClosed: false,
};

const teacherTabel = createSlice({
  name: 'TeacherTabel',
  initialState,
  reducers: {
    setTeacherTabelData(state, action) {
      state.teacherTabelData = action.payload;
    },
    setTeacherTabelDataLessonEdit(state, action){
      state.teacherTabelData = state.teacherTabelData.map(item=>{
        if(item.id === action.payload.id){
          return {...item, lessons: item.lessons.map(child=>(child.date === action.payload.date)? {...child, value: action.payload.value}: child)}          
        }
        else return item
      })
    },
    setTeacherTabelDataTeacherPass(state, action) {
      state.teacherTabelTeacherPassDates = (state.teacherTabelTeacherPassDates.indexOf(action.payload.date) !== -1)? state.teacherTabelTeacherPassDates.filter(date => date !== action.payload.date): [...state.teacherTabelTeacherPassDates, action.payload.date]
    },

    setTeacherTabelDataReplace(state, action) {
      state.teacherTabelData = action.payload.tabel;
      state.teacherTabelTeacherPassDates = action.payload.passDates;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherTabelData.pending, (state, action) => {
      state.teacherTabelData = [];
      state.teacherTabelTeacherPassDates = [];
      state.teacherTabelIsClosed = false;
    });
    builder.addCase(fetchTeacherTabelData.fulfilled, (state, action) => {
            
      if(action.payload && action.payload.tabel && action.payload.tabel && Array.isArray(action.payload.tabel)){
        console.log(action.payload);
        state.teacherTabelData = action.payload.tabel;
        state.teacherTabelTeacherPassDates = action.payload.passDates;
        state.teacherTabelIsClosed = action.payload.isClosed;
      }            
    });
    builder.addCase(fetchTeacherTabelData.rejected, (state, action) => {
        state.loading = false;
    });
  },

});

const { actions, reducer } = teacherTabel;


export default reducer;

export const { 
  setTeacherTabelData,
  setTeacherTabelDataTeacherPass,

  setTeacherTabelDataReplace,
} = actions;

