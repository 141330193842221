import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminProgramsClassesData } from './asyncActions';

const initialState = {
  adminProgramsClassesData:[
  ],

  adminProgramsClassesPageLast: false,
  adminProgramsClassesScrollReset: false,
  adminProgramsClassesLoading: false,

  adminProgramsCheckedClass: sessionStorage.getItem('adminProgramsCheckedClass')? JSON.parse(sessionStorage.getItem('adminProgramsCheckedClass')):  {
    id: 0,
    number: '', 
    letter: '',
    school: {id: 0, name: ''},
  }
};

const adminProgramsClasses = createSlice({
  name: 'AdminProgramsClasses',
  initialState,
  reducers: {
    setAdminProgramsClassesData(state, action) {
      state.adminProgramsClassesData = action.payload;      
    },
    setAdminProgramsClassesDataEdit(state, action) {
      state.adminProgramsClassesData = state.adminProgramsClassesData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminProgramsClassesDataAdd(state, action) {
      state.adminProgramsClassesData = [...state.adminProgramsClassesData, action.payload];
    },
    setAdminProgramsClassesDataDelete(state, action) {
      state.adminProgramsClassesData = state.adminProgramsClassesData.filter(item => item.id !== action.payload);
    },
    setAdminProgramsClassesScrollReset(state, action) {
        state.adminProgramsClassesScrollReset = action.payload;      
    },


    setAdminProgramsCheckedClass(state, action) {
        sessionStorage.setItem('adminProgramsCheckedClass', JSON.stringify(action.payload))
        state.adminProgramsCheckedClass = action.payload;      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProgramsClassesData.pending, (state, action) => {
        state.adminProgramsClassesLoading = true;
    });
    builder.addCase(fetchAdminProgramsClassesData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminProgramsClassesData = [...state.adminProgramsClassesData, ...action.payload.data];
            }
            else
                state.adminProgramsClassesData = action.payload.data;
    
            if(action.payload.page === 0) state.adminProgramsClassesScrollReset = true;
    
            if(action.payload.data.length < 55) state.adminProgramsClassesPageLast = true;
            else state.adminProgramsClassesPageLast = false;
            state.adminProgramsClassesLoading = false;
        }

    });
    builder.addCase(fetchAdminProgramsClassesData.rejected, (state, action) => {
        state.adminProgramsClassesLoading = false;
    });
  },

});

const { actions, reducer } = adminProgramsClasses;


export default reducer;

export const { 
    setAdminProgramsClassesData,
    setAdminProgramsClassesDataEdit,
    setAdminProgramsClassesDataAdd,
    setAdminProgramsClassesDataDelete,
    setAdminProgramsClassesScrollReset,

    setAdminProgramsCheckedClass,

} = actions;

