const PopupDropListElem = (label, clickFunk, classes, key, type='listElem') =>{
    if(type === 'listElem'){
        return(
            <div className={`popup__drop-list-elem ${classes}`} onClick={clickFunk} key={key}>{label}</div>
        )
    }
    else{
        return(
            <div className={`popup__drop-list-elem-checkbox ${classes}`} onClick={clickFunk} key={key}>{label}</div>
        )
    }
}

export default PopupDropListElem;