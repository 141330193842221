import './index.scss'
import Tabel from './Tabel/Tabel';

const TabelPage = () =>{
    return (
        <div className={`admin__tabel pl-sidebar`}>
            <Tabel/>
        </div>
    )
}

export default TabelPage;