
import { convertNumber } from './../../../../../../services/clientHome';
const ProgramElem = ({item}) =>{
    const {id, name, date, summ} = item;
    return(
        <div className={`admin__finance-user-card-child-list-item-program-list-item`}>
            <div className='admin__finance-user-card-child-list-item-program-list-item-name d-flex align-center mb-8'>
                <div className='admin__finance-user-card-child-list-item-program-list-item-name-icon'/>
                <div>{name}</div>
            </div>
            <div className='admin__finance-user-card-child-list-item-program-list-item-date-summ d-flex align-center'>
                <div className="admin__finance-user-card-child-list-item-program-list-item-date-summ-item mr-16">
                    <span>Дата: </span> {date}
                </div>
                <div className="admin__finance-user-card-child-list-item-program-list-item-date-summ-item">
                    <span>Сумма: </span> {convertNumber(summ)}₽
                </div>
            </div>
        </div>
    )
}

export default ProgramElem;