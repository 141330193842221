import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';


export const fetchTeacherTabelData = createAsyncThunk(
  'TeacherTabel/fetchTeacherTabelData',
  async (dataObj={filters:{}}) => {    
    try {
      const response = await axiosProxy.get(`/api/v1/teaching/tabel/get-students?`+ new URLSearchParams(dataObj.filters))      
      if(response.status < 400){
        let students = response.data.data;
        if(students.length){
          const response = await axiosProxy.get(`/api/v1/teaching/table/get-table?`+ new URLSearchParams(dataObj.filters))
          if(response.status < 400){

            let tabelData = response.data.data;
            if(tabelData){
              let studentsId = students.map(item=>item.id)
              tabelData = JSON.parse(tabelData);
              if(tabelData.tabel && tabelData.tabel.length){
                let tabelStudentsId = tabelData.tabel.map(item=>item.id)
                let newStudents = students.filter(item=> tabelStudentsId.indexOf(item.id) === -1)
                if(!newStudents.length){
                  tabelData.tabel = tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1)
                }
                else{
                  if(tabelData.tabel[0].lessons && tabelData.tabel[0].lessons.length){
                    let lessons = tabelData.tabel[0].lessons.map(item=>({...item, value: ''}))
                    newStudents = newStudents.map(item=>({...item, lessons}))
                  }
                  tabelData.tabel = [...tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1), ...newStudents]
                }
                
              }
            }
            else{
              tabelData = {
                tabel: students,
                passDates: []
              }
            }

            return {tabel: tabelData.tabel.sort((a, b) => a.name > b.name ? 1 : -1), passDates: tabelData.passDates, isClosed: (response.data.isClosed)? response.data.isClosed: false};
          }
          else{
            // console.log(response.response.statusText)
          } 
        }
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);









// export const fetchTeacherTabelData = createAsyncThunk(
//   'TeacherTabel/fetchTeacherTabelData',
//   async (dataObj={filters:null}) => {
//     console.log(66666666);
//     try {
//       axiosProxy.get(`/api/v1/teaching/tabel/get-students?`+ new URLSearchParams(dataObj.filters)).then((response)=>{
//         console.log(response.status, response.data.data);
//         if(response.status < 400){
//           let students = response.data.data;
//           if(students.length){
//             axiosProxy.get(`/api/v1/teaching/table/get-table?`+ new URLSearchParams(dataObj.filters)).then((tabelResponse)=>{
//               if(tabelResponse.status < 400){
//                 let tabelData = tabelResponse.data.data;
//                 console.log(tabelData);
//                 if(tabelData){
//                   let studentsId = students.map(item=>item.id)
//                   tabelData = JSON.parse(tabelData);
//                   if(tabelData.tabel && tabelData.tabel.length) tabelData.tabel = tabelData.tabel.filter(item=>studentsId.indexOf(item.id) === -1)
//                 }
//                 else{
//                   tabelData = {
//                     tabel: students.map(item =>{
//                       return{...item, lessons: []}
//                     }),
//                     passDates: []
//                   }
//                 }

//                 console.log(tabelData);
//                 return {tabel: tabelData.tabel, passDates: tabelData.passDates};                    
//               }
//               else{
//                 // console.log(response.response.statusText)
//               }
//             })  
//           }
//         }
//         else{
//           // console.log(response.response.statusText)
//         }      
//       })
      
//     } catch (error) {
//         // console.log(error);
//     }
//   },
// );


