import Navbar from './Navbar/Navbar';
import './index.scss'

import Logo from '../../../img/logo.svg'
import HeaderProfileBlock from '../../HeaderProfileBlock';
import { selectUserProfile } from './../../../redux/users/profile/selectors';
import { selectAuthorizationData } from './../../../redux/authorization/selectors';
import { useSelector } from 'react-redux';

const navbarData = [
    {
        label: 'О себе',
        link: '/',
    },
    {
        label: 'Документы',
        link: '/user-documents',
    },
    {
        label: 'Финансы',
        link: '/finance',
    },
    // {
    //     label: 'Табель',
    //     link: '/grades-list',
    // },
]

const Header = () =>{
    const { profileData } = useSelector(selectUserProfile);

    return (
        <header className={`user-page__header d-flex justify-center align-center`}>
            <div className="user-wrap d-flex justify-between align-center">
                <img src={Logo} className='user-page__header-logo'/>
                {
                    (profileData.status > 0)? <Navbar navbarData={navbarData} financeAccess={profileData.financeAccess}/>: null
                }
                {/* <Navbar navbarData={profileData.confirm? navbarData: [navbarData[0]]}/> */}
                <HeaderProfileBlock user={profileData}/>
            </div>
        </header>
    )
}

export default Header;