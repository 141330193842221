import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    popupBack: '',
};

const userPopup = createSlice({
  name: 'userPopup',
  initialState,
  reducers: {
    setUserPopupBack(state, action) {
      state.popupBack = action.payload;      
    }
  },
});

const { actions, reducer } = userPopup;


export default reducer;

export const { 
  setUserPopupBack
} = actions;

