import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';
// import axios from 'axios';





export const fetchUserDocumentsData = createAsyncThunk(
  'UserDocuments/fetchUserDocumentsData',
  async (dataObj={childId:0}) => {
    // const response = await axiosProxy.get(`/api/v1/parental/document/get-documents`)
    // // const response = await axiosProxy.get(`/api/v1/parental/document/get-documents?`+ new URLSearchParams({childId: dataObj.childId}))
    // if(response.status < 400){   
    //   // console.log(response.data);   
    //   return {data: response.data.data}                       
    // }
    // else{
    //   // console.log(response.response.statusText)
    // }
    
    try {
      const response = await axiosProxy.get(`/api/v1/parental/document/get-documents?`+ new URLSearchParams({studentId: dataObj.childId}))
      if(response.status < 400){
        return {data: response.data.data}                       
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

// export const fetchUserAdditionalyDocumentsData = createAsyncThunk(
//   'UserDocuments/fetchUserAdditionalyDocumentsData',
//   async (dataObj={childId:0}) => {
//     let postData = {childId: dataObj.childId};
//     const { data:{programs} } = axiosProxy({
//       url: `/fetchUserAdditionalyDocumentsData?`+ new URLSearchParams({...postData, type: 2}), 
//       resolved: ()=>{
//         return { data: programs};
//       },
//       rejected: ()=>{

//       }
//     })
//   },
// );


export const fetchUserAegrotatsData = createAsyncThunk(
  'UserPaymentHistory/fetchUserAegrotatsData',
  async (dataObj={count:10, page:0, isScrolling: false, childId:0}) => {
    let postData = {count: dataObj.count, page: dataObj.page, studentId: dataObj.childId};

    try {
      const response = await axiosProxy.get(`/api/v1/parental/document/get-certificate?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


