import MaskedInput from './../../../../../MaskedInput/MaskedInput';
import PasswordIcon from '../../../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../../../img/autorization-password-check-icon.svg'

const EditDataBlock = ({formData, setFormData, displayPass, setDisplayPass}) =>{
    return(
        <div className={`admin-popup__edit-data-confirm-edit wd-50-m16`}>
            <div className='admin-popup__edit-data-confirm-subtitle'>Новые данные</div>
                
            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input name='' type="text" placeholder='' value={formData.name} className="popup__input mb-16 border-active" onChange={(e) =>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>
            
            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <MaskedInput
                className={`popup__input mb-16 border-active`}
                mask="+7(___) ___-__-__"
                replacement={ { _ : /[0-9]/ }}
                defaultValue={formData.phone}
                name = 'phone'
                readOnly={false}
                placeholder=''
                showMask={true}
                separate={true}
                onChange={(event)=>{
                    setFormData({...formData, phone: event.target.value})
                }}
            />

            <label htmlFor="" className='popup__label mb-4'>Пароль</label>
            <div className="autorization__content-form-field d-flex align-center justify-between mb-16 border-active h-54">
                <input autoComplete="new-password" type={(displayPass?'text':'password')} value={formData.password} className="autorization__content-form-field-input" onInput={(event)=>{
                    if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, password: event.target.value.replaceAll(' ', '')})
                }}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                <span></span>
            </div>

            <label htmlFor="" className='popup__label mb-4'>E-mail</label>
            <input name='' readOnly={true} type="mail" placeholder='' value={formData.email} className="popup__input popup__disable-input" onChange={(e) =>{
                if((e.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && e.target.value.indexOf('..') === -1) || !e.target.value) setFormData({...formData, email: e.target.value})}
            }/>
        </div>
    )
}

export default EditDataBlock;