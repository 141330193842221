import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminFinanceUserDiscountData } from './asyncActions';

const initialState = {
  adminFinanceUserDiscountData: ''
};

const adminFinanceUserDiscount = createSlice({
  name: 'AdminFinanceUserDiscount',
  initialState,
  reducers: {
    setAdminFinanceUserDiscountData(state, action) {
      state.adminFinanceUserDiscountData = action.payload;      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminFinanceUserDiscountData.pending, (state, action) => {
    });
    builder.addCase(fetchAdminFinanceUserDiscountData.fulfilled, (state, action) => {
      state.adminFinanceUserDiscountData = action.payload.discount;
    });
    builder.addCase(fetchAdminFinanceUserDiscountData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminFinanceUserDiscount;


export default reducer;

export const { 
  setAdminFinanceUserDiscountData
} = actions;

