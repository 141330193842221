export const additionalyDocuments = [
    {
        "id": "4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b",
        "name": "Доп соглашение на мат капитал",
        "startDate": null,
        "endDate": null,
        "size": 14,
        "type": 2,
        "comment": null,
        "link": "",
        "date": "",
        "recalculate": false,
        "status": null,
        "layout": "4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b",
        "appId": null,
        "extension": ""
    },
    {
        "id": "e7260596-a357-4f84-8fc6-92fcb47b30d7",
        "name": "Доп соглашение на подключение дополнительных программ",
        "startDate": null,
        "endDate": null,
        "size": 14,
        "type": 2,
        "comment": null,
        "link": "",
        "date": "",
        "recalculate": false,
        "status": null,
        "layout": "e7260596-a357-4f84-8fc6-92fcb47b30d7",
        "appId": null,
        "extension": ""
    }
]