import { convertNumber, getPaymentTypeObj } from "../../../../../services/clientHome";

const HistoryListElem = ({item}) =>{
    const {id, studentName, clientName, date, summ, paymentType} = item;
    const paymentObj = getPaymentTypeObj(paymentType)
    return (
        <div className={`admin__finance-table-line pd-12 d-flex justify-between align-center`}>
            <div className='admin__finance-page-history-table-line-item name'>
                <div className="admin__finance-page-history-table-line-item-name-child mb-6">{studentName}</div>
                <div className="admin__finance-page-history-table-line-item-name-parrent d-flex align-center">
                    <div className='admin__finance-page-history-table-line-item-name-parrent-icon'/>
                    <div className="admin__finance-page-history-table-line-item-name-parrent-name">
                        {clientName}
                    </div>
                </div>
            </div>

            <div className='admin__finance-page-history-table-line-item date-summ'>
                <div className='admin__finance-page-history-table-line-item-date-summ-item mb-6'>
                    <span>Дата: </span> {date}
                </div>
                <div className='admin__finance-page-history-table-line-item-date-summ-item'>
                    <span>Сумма: </span> {convertNumber(summ)}{summ? '₽': ''}
                </div>
            </div>

            <div className='admin__finance-page-history-table-line-item payment-type d-flex justify-center align-center'>
                <div className={`admin__finance-page-history-table-line-item-payment-type ${paymentObj.color}`}>
                    {paymentObj.name}
                </div>
            </div>
        </div>
    )
}

export default HistoryListElem;