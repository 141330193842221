import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    popupBack: '',
    deleteCheckElem: {id: 0, label: ''}
};

const popup = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setPopupBack(state, action) {
      state.popupBack = action.payload;      
    },
    setDeleteCheckElem(state, action) {
      state.deleteCheckElem = action.payload;      
    }
  },
});

const { actions, reducer } = popup;


export default reducer;

export const { 
  setPopupBack,
  setDeleteCheckElem
} = actions;

