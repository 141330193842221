// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__refusal-comment {
  position: absolute; }
  .admin-popup__refusal-comment-textarea {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    height: 150px;
    width: 100%;
    resize: none; }
    .admin-popup__refusal-comment-textarea::-webkit-scrollbar {
      width: 0; }
  .admin-popup__refusal-comment.active {
    display: block; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/RefusalComment/index.scss"],"names":[],"mappings":"AAAA;EAgBI,kBAAkB,EAAA;EAflB;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,YAAY,EAAA;IATf;MAWO,QAAQ,EAAA;EAZpB;IAkBQ,cAAc,EAAA","sourcesContent":[".admin-popup__refusal-comment{\n    &-textarea{\n        padding: 16px;\n        border-radius: 8px;\n        border: 1px solid #E6E6E6;\n        font-size: 18px;\n        font-weight: 500;\n        line-height: 21.6px;\n        height: 150px;\n        width: 100%;\n        resize: none;\n        &::-webkit-scrollbar{\n            width: 0;            \n        }\n        \n    }\n    position: absolute;\n    &.active{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
