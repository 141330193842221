import './index.scss'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';
import ProfileDataBlock from './ProfileDataBlock';

const ProfileCurrentChages = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const { profileData } = useSelector(selectUserProfile);
    const [displayPass, setDisplayPass] = useState(false)

    return(
        <div className={`popup__container user-popup__current-changes-data-container d-flex justify-between`}>
            <ProfileDataBlock data={profileData} label={'Актуальные данные'} displayPass={displayPass} setDisplayPass={setDisplayPass}/>
            <div className='user-popup__current-changes-data-line'/>
            <ProfileDataBlock data={profileData.update} currentData={profileData} label={'Редактированные данные'} displayPass={displayPass} setDisplayPass={setDisplayPass} isUpdateData={true}/>
        </div>
    )
}

export default ProfileCurrentChages;