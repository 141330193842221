import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChildClass, City, School } from '../../../../Filters/FiltersList';
import { selectAdminFinanceChilds } from '../../../../../redux/admin/finance/childs/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { getDateFormatFunc, getDateFormatFuncServer } from '../../../../../services/clientHome';
import { setAdminFinanceChildsDataEdit } from '../../../../../redux/admin/finance/childs/childsSlice';
import { isCorrectDate, correctorName } from './../../../../../services/clientHome';
import MaskedInput from './../../../../MaskedInput/MaskedInput';
import { PaginationMonth } from './../../../../Filters/FiltersList';


const FinanceEditChild = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()

    const { adminFinanceCheckedChild:checkChild } = useSelector(selectAdminFinanceChilds);

    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        month: {id: 0, label: '', year: ''}
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
    })

    useEffect(()=>{
        if(checkChild.id){
            setFormData({...checkChild, classNumber: checkChild.classObj.number, classLetter: checkChild.classObj.letter,})
        }
        else{
            setFormData({
                name: '',
                birthDay: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''},
                month: {id: 0, label: '', year: ''}
            })
        }
        
        setSearchData({
            city: '',
            school: '',
        })
        setActiveList('')
    }, [popupBack])

    const editChildPostFunk = () =>{
        setError('')
        let {id, name, city, school, classObj, birthDay, month} = formData;
        let postData = {
            id,
            name: correctorName(name),
            birthDay: getDateFormatFuncServer(birthDay),
            cityId: city.id,
            schoolId: school.id,
            classId: classObj.id,
            startDate: getDateFormatFuncServer(new Date(month.year, month.id - 1, 1).toLocaleDateString()), 
        }

        let fieldVal = {}
        if(!(birthDay.length === 10 && birthDay.indexOf('_') === -1 && isCorrectDate(birthDay)))
            fieldVal.birthDay = true;

        if(name !== checkChild.name || city.id !== checkChild.city.id || school.id !== checkChild.school.id || classObj.id !== checkChild.classObj.id || birthDay !== checkChild.birthDay || (month.id !== checkChild.month.id || month.year !== checkChild.month.year)){
            if(correctorName(name) !== '' && city.id && school.id && classObj.id && !Object.keys(fieldVal).length && (checkChild.month && checkChild.month.id)){
                setLoading(true)
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/finance/update-students-profile',
                    data: {data: [postData]},
                    resolved: (data)=>{
                        dispatch(setAdminFinanceChildsDataEdit({...formData, startMonth: month.id, startDate: new Date(month.year, month.id - 1, 1).toLocaleDateString()}))
                        setLoading(false)
                        closePopup()
                    },
                    rejected: (error)=>{
                        setLoading(false)
                        setError('Что-то пошло не так*')                      
                    }
                })
            }
            else if(!correctorName(name)){
                setError('Некорректное имя ребенка*')
            }
            else if(Object.keys(fieldVal).length){
                setError('Некорректная дата рождения*')
            }
            else setError('Необходимо заполнить все поля*')
        }
        else setError('Изменений не найдено*')
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open') && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    city: '',
                    school: '',
                })
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    О ребенке
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input type="text" value={formData.name} className='popup__input mb-16' onChange={(e)=>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>

            <label className="popup__label mb-4">Дата рождения</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.birthDay}
                name = 'endDate'
                // readOnly={false}
                readOnly={false}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, birthDay: e.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <PaginationMonth
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}
                setError={setError}
                classes={`${checkChild.isFullVerify? 'popup__disable-input': ''}`}
                isLastBlock={true}
                label={'Месяц начала занятий'}
            />


            <div className='popup__error'>{error}</div>     

            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>closePopup('')}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={editChildPostFunk}>Сохранить</button>
            </div>       
        </div>
    )
}

export default FinanceEditChild;