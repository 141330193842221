import './index.scss'
import School from './School/School';
import Classes from './Classes/Classes';
import { Routes, Route } from 'react-router-dom';
import Programs from './Programs/Programs';

const ProgramsPage = () =>{
    return(
        <div className={`admin__programs pl-sidebar`}>
            <Routes>
                <Route path='/' element={<School/>} exact/>
                <Route path='classes' element={<Classes/>} exact/>
                <Route path='classes/programs' element={<Programs/>} exact/>
            </Routes>
        </div>
    )
}

export default ProgramsPage;