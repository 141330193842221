

const CurrentDataBlock = ({data, displayPass}) =>{
    return(
        <div className={`admin-popup__edit-data-confirm-current wd-50-m16`}>
            <div className='admin-popup__edit-data-confirm-subtitle'>Старые данные</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <div className="popup__input mb-16">{data.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <div className="popup__input mb-16">{data.phone}</div>

            <label htmlFor="" className='popup__label mb-4'>Пароль</label>
            <input autoComplete="new-password" type={(displayPass?'text':'password')} value={data.password} className="popup__input mb-16" onChange={()=>{}}/>

            <label htmlFor="" className='popup__label mb-4'>E-mail</label>
            <div className="popup__input">{data.email}</div>            
        </div>
    )
}

export default CurrentDataBlock;