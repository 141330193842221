import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import StudentListElem from './StudentListElem';


import './index.scss';



const Students = ({
    data, 
    groupEdited, 
    subGroupActive, 
    teachersListActive=false, 
    checkedTeacher=null, 
    teachersData=null,
    selectedProgram={id: 0},
    checkedElemData=[], 
    classes='',
    name='Ученики',
    number='',
    setTachersListActive=()=>null,
    checkedTeacherFunc=()=>null, 
    setSubGroupActive=()=>null, 
    transferFunc=()=>null, 
    checkedElemFunc=()=>null}) =>{

    return (
        <div className={`admin__finance-container admin__programs-program-students ${classes} ${groupEdited? 'edited': ''}`}>

            <div className='admin__finance-container-top admin__programs-program-students-top d-flex justify-between align-center mb-16'>
                <div className='admin__finance-title'>{name}</div>                
                {
                    (!(selectedProgram.id || number) || (data.length < 2 && !subGroupActive && !selectedProgram.isContainsSubgroup))? null
                    :(!subGroupActive && !number)? (
                        <div className='d-flex align-center'>
                            <button className='admin__finance-button d-flex align-center' onClick={()=>setSubGroupActive(true)}>
                                <div className='admin__finance-button-label'>Создать подгруппу</div>
                            </button>
                        </div>
                    ):(number === 2)?(
                        <div className='d-flex align-center'>
                            <button className={`admin__programs-program-students-button color d-flex align-center ${checkedElemData.length === 0? 'deactive-button': ''}`} onClick={transferFunc}>
                                <div className='admin__programs-program-students-button-icon arrow-left mr-16'/>
                                <div className='admin__programs-program-students-button-label'>Перенести</div>
                            </button>
                            <button className={`admin__programs-program-students-button icon d-flex align-center justify-center ml-8 ${selectedProgram.isContainsSubgroup? 'd-none': ''}`} onClick={()=>setSubGroupActive(false)}>
                                <div className='admin__programs-program-students-button-icon close'/>
                            </button>
                        </div>
                    ):(
                        <div className='d-flex align-center'>
                            <button className={`admin__programs-program-students-button color d-flex align-center ${checkedElemData.length === 0? 'deactive-button': ''}`} onClick={transferFunc}>
                                <div className='admin__programs-program-students-button-label mr-16'>Перенести</div>
                                <div className='admin__programs-program-students-button-icon arrow-right'/>
                            </button>
                        </div>
                    )
                }
            </div>
            {
                ((subGroupActive || selectedProgram.isContainsSubgroup) && checkedTeacher !== null)?(
                    <div className='admin__programs-program-students-teachers-list-container mb-16'>
                        <div className={`admin__programs-program-students-teachers-list-checked d-flex align-center ${checkedTeacher.id? 'checked': ''} ${(teachersListActive && teachersData && teachersData.length)? 'active': ''}`} onClick={()=>setTachersListActive(teachersListActive)}>
                            <div className='admin__programs-program-students-teachers-list-checked-icon'/>
                            <div className='admin__programs-program-students-teachers-list-checked-label'>{checkedTeacher.id? checkedTeacher.name:(teachersData && teachersData.length)? 'Выберите преподавателя': 'У данной программы отсутствуют преподаватели'}</div>
                        </div>
                        <div className={`admin__programs-program-students-teachers-list ${(teachersListActive && teachersData && teachersData.length)? 'active': ''}`}>
                            {
                                teachersData.map(item =>{
                                    const {id, name} = item;
                                    return(
                                        <div className={`admin__programs-program-students-teachers-list-item d-flex align-center justify-between ${checkedTeacher.id === id? 'active': ''}`} key={`admin__programs-program-students-teachers-list-item-${number === 1? 'first-group': 'second-group'}-${id}`} onClick={()=>checkedTeacherFunc(number, item)}>
                                            {name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ):null
            }
            <div className='admin__finance-table-line title d-flex justify-between'>
                <div className='admin__programs-students-table-line-item name'>ФИО</div>
            </div>

            <div className='admin__finance-table admin__programs-program-students-list'>
                {
                    data.map(item=><StudentListElem item={item} key={`admin__programs-students-list-elem-conatiner-${item.id}`} classes={`${(checkedElemData.indexOf(item.id) !== -1)? 'active': ''} ${subGroupActive || selectedProgram.isContainsSubgroup? 'subgroup-active': ''}`} clickFunc={()=>checkedElemFunc(item.id)}/>)
                }
            </div>
        </div>
    )
}

export default Students;