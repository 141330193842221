import { useState, useEffect } from 'react';
import Students from '../Students/Students';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminProgramTeachersData } from './../../../../../redux/admin/programs/teachers/asyncActions';
import { selectAdminProgramTeachers } from './../../../../../redux/admin/programs/teachers/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminProgramsStudentsSubgroupDataEdit } from '../../../../../redux/admin/programs/students/studentsSlice';
import {setAdminProgramsProgramsDataEdit, setAdminProgramsAdditionalyProgramsDataEdit} from '../../../../../redux/admin/programs/programs/programsSlice'

const StudentsSubgroup = ({data, subGroupActive, setSubGroupActive, query, selectedProgram, setSelectedProgram,  checkClass}) =>{
    const dispatch = useDispatch();

    const { adminProgramsTeachersData:teachersData } = useSelector(selectAdminProgramTeachers);

    const [firstSubgroup, setFirstSubgroup] = useState((selectedProgram.isContainsSubgroup && data.length)? data[0]: {
        id: 0,
        name: 'Подгруппа 1',
        teacher: {
            id: 0,
            name: ''
        },
        students: data.length? data: []
    })
    const [secondSubgroup, setSecondSubgroup] = useState((selectedProgram.isContainsSubgroup && data.length > 1)? data[1]: {
        id: 0,
        name: 'Подгруппа 2',
        teacher: {
            id: 0,
            name: ''
        },
        students: []
    })



    const [firstSubgroupChecked, setFirstSubgroupChecked] = useState([])
    const [secondSubgroupChecked, setSecondSubgroupChecked] = useState([])

    const [loading, setLoading] = useState(false)
    const [isTransfer, setIsTransfer] = useState(false)
    const [listActive, setListActive] = useState('')

    useEffect(()=>{
        if(data.length && selectedProgram.isContainsSubgroup){
            setFirstSubgroup(data.length? data[0]: {...firstSubgroup, students: []})
            setSecondSubgroup((data.length > 1)? data[1]: {...secondSubgroup, students: []})
        }
    }, [data])

    useEffect(()=>{
        dispatch(fetchAdminProgramTeachersData({programId: selectedProgram.id}))
    }, [])

    const checkedTeacherFunc = (number, teacher) =>{
        if(number === 1){
            setFirstSubgroup({...firstSubgroup, teacher: teacher})
            // if(secondSubgroup.teacher.id === teacher.id) setSecondSubgroup({...secondSubgroup, teacher: {id: 0, name: ''}})
        }
        else if(number === 2){
            setSecondSubgroup({...secondSubgroup, teacher: teacher})
            // if(firstSubgroup.teacher.id === teacher.id) setFirstSubgroup({...firstSubgroup, teacher: {id: 0, name: ''}})
        }
        setIsTransfer(true)
        setListActive('')
    }

    const checkedFirstGroupElem = (id) =>{
        if(firstSubgroupChecked.filter(item => item === id).length === 0){
            setFirstSubgroupChecked([...firstSubgroupChecked, id])
        }
        else if(firstSubgroupChecked.filter(item => item === id).length !== 0){
            setFirstSubgroupChecked(firstSubgroupChecked.filter(item => item !== id))
        }
    }

    const checkedSecondGroupElem = (id) =>{
        if(secondSubgroupChecked.filter(item => item === id).length === 0){
            setSecondSubgroupChecked([...secondSubgroupChecked, id])
        }
        else if(secondSubgroupChecked.filter(item => item === id).length !== 0){
            setSecondSubgroupChecked(secondSubgroupChecked.filter(item => item !== id))
        }
    }

    const transferFirstGroupFunc = () =>{
        setSecondSubgroup({...secondSubgroup, students: [...secondSubgroup.students, ...firstSubgroup.students.filter(item => firstSubgroupChecked.indexOf(item.id) !== -1)]})
        setFirstSubgroup({...firstSubgroup, students: firstSubgroup.students.filter(item => firstSubgroupChecked.indexOf(item.id) === -1)})
        setIsTransfer(true)
        setFirstSubgroupChecked([])
    }

    const transferSecondGroupFunc = () =>{
        setFirstSubgroup({...firstSubgroup, students: [...firstSubgroup.students, ...secondSubgroup.students.filter(item => secondSubgroupChecked.indexOf(item.id) !== -1)]})
        setSecondSubgroup({...secondSubgroup, students: secondSubgroup.students.filter(item => secondSubgroupChecked.indexOf(item.id) === -1)})
        setIsTransfer(true)
        setSecondSubgroupChecked([])
    }

    const transferElemsPostFunc = async () =>{
        setLoading(false)
        if(firstSubgroup.students.length && secondSubgroup.students.length){
            axiosProxyCatchPost({
                url: selectedProgram.isContainsSubgroup? '/api/v1/administrative/programs/update-group': '/api/v1/administrative/programs/create-groups',
                data: {...firstSubgroup, students: firstSubgroup.students.map(item=>item.id), teacherId: firstSubgroup.teacher.id, classId: checkClass.id, programId: selectedProgram.id},
                resolved: (data)=>{
                    setFirstSubgroup({...firstSubgroup, id: data.id})

                    axiosProxyCatchPost({
                        url: selectedProgram.isContainsSubgroup? '/api/v1/administrative/programs/update-group': '/api/v1/administrative/programs/create-groups',
                        data: {...secondSubgroup, students: secondSubgroup.students.map(item=>item.id), teacherId: secondSubgroup.teacher.id, classId: checkClass.id, programId: selectedProgram.id},
                        resolved: (data)=>{
                            setSecondSubgroup({...secondSubgroup, id: data.id})
                            setLoading(false)
                            if(selectedProgram.type === 1) dispatch(setAdminProgramsProgramsDataEdit({id: selectedProgram.id, isContainsSubgroup: true}))
                            else if(selectedProgram.type === 2) dispatch(setAdminProgramsAdditionalyProgramsDataEdit({id: selectedProgram.id, isContainsSubgroup: true}))
                            setSelectedProgram({...selectedProgram, isContainsSubgroup: true})
                            setIsTransfer(false)
                        },
                        rejected: (error)=>{
                            // if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                            // else setError('Что-то пошло не так*')
                            setLoading(false)                        
                        }
                    })
                },
                rejected: (error)=>{
                    // if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    // else setError('Что-то пошло не так*')
                    setLoading(false)
                }
            })
        }
        else{
            axiosProxyCatchPost({
                url: '/api/v1/administrative/programs/delete-group',
                data: {id: firstSubgroup.id},
                resolved: (data)=>{
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/programs/delete-group',
                        data: {id: secondSubgroup.id},
                        resolved: (data)=>{
                            if(selectedProgram.type === 1) dispatch(setAdminProgramsProgramsDataEdit({id: selectedProgram.id, isContainsSubgroup: false}))
                            else if(selectedProgram.type === 2) dispatch(setAdminProgramsAdditionalyProgramsDataEdit({id: selectedProgram.id, isContainsSubgroup: false}))
                            setSelectedProgram({...selectedProgram, isContainsSubgroup: false})
                            dispatch(setAdminProgramsStudentsSubgroupDataEdit([]))
                            setSubGroupActive(false)
                            setSelectedProgram({id: 0})
                        },
                        rejected: (error)=>{
                            // if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                            // else setError('Что-то пошло не так*')
                            setLoading(false)                        
                        }
                    })
                },
                rejected: (error)=>{
                    // if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    // else setError('Что-то пошло не так*')
                    setLoading(false)                        
                }
            })
        }
    }

    const cancelEdits = () =>{
        setFirstSubgroup((selectedProgram.isContainsSubgroup && data.length)? data[0]: {
            id: 0,
            name: 'Подгруппа 1',
            teacher: {
                id: 0,
                name: ''
            },
            students: data.length? data: []
        })
        setSecondSubgroup((selectedProgram.isContainsSubgroup && data.length > 1)? data[1]: {
            id: 0,
            name: 'Подгруппа 2',
            teacher: {
                id: 0,
                name: ''
            },
            students: []
        })
        setIsTransfer(false)
    }


    

    return (
        <div className={`admin__programs-program-students-container ${(firstSubgroupChecked.length || secondSubgroupChecked.length || isTransfer)? 'subgroup-active': ''}`} onClick={(e)=>{
            if(e.target.closest(".admin__programs-program-students-teachers-list-container") === null){
                setListActive('')
            }
        }}>
            <div className={`d-flex justify-between`}>
                <Students 
                    classes={'admin__programs-program-students-subgroup mr-24'}
                    data={firstSubgroup.students}
                    groupEdited={(firstSubgroupChecked.length || secondSubgroupChecked.length || isTransfer)} 
                    subGroupActive={subGroupActive} 
                    setSubGroupActive={setSubGroupActive} 
                    name={firstSubgroup.name} 
                    number={1}
                    checkedElemData={firstSubgroupChecked}
                    transferFunc={transferFirstGroupFunc}
                    checkedElemFunc={checkedFirstGroupElem}
                    teachersData={teachersData}
                    checkedTeacher={firstSubgroup.teacher}
                    checkedTeacherFunc={checkedTeacherFunc}
                    teachersListActive={listActive === 'firstGroupTeachersListActive'}
                    setTachersListActive={(isActive)=>setListActive(isActive? '': 'firstGroupTeachersListActive')}
                    selectedProgram={selectedProgram}
                />
                <Students 
                    classes={'admin__programs-program-students-subgroup'}
                    data={secondSubgroup.students}
                    groupEdited={(firstSubgroupChecked.length || secondSubgroupChecked.length || isTransfer)} 
                    subGroupActive={subGroupActive} 
                    setSubGroupActive={setSubGroupActive}
                    name={secondSubgroup.name} 
                    number={2}                    
                    checkedElemData={secondSubgroupChecked}
                    transferFunc={transferSecondGroupFunc}
                    checkedElemFunc={checkedSecondGroupElem}
                    teachersData={teachersData}
                    checkedTeacher={secondSubgroup.teacher}
                    checkedTeacherFunc={checkedTeacherFunc}
                    teachersListActive={listActive === 'secondGroupTeachersListActive'}
                    setTachersListActive={(isActive)=>setListActive(isActive? '': 'secondGroupTeachersListActive')}
                    selectedProgram={selectedProgram}
                />
            </div>
            {
                (firstSubgroupChecked.length || secondSubgroupChecked.length || isTransfer)?(
                    <>
                        <div className='popup__error text-right'></div>
                        <div className='admin__programs-program-students-subgroup-buttons d-flex align-center justify-end'>
                            <button type='button' className={`admin__programs-program-students-subgroup-button cancel mr-24 ${loading? 'deactive-button': ''}`} onClick={cancelEdits}>Отменить</button>
                            <button type='button' className={`admin__programs-program-students-subgroup-button save ${(loading || (!firstSubgroup.teacher.id && firstSubgroup.students.length) || (!secondSubgroup.teacher.id && secondSubgroup.students.length))? 'deactive-button': ''}`} onClick={transferElemsPostFunc}>Сохранить</button>
                        </div>
                    </>
                ): null
            }
        </div>
        
    )
}

export default StudentsSubgroup;