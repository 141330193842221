import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosProxyCatchGet } from '../../../../axiosProxy';
import axiosProxy from './../../../../axiosProxy';


export const fetchAdminProgramTeachersData = createAsyncThunk(
  'AdminProgramTeachers/fetchAdminProgramTeachersData',
  async (dataObj={programId: 0}) => {
    let postData = {programId: dataObj.programId, page: 0, count: 100};

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/teachers/get-teachers-list?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {teachers: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);