import { getChildPaymentStatus } from "../../../../../../services/clientHome";
import ProgramElem from "../ProgramElem";
import { getMonthName } from './../../../../../../services/clientHome';


const ChildListElem = ({item, clickFunc}) =>{
    const {id, name, status, matcap, city, school, classObj, programs, startMonth:month} = item;
    let statusData = getChildPaymentStatus(matcap? 2: status)

    return(
        <div className={`admin__finance-user-card-child-list-item`}>
            <div className='admin__finance-user-card-child-list-item-top d-flex align-center mb-16'>
                <div className='admin__finance-user-card-child-list-item-child-data-icon' onClick={clickFunc}/>
                <div className='admin__finance-user-card-child-list-item-child-data'>
                    <div className='admin__finance-user-card-child-list-item-child-data-name mb-8 d-flex align-center justify-between'>
                        <div className="admin__finance-user-card-child-list-item-child-data-name-label">
                            {name} <span>({getMonthName(month)} - Май)</span>
                        </div>
                        <div className="admin__finance-user-card-child-list-item-child-data-name-status-container d-flex justify-end align-center">
                            <div className={`admin__finance-user-card-child-list-item-child-data-name-status ${statusData.color}`}>{statusData.label}</div>
                        </div>
                    </div>
                    <div className='admin__finance-user-card-child-list-item-child-data-address-school-class d-flex align-center'>
                        <div>{city.name}</div> <span/> <div>{school.name}</div> <span/> <div>{classObj.number}{classObj.letter}</div>
                    </div>
                </div>
                
            </div>
            <div className="admin__finance-user-card-child-list-item-program-list">
                {
                    programs.map(item => <ProgramElem item={item} key={`admin__finance-user-card-child-list-item-program-list-container-${item.id}`}/>)
                }
            </div>

        </div>
    )
}

export default ChildListElem;