import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectAdminProgramsSchools } from './../../../../../redux/admin/programs/schools/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminProgramsSchoolsDataAdd, setAdminProgramsSchoolsDataEdit } from '../../../../../redux/admin/programs/schools/schoolsSlice';
import { City } from './../../../../Filters/FiltersList';
import { getDateFormatFunc, getDatePostFormatFunc } from './../../../../../services/clientHome';

const SchoolEdit = ({type, popupBack, closePopup, setPopupType}) =>{
    
    const dispatch = useDispatch();

    const { adminProgramsCheckedSchool } = useSelector(selectAdminProgramsSchools);

    const [formData, setFormData] = useState({
        id: 0,
        city: {id: 0, name: ''},
        name: '',
        fullName: '',
        director: '',
        recalculate: false
    })

    const [loading, setLoading] = useState(false)

    const [activeList, setActiveList] = useState('')

    const [searchData, setSearchData] = useState({
        city: '',
    })

    const [error, setError] = useState('')

    useEffect(()=>{
        if(type === 'AddSchool'){
            setFormData({
                id: 0,
                city: {id: 0, name: ''},
                name: '',
                fullName: '',
                director: '',
                recalculate: false
            })
        }
        else{
            setFormData(adminProgramsCheckedSchool)
        }
        setError('')
        setActiveList('')
    }, [popupBack])

    const AddOrEditSchoolFunc = () =>{
        setError('')
        if(formData.city.id && formData.fullName && formData.name && formData.director){
            let postData = {}
            let resultData = {}
            if(type === 'AddSchool'){
                postData = {
                    cityId: formData.city.id,
                    schoolName: formData.fullName,
                    abbreviation: formData.name,
                    directorName: formData.director,
                    recalculate: formData.recalculate,
                    ///////////////////////////
                    // conratctDate: '2024-08-24',
                    // contractDateFrom: '2024-09-02',
                    // contractDateTo: '2025-05-30',
                    // prefix: "Р01",
                }
            }
            else{
                postData = {
                    id: formData.id,
                    cityId: formData.city.id,
                    name: formData.fullName,
                    abbreviation: formData.name,
                    director: formData.director,
                    recalculate: formData.recalculate,
                }
                resultData = {id: formData.id, recalculate: formData.recalculate}
                if(formData.city.id !== adminProgramsCheckedSchool.city.id){
                    // postData.cityId = formData.city.id;
                    resultData.city = formData.city;
                }
                if(formData.fullName !== adminProgramsCheckedSchool.fullName){
                    // postData.schoolName = formData.fullName;
                    resultData.fullName = formData.fullName;
                }                
                if(formData.name !== adminProgramsCheckedSchool.name){
                    // postData.abbreviation = formData.name;
                    resultData.name = formData.name;
                }
                if(formData.director !== adminProgramsCheckedSchool.director){
                    // postData.director = formData.director;
                    resultData.director = formData.director;
                }
            }            
            
            if(type === 'AddSchool' || Object.keys(postData).length > 2){
                setLoading(true)                
                axiosProxyCatchPost({
                    url: type === 'AddSchool'? '/api/v1/administrative/programs/create-school': '/api/v1/administrative/programs/update-school',
                    data: postData,
                    resolved: (data)=>{
                        if(type === 'AddSchool'){                    
                            dispatch(setAdminProgramsSchoolsDataAdd({
                                id: data.schoolId,
                                city: formData.city,
                                name: formData.name,
                                fullName: formData.fullName,
                                director: formData.director,
                                recalculate: formData.recalculate
                            }))
                        }
                        else{
                            dispatch(setAdminProgramsSchoolsDataEdit(resultData))
                        }
                        
                        closePopup()
                        setLoading(false)
                    },
                    rejected: (error)=>{
                        // console.log(error);
                        setLoading(false)
                        // setError(error.data.detail)
                    }
                })
            }
            else setError('Необходимо совершить какие-то изменения перед сохранением*')
        
        }
        else setError('Необходимо заполнить все поля*')
    }



    return(
        <div className={`popup__container class-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button')){
                setActiveList('')
                setSearchData({city: ''})
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    {(type==='AddSchool')? 'Создание школы': 'Настройка школы'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
                classes={`mb-16 ${type === 'AddSchool'? '': 'popup__disable-input'}`}
            />
            <label htmlFor="" className='popup__label mb-4'>Название</label>
            <textarea type="text" className='popup__textarea scool mb-16' value={formData.fullName} onChange={(e)=>{
                setFormData({...formData, fullName: e.target.value})
            }}/>

            <label htmlFor="" className='popup__label mb-4'>Аббревиатура</label>
            <input type="text" className='popup__input mb-16' value={formData.name} onChange={(e)=>{
                setFormData({...formData, name: e.target.value})
            }}/>

            <label htmlFor="" className='popup__label mb-4'>Директор</label>
            <input type="text" className='popup__input mb-16' value={formData.director} onChange={(e)=>{
                setFormData({...formData, director: e.target.value})
            }}/>

            <div className={`popup__checkbox ${formData.recalculate? 'active': ''}`} onClick={()=>{
                setFormData({...formData, recalculate: !formData.recalculate})
            }}>
                Делать перерасчет
            </div>
            
            <div className={`popup__error`}>{error}</div> 
            <button className={`popup__button ${loading? 'deactive-button': ''}`} onClick={AddOrEditSchoolFunc}>{(type==='AddSchool')? 'Создать': 'Сохранить'}</button>
            {
                (type !== 'AddSchool')? <button className={`popup__button cancel delete mt-16 ${loading? 'deactive-button': ''}`} onClick={()=>{
                    closePopup()
                    setPopupType('Delete')
                }}>Удалить школу</button>: null
            }
        </div>
    )
}

export default SchoolEdit;