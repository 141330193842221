import { useEffect, useState } from 'react'
import { axiosProxyCatchPost } from '../../../../../axiosProxy'

const CreateCity = ({type, popupBack, closePopup}) =>{
    const [formData, setFormData] = useState({
        city: ''
    })

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        setFormData({
            city: ''
        })
    }, [popupBack])

    const cfreateCityFunc = () =>{
        if(formData.city){
            let postData = {
                name: formData.city,
            }

            setLoading(true)
            
            axiosProxyCatchPost({
                url: '/api/v1/administrative/programs/create-city',
                data: postData,
                resolved: (response)=>{
                    setFormData({city: ''})
                    setLoading(false)
                },
                rejected: (error)=>{
                    if(error && error.data && error.data.detail) setError(error.data.detail)
                    else setError('Что-то пошло не так*')
                    setLoading(false)
                }
            })
        }
        else setError('Необходимо ввести название*')
    }
    return (
        <div  className={`popup__container class-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__error')){
                setError('')
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Создание города
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            <label htmlFor="" className='popup__label mb-4'>Название</label>
            <input type="text" className='popup__input' value={formData.city} onChange={(e)=>{
                setFormData({...formData, city: e.target.value})
            }}/>
            <div className={`popup__error`}>{error}</div> 
            <button type='button' className='popup__button' onClick={cfreateCityFunc}>Добавить</button>
            <button type='button' className='popup__button cancel delete mt-16' onClick={closePopup}>Отменить</button>
        </div>
    )
}

export default CreateCity;