// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__container.finanse-page-history-filter {
  width: 540px; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/index.scss"],"names":[],"mappings":"AACI;EACI,YAAY,EAAA","sourcesContent":[".admin-popup{\n    &__container.finanse-page-history-filter{\n        width: 540px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
