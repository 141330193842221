import ChildListElem from './ChildElem';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import { selectAdminFinanceChilds } from '../../../../../redux/admin/finance/childs/selectors';

import './index.scss';
import { useEffect } from 'react';
import { fetchAdminFinanceChildsData } from './../../../../../redux/admin/finance/childs/asyncActions';
import { setAdminFinanceCheckedChild } from '../../../../../redux/admin/finance/childs/childsSlice';
import { getMonthName, getDateFormatFunc } from './../../../../../services/clientHome';



const Childs = ({setPopupType, childs}) =>{
    const dispatch = useDispatch()

    const editChild = (item) =>{
        dispatch(setPopupBack('open'))
        setPopupType('FinanceEditChild')
        dispatch(setAdminFinanceCheckedChild({...item, month: {id: item.startMonth, label: getMonthName(item.startMonth), year: getDateFormatFunc(item.startDate).getFullYear()}}))
    }

    return(
        <div className={`admin__finance-container childs`}>
            <div className='admin__finance-title mb-24'>Дети</div>
            <div className='admin__finance-user-card-child-list'>
                {
                    childs.map(item => <ChildListElem item={item} clickFunc={() => editChild(item)} key={`admin__finance-user-card-child-list-item-container-${item.id}`}/>)
                }
            </div>
        </div>
    )
}

export default Childs;