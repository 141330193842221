import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';
// import axios from 'axios';





// export const fetchUserProfileData = createAsyncThunk(
//   'UserProfile/fetchUserProfileData',
//   async () => {
//      axiosProxyCatchGet({
//       url: `/api/v1/parental/get-client`, 
//       resolved: ({data:profileData})=>{
//         // console.log(profileData);
        
//         return profileData;
//       },
//       rejected: ()=>{
        
//       }
//     })
//   },
// );

export const fetchUserProfileData = createAsyncThunk(
  'UserProfile/fetchUserProfileData',
  async () => {
    try {
      const response = await axiosProxy.get('/api/v1/parental/profile/get-client')
      if(response.status === 200){
          return {data: response.data}                       
      }
      else{

      }
      
    } catch (error) {
        
    }
  },
);


