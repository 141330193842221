import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDropListName } from '../../redux/dropDownList/dropDownListSlice';
import { selectDropDownListData } from '../../redux/dropDownList/selectors';


import About from './About/About';
import Header from './Header/Header';
import Finance from './Finance/Finance';
import Documents from './Documents/Documents';


import './index.scss'
import { selectUserProfile } from './../../redux/users/profile/selectors';
import { useEffect } from 'react';


const User = () =>{
    const dispatch = useDispatch();
    const { listName } = useSelector(selectDropDownListData)
    const { profileData } = useSelector(selectUserProfile);
    

    return (
        <div className={`user`} onClick={(e)=>{
            if(listName !== '' && !e.target.classList.contains('popupBlock-click')){
                dispatch(setDropListName(''))
            }
        }}>
            <Header/>
            <div className="user-wrap">
                <Routes>
                    <Route path='/' element={<About/>} exact/>
                    {
                        (profileData.status === 1)?(
                            <>
                                <Route path='/user-documents' element={<Documents/>} exact/>
                                <Route path='/finance' element={(profileData.financeAccess)? <Finance/>: <div>Страница не доступна</div>} exact/>
                            </>
                        ): null
                    }
                    
                    <Route path='/*' element={<div>Страница не найдена</div>} exact/>
                </Routes>
            </div>
        </div>
    )
}

export default User;