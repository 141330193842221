import './index.scss'
import Navbar from './Navbar/Navbar';

const Sidebar = () =>{
    return (
        <div className={`admin__sidebar`}>
            <div className='admin__sidebar-logo'/>
            <Navbar />            
        </div>
    )
}

export default Sidebar;