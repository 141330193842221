import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminFinanceUserData: sessionStorage.getItem('adminFinanceUserData')? JSON.parse(sessionStorage.getItem('adminFinanceUserData')): {
    id: 0,
    name: '',
    email: '', 
    phone: '', 
    password: ''
  }
};

const adminFinanceUser = createSlice({
  name: 'AdminFinanceUser',
  initialState,
  reducers: {
    setAdminFinanceUserData(state, action) {
      sessionStorage.setItem('adminFinanceUserData', JSON.stringify({...state.adminFinanceUserData, ...action.payload}))
      state.adminFinanceUserData = {...state.adminFinanceCheckedClient, ...action.payload};      
    },
  },
});

const { actions, reducer } = adminFinanceUser;


export default reducer;

export const { 
  setAdminFinanceUserData
} = actions;

