import './index.scss'

const DropDownList = ({listData, label='name', checkedElem, clickFunk, listName, listIsOpen, setListIsOpen, classes='', itemClasses=''}) =>{
    const AddDropListItem = (item, key) =>{
        const {id} = item;
        return(
            <div className={`user-page__drop-down-list-item popupBlock-click ${itemClasses} ${listName} ${(id === checkedElem.id || (checkedElem.id == 0 && key === 0))? 'active': ''}`} key={`user-page_drop-down-list-${listName}-${id}`} onClick={()=>clickFunk(item)}>
                {item[label]}
            </div>
        )
    }

    return (
        <div className={`user-page__drop-down-list-container d-flex justify-center align-center popupBlock-click ${classes}`}>
            <div className={`user-page__drop-down-list-checked d-flex justify-between popupBlock-click ${itemClasses} ${listName} ${(listIsOpen===listName)? 'active': ''}`} onClick={()=>setListIsOpen((listIsOpen===listName)? '': listName)}>
                <div className={`user-page__drop-down-list-checked-label popupBlock-click mr-12 ${listName}`}>{checkedElem[label]}</div>
                <div className={`user-page__drop-down-list-checked-arrow popupBlock-click ${listName}`}/>
            </div>
            <div className={`user-page__drop-down-list popupBlock-click ${listName} ${(listIsOpen===listName)? 'active': ''}`}>
                <div className={`user-page__drop-down-list-scroll popupBlock-click ${listName}`}>
                    {
                        listData.map(AddDropListItem)
                    }
                </div>
            </div>
        </div>
    )
}

export default DropDownList;