import PasswordIcon from '../../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../../img/autorization-password-check-icon.svg'


const ProfileDataBlock = ({data, label, displayPass, setDisplayPass, currentData={}, isUpdateData=false}) =>{
    return(
        <div className={`user-popup__current-changes-data wd-50-m16`}>
            <div className='user-popup__current-changes-data-subtitle'>{label}</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.name !== data.name)? 'border-active': ''}`}>{data.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.phone !== data.phone)? 'border-active': ''}`}>{data.phone}</div>

            {/* <label htmlFor="" className='popup__label mb-4'>Пароль</label>
            <div className={`autorization__content-form-field d-flex align-center justify-between mb-16 h-54 ${(isUpdateData && currentData.password !== data.password)? 'border-active': ''}`}>
                <input autoComplete="new-password" readOnly={true} type={(displayPass?'text':'password')} value={data.password} className="autorization__content-form-field-input" onInput={()=>{}}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                <span></span>
            </div> */}

            <label htmlFor="" className='popup__label mb-4'>E-mail</label>
            <div className={`popup__input popup__disable-input ${(isUpdateData && currentData.email !== data.email)? 'border-active': ''}`}>{data.email}</div>
        </div>
    )
}

export default ProfileDataBlock;