import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from './../../../../axiosProxy';
import { getDateFormatFunc, getDateFormatFuncServer } from '../../../../services/clientHome';
import { getDatePostFormatFunc } from './../../../../services/clientHome';



export const fetchFinancePaymentHistoryData = createAsyncThunk(
  'AdminFinancePaymentHistory/fetchFinancePaymentHistoryData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', filtres:null, dates: {}}) => {
    let postData = {count: dataObj.count, page: dataObj.page};
    let dates = (dataObj.dates.from && dataObj.dates.to)? {
      from: getDatePostFormatFunc(getDateFormatFunc(dataObj.dates.from)),
      to: getDatePostFormatFunc(getDateFormatFunc(dataObj.dates.to)),
    }:{}    
    
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-payment-history?`+ new URLSearchParams({...postData, ...dataObj.filters, ...dates}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }

  },
);


export const fetchFinanceUserPaymentHistoryData = createAsyncThunk(
  'AdminFinancePaymentHistory/fetchFinanceUserPaymentHistoryData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', clientId: 0, filtres:null, date: ''}) => {
    let postData = {count: dataObj.count, page: dataObj.page, clientId: dataObj.clientId};
    let dates = (dataObj.date)? {
      from: getDatePostFormatFunc(getDateFormatFunc(dataObj.date)),
      to: getDatePostFormatFunc(getDateFormatFunc(dataObj.date)),
    }:{}   

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-payment-history?` + new URLSearchParams({...postData, ...dataObj.filters, ...(dataObj.date? dates: {})}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);