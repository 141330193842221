import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { City, School } from '../../../../Filters/FiltersList';
import { setAdminTeachersFilters } from '../../../../../redux/admin/teachers/teachers/teachersSlice';
import { selectAdminTeachers } from '../../../../../redux/admin/teachers/teachers/selectors';




const TeachersFilters = ({popupBack, closePopup, clickFunk}) =>{
    const dispatch = useDispatch()
    const { adminTecahersFilter } = useSelector(selectAdminTeachers);

    const [activeList, setActiveList] = useState('')
    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        city: {id: 0, name: ''},
        school: {id: 0, name: ''} 
    })
    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    useEffect(()=>{
        setFormData(adminTecahersFilter)
        setSearchData({city: '', school: ''})
        setActiveList('')
    }, [popupBack, adminTecahersFilter])

    
    const filtersConfirm = () =>{
        let result = {
            city: formData.city,
            school: formData.school
        }

        let postResult = {}
        if(formData.city.id) postResult.cityId = formData.city.id;
        if(formData.school.id) postResult.schoolId = formData.school.id;

        if(formData.city.id || formData.school.id){
            dispatch(setAdminTeachersFilters({...result, post: postResult}))
            closePopup()
        }
        else{
            setError('Необходимо сделать изменения')
        }
        
    }

    const filtersClean = () =>{
        setFormData({city:{id: 0, name: ''}, school: {id: 0, name: ''}})
        dispatch(setAdminTeachersFilters({city: {id: 0, name: ''}, school: {id: 0, name: ''}, post: {}}))
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input')){
                setActiveList('')
                setSearchData({city: ''})
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                recalculate={true}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList}
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                recalculate={true}
                url='/api/v1/administrative/programs'
            />

            <div className='d-flex mt-24'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={filtersClean}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${(((formData.city.id !== 0 && formData.city.id === adminTecahersFilter.city.id) || formData.city.id === 0) && ((formData.school.id !== 0 && formData.school.id === adminTecahersFilter.school.id) || formData.school.id === 0))? 'deactive-button': ''}`} onClick={filtersConfirm}>Применить</button>
            </div>       
        </div>
    )
}

export default TeachersFilters;