// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__applications-table-line-item {
  margin-right: 10px; }
  .admin__applications-table-line-item-type {
    padding: 4px 6px;
    color: #B158FF;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    background: #B158FF1A;
    border-radius: 4px;
    width: max-content; }
  .admin__applications-table-line-item.type {
    width: calc(14% - 30px); }
  .admin__applications-table-line-item.name {
    width: calc(30% - 30px); }
  .admin__applications-table-line-item.city {
    width: calc(20% - 30px); }
  .admin__applications-table-line-item.school {
    width: calc(34% - 30px); }

.admin__applications-table-line-button {
  cursor: pointer; }
  .admin__applications-table-line-button.title {
    color: #727272;
    background: none;
    cursor: default; }
  .admin__applications-table-line-button.follow {
    min-width: 80px;
    text-align: center;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background: #B158FF;
    border-radius: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Application/Applications/UsersTableElem/index.scss"],"names":[],"mappings":"AACI;EACI,kBAAkB,EAAA;EAClB;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB,EAAA;EAVzB;IAaO,uBAAuB,EAAA;EAb9B;IAgBO,uBAAuB,EAAA;EAhB9B;IAmBO,uBAAuB,EAAA;EAnB9B;IAsBO,uBAAuB,EAAA;;AAI/B;EACI,eAAe,EAAA;EADlB;IAIO,cAAc;IACd,gBAAgB;IAChB,eAAe,EAAA;EANtB;IASO,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,kBAAkB,EAAA","sourcesContent":[".admin__applications-table-line{\n    &-item{\n        margin-right: 10px;\n        &-type{\n            padding: 4px 6px;\n            color: #B158FF;\n            font-size: 12px;\n            font-weight: 500;\n            line-height: 16.8px;\n            background: #B158FF1A;\n            border-radius: 4px;\n            width: max-content;\n        }\n        &.type{\n            width: calc(14% - 30px);\n        }\n        &.name{\n            width: calc(30% - 30px);\n        }\n        &.city{\n            width: calc(20% - 30px);\n        }\n        &.school{\n            width: calc(34% - 30px);\n        }\n    }\n\n    &-button{\n        cursor: pointer;\n\n        &.title{\n            color: #727272;\n            background: none;\n            cursor: default;\n        }\n        &.follow{\n            min-width: 80px;\n            text-align: center;\n            padding: 8px 12px;\n            font-size: 12px;\n            font-weight: 500;\n            color: #fff;\n            background: #B158FF;\n            border-radius: 4px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
