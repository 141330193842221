import { useState } from 'react';
import { createPortal } from 'react-dom';

import './index.scss'

const RefusalComment = ({setCommentClose, classes, clickFunc=()=>{}, style=null}) =>{
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false)
    return(
        <div className={`popup__container admin-popup__refusal-comment comment ${classes}`} style={style}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title fs-24'>
                    Комментарий к отказу
                </div>
                <div className='popup__close' onClick={()=>{
                    setCommentClose()
                    setComment('')
                }}/>
            </div>
            <textarea className="admin-popup__refusal-comment-textarea mb-24" value={comment} onChange={(e)=>setComment(e.target.value)}/>
            <button className={`popup__button save ${loading? 'deactive-button': ''}`} onClick={()=>{
                clickFunc(comment, setLoading)
                setComment('')
            }}>Отправить</button>
        </div>
    )
}

export default RefusalComment;