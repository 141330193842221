import TabelFilters from './TabelFilters/index';


const TabelPopup = ({unDisplay, popupBack, type, props}) =>{
    return(        
        (type === "TabelFilters")? <TabelFilters closePopup={unDisplay} popupBack={popupBack}/>
        : null
    )
}

export default TabelPopup;