import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminFinanceChildsData } from './asyncActions';
import { financeUserChilds } from './../../../../defaultData/admin/finance/childs';

const initialState = {
  adminFinanceChildsData: [
    // ...financeUserChilds
  ],

  adminFinanceCheckedChild:{
    id: 0,
    name: '',
    status: 0,
    city: {
        id: 0,
        name: ''
    },
    school:{
        id: 0,
        name: ''
    },
    classObj: {id: 0, number: '', letter: ''},
    programs: []
  },

  discount: '',
};

const adminFinanceChilds = createSlice({
  name: 'AdminFinanceChilds',
  initialState,
  reducers: {
    setAdminFinanceChildsData(state, action) {
      state.adminFinanceChildsData = action.payload;      
    },
    setAdminFinanceChildsDataEdit(state, action) {
      state.adminFinanceChildsData = state.adminFinanceChildsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },

    setAdminFinanceCheckedChild(state, action) {
      state.adminFinanceCheckedChild = {...state.adminFinanceCheckedChild, ...action.payload};    
    },

    setAdminFinanceDiscount(state, action){
      state.discount = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminFinanceChildsData.pending, (state, action) => {
      state.adminFinanceChildsData = [
      ];   
      state.discount='';
    });
    builder.addCase(fetchAdminFinanceChildsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.childs && Array.isArray(action.payload.childs)){
        state.adminFinanceChildsData = action.payload.childs;
        if(action.payload.discount || action.payload.discount === 0) state.discount = action.payload.discount;
      }
    });
    builder.addCase(fetchAdminFinanceChildsData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminFinanceChilds;


export default reducer;

export const { 
  setAdminFinanceChildsData,
  setAdminFinanceChildsDataEdit,
  setAdminFinanceCheckedChild,
  setAdminFinanceDiscount
} = actions;

