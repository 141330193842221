import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminProgramsProgramsData = createAsyncThunk(
  'AdminProgramsPagePrograms/fetchAdminProgramsProgramsData',
  async (dataObj={query: '', classId: 0}) => {
    let postData = {classId: dataObj.classId};
    if(dataObj.query) postData.query = dataObj.query;

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/programs/get-programs?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {programs: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


