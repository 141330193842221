export const students = [
    {
        id: 2,
        name: 'Иванов Иван Иванович2'
    },
    {
        id: 3,
        name: 'Иванов Иван Иванович3'
    },
    {
        id: 4,
        name: 'Иванов Иван Иванович4'
    },
    {
        id: 9,
        name: 'Иванов Иван Иванович9'
    },
    {
        id: 10,
        name: 'Иванов Иван Иванович10'
    },
    {
        id: 11,
        name: 'Иванов Иван Иванович11'
    },
    {
        id: 12,
        name: 'Иванов Иван Иванович12'
    },
    {
        id: 13,
        name: 'Иванов Иван Иванович13'
    },
    {
        id: 14,
        name: 'Иванов Иван Иванович14'
    },
    {
        id: 15,
        name: 'Иванов Иван Иванович15'
    },
    {
        id: 19,
        name: 'Иванов Иван Иванович19'
    },
]

export const studentsSubgroup = [
    {
        id: 1,
        name: 'Подгруппа 1',
        teacher: {id: 2, name: 'Иванов Препод Иванович'},
        students: [
        {
            id: 2,
            name: 'Иванов Иван Иванович2'
        },
        {
            id: 3,
            name: 'Иванов Иван Иванович3'
        },
        {
            id: 4,
            name: 'Иванов Иван Иванович4'
        },
        {
            id: 9,
            name: 'Иванов Иван Иванович9'
        },
        {
            id: 10,
            name: 'Иванов Иван Иванович10'
        },
        {
            id: 11,
            name: 'Иванов Иван Иванович11'
        },
        {
            id: 12,
            name: 'Иванов Иван Иванович12'
        },
        {
            id: 13,
            name: 'Иванов Иван Иванович13'
        },
        {
            id: 14,
            name: 'Иванов Иван Иванович14'
        },
        {
            id: 15,
            name: 'Иванов Иван Иванович15'
        },
        {
            id: 19,
            name: 'Иванов Иван Иванович19'
        },
        ]
    },
    {
        id: 2,
        name: 'Подгруппа 2',
        teacher: {id: 0, name: ''},
        students: [
        {
            id: 1,
            name: 'Иванов Иван Иванович'
        },
        {
            id: 5,
            name: 'Иванов Иван Иванович6'
        },
        {
            id: 6,
            name: 'Иванов Иван Иванович6'
        },
        {
            id: 7,
            name: 'Иванов Иван Иванович7'
        },
        {
            id: 8,
            name: 'Иванов Иван Иванович8'
        },
        {
            id: 16,
            name: 'Иванов Иван Иванович16'
        },
        {
            id: 17,
            name: 'Иванов Иван Иванович17'
        },
        {
            id: 18,
            name: 'Иванов Иван Иванович18'
        },
        {
            id: 20,
            name: 'Иванов Иван Иванович20'
        },
        ]
    }
]


// export const studentsSubgroup = {
//     firstGroup: [
//         {
//             id: 1,
//             name: 'Иванов Иван Иванович'
//         },
//         {
//             id: 5,
//             name: 'Иванов Иван Иванович6'
//         },
//         {
//             id: 6,
//             name: 'Иванов Иван Иванович6'
//         },
//         {
//             id: 7,
//             name: 'Иванов Иван Иванович7'
//         },
//         {
//             id: 8,
//             name: 'Иванов Иван Иванович8'
//         },
//         {
//             id: 16,
//             name: 'Иванов Иван Иванович16'
//         },
//         {
//             id: 17,
//             name: 'Иванов Иван Иванович17'
//         },
//         {
//             id: 18,
//             name: 'Иванов Иван Иванович18'
//         },
//         {
//             id: 20,
//             name: 'Иванов Иван Иванович20'
//         },
//     ],

//     secondGroup: [
//         {
//             id: 2,
//             name: 'Иванов Иван Иванович2'
//         },
//         {
//             id: 3,
//             name: 'Иванов Иван Иванович3'
//         },
//         {
//             id: 4,
//             name: 'Иванов Иван Иванович4'
//         },
//         {
//             id: 9,
//             name: 'Иванов Иван Иванович9'
//         },
//         {
//             id: 10,
//             name: 'Иванов Иван Иванович10'
//         },
//         {
//             id: 11,
//             name: 'Иванов Иван Иванович11'
//         },
//         {
//             id: 12,
//             name: 'Иванов Иван Иванович12'
//         },
//         {
//             id: 13,
//             name: 'Иванов Иван Иванович13'
//         },
//         {
//             id: 14,
//             name: 'Иванов Иван Иванович14'
//         },
//         {
//             id: 15,
//             name: 'Иванов Иван Иванович15'
//         },
//         {
//             id: 19,
//             name: 'Иванов Иван Иванович19'
//         },
//     ]
// }