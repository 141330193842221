import './index.scss'

const PageElem = ({page, activePage, setPage}) =>{
    return(
        <div className={`admin__tabel-tabel-pages-item d-flex justify-center align-center ${(activePage === page)? 'active': ''}`} onClick={()=>setPage(page)}><div>{page}</div></div>
    )
}


const PagesBlock = ({activePage, pages, setPage}) =>{
    return(
        <div className={`admin__tabel-tabel-pages d-flex align-center`}>
            <div className='admin__tabel-tabel-pages-container d-flex align-center'>
                <div className={`admin__tabel-tabel-pages-arrow left ${activePage === 1? 'deactive-button': ''}`} onClick={()=>setPage(activePage-1)}/>
                {
                    (pages.length < 9)? pages.map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                    :(activePage < 7)? (
                        <>
                            {
                                pages.slice(0, 7).map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                            <div className='admin__tabel-tabel-pages-points d-flex justify-center align-center'><div>...</div></div>
                            {
                                pages.slice(pages.length -1).map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                        </>
                    )
                    :(activePage <= pages.length-6)? (
                        <>
                            {
                                pages.slice(0, 2).map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                            <div className='admin__tabel-tabel-pages-points d-flex justify-center align-center'><div>...</div></div>
                            {
                                [activePage-1, activePage, activePage+1].map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                            <div className='admin__tabel-tabel-pages-points d-flex justify-center align-center'><div>...</div></div>
                            {
                                pages.slice(pages.length-2).map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                        </>
                    )
                    :(activePage > pages.length-7)? (
                        <>
                            {
                                [1].map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                            <div className='admin__tabel-tabel-pages-points d-flex justify-center align-center'><div>...</div></div>
                            {
                                pages.slice(pages.length-7).map(page=> <PageElem page={page} count={pages.length} activePage={activePage} setPage={setPage} key={`admin__tabel-tabel-pages-item-${page}`}/>)
                            }
                        </>
                    ): null
                }

                <div className={`admin__tabel-tabel-pages-arrow right ${activePage === pages.length? 'deactive-button': ''}`} onClick={()=>setPage(activePage+1)}/>
            </div>
        </div>
    )
}

export default PagesBlock;