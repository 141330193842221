import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminAplicationsData, fetchAdminTeachersAplicationsData } from './asyncActions';
import { applications, teacherApplications } from '../../../../defaultData/admin/aplications/aplications';

const initialState = {
  adminAplicationsData:[
    // ...applications
  ],

  loading: false,
  pageLast: false,
  scrollReset: false,

  adminAplicationFilter: {
    applicationType: {id: '', name: ''},
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    month: [],
    classObj: {id: 0, number: '', letter: ''},
    post: {}
  },

  

  adminTeachersAplicationsData: [
    // ...teacherApplications,
  ],

  teachersAplicationsLoading: false,
  teachersAplicationsPageLast: false,
  teachersAplicationsScrollReset: false,

  adminTeachersAplicationFilter: {
    // applicationType: {id: '', name: ''},
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    // program: {id: 0, name: ''},
    // month: [],
    classObj: {id: 0, number: '', letter: ''},
    post: {}
  },
};

const adminAplications = createSlice({
  name: 'AdminAplications',
  initialState,
  reducers: {
    setAdminAplicationsData(state, action) {
      state.adminAplicationsData = action.payload;      
    },
    setAdminAplicationsDataEdit(state, action) {
      state.adminAplicationsData = state.adminAplicationsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminAplicationsDataDeleteElem(state, action) {
      state.adminAplicationsData = state.adminAplicationsData.filter(item=> item.id !== action.payload);
    },

    setAdminAplicationsScrollReset(state, action) {
      state.scrollReset = action.payload;      
    },

    setAdminApplicationFilters(state, action){
        state.adminAplicationFilter = {...state.adminAplicationFilter, ...action.payload}
    },



    setAdminTeachersAplicationsData(state, action) {
      state.adminTeachersAplicationsData = action.payload;
    },
    setAdminTeachersAplicationsDataEdit(state, action) {
      state.adminTeachersAplicationsData = state.adminTeachersAplicationsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminTeachersAplicationsDataDeleteElem(state, action) {
      state.adminTeachersAplicationsData = state.adminTeachersAplicationsData.filter(item=> item.id !== action.payload);
    },

    setAdminTeachersAplicationsScrollReset(state, action) {
      state.teachersAplicationsScrollReset = action.payload;      
    },

    setAdminTeachersApplicationFilters(state, action){
        state.adminTeachersAplicationFilter = {...state.adminTeachersAplicationFilter, ...action.payload}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminAplicationsData.pending, (state, action) => {
        state.loading = true;
    });
    builder.addCase(fetchAdminAplicationsData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminAplicationsData = [...state.adminAplicationsData, ...action.payload.data];
            }
            else
                state.adminAplicationsData = action.payload.data;
    
            if(action.payload.page === 0) state.scrollReset = true;
    
            if(action.payload.data.length < 30) state.pageLast = true;
            else state.pageLast = false;
            state.loading = false;
          }
    });
    builder.addCase(fetchAdminAplicationsData.rejected, (state, action) => {
        state.loading = false;
    });



    builder.addCase(fetchAdminTeachersAplicationsData.pending, (state, action) => {
        state.teachersAplicationsLoading = true;
    });
    builder.addCase(fetchAdminTeachersAplicationsData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminTeachersAplicationsData = [...state.adminTeachersAplicationsData, ...action.payload.data];
            }
            else
                state.adminTeachersAplicationsData = action.payload.data;
    
            if(action.payload.page === 0) state.teachersAplicationsScrollReset = true;
    
            if(action.payload.data.length < 30) state.teachersAplicationsPageLast = true;
            else state.teachersAplicationsPageLast = false;
            state.teachersAplicationsLoading = false;
        }
    });
    builder.addCase(fetchAdminTeachersAplicationsData.rejected, (state, action) => {
        state.teachersAplicationsLoading = false;
    });
  },

});

const { actions, reducer } = adminAplications;


export default reducer;

export const { 
  setAdminAplicationsData,
  setAdminAplicationsDataEdit,
  setAdminAplicationsDataDeleteElem,
  setAdminAplicationsScrollReset,

  setAdminApplicationFilters,



  setAdminTeachersAplicationsData,
  setAdminTeachersAplicationsDataEdit,
  setAdminTeachersAplicationsDataDeleteElem,
  setAdminCheckedAplication,
  setAdminTeachersAplicationsScrollReset,

  setAdminTeachersApplicationFilters
} = actions;

