import { createSlice } from '@reduxjs/toolkit';
import { fetchUserChildsData, fetchUserFinanceChildsData } from './asyncActions';
import { childs } from './../../../defaultData/user/about/childs';

const initialState = {
  homeChildrenData: [
    // ...childs
  ],
  checkChild: {
    id: 0,
    name: '', 
    classNumber: '',
    classLetter: '',
    classObj: {id: 0, number: '', letter: ''},
    birthDay: '',
    city: {id: 0, name: ''},  
    school: {id: 0, name: ''}, 
    summ: ''
  },

  financeChildrenData: [
  ]
};

const userChildren = createSlice({
  name: 'UserChildren',
  initialState,
  reducers: {
    setUserChildrenData(state, action) {
      state.homeChildrenData = action.payload;      
    },
    setUserChildrenDataElemEdit(state, action) {
      state.homeChildrenData = state.homeChildrenData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);      
    },

    setUserFinanceChildrenData(state, action) {
      state.financeChildrenData = action.payload;      
    },
    setUserFinanceChildrenDataEdit(state, action) {
      state.financeChildrenData = state.financeChildrenData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setUserFinanceChildrenDataProgramsConected(state, action) {      
      state.financeChildrenData = state.financeChildrenData.map(item =>{
        if(item.id === action.payload.childId)
          return {...item, programs: [...item.programs, action.payload.program]};
        else
          return item;
      });
    },
    setUserFinanceChildrenDataProgramsDisable(state, action) {      
      state.financeChildrenData = state.financeChildrenData.map(item =>{
        if(item.id === action.payload.childId){
          return {...item, programs: item.programs.filter(item => item.id !== action.payload.program.id)};
        }          
        else
          return item;
      });
    },

    setUserCheckChild(state, action) {
      state.checkChild = {...state.checkChild, ...action.payload};    
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserChildsData.pending, (state, action) => {
      state.homeChildrenData = []
    });
    builder.addCase(fetchUserChildsData.fulfilled, (state, action) => {
      state.homeChildrenData = (action.payload && Array.isArray(action.payload.data))? action.payload.data: [];
    });
    builder.addCase(fetchUserChildsData.rejected, (state, action) => {
    });



    builder.addCase(fetchUserFinanceChildsData.pending, (state, action) => {
    });
    builder.addCase(fetchUserFinanceChildsData.fulfilled, (state, action) => {
        state.financeChildrenData = (action.payload && Array.isArray(action.payload.data))? action.payload.data: [];
    });
    builder.addCase(fetchUserFinanceChildsData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = userChildren;


export default reducer;

export const { 
  setUserChildrenData,
  setUserChildrenDataElemEdit,
  setUserCheckChild,
  setUserFinanceChildrenData,
  setUserFinanceChildrenDataEdit,
  setUserFinanceChildrenDataProgramsConected,
  setUserFinanceChildrenDataProgramsDisable
} = actions;

