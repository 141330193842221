import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminTeachersData } from './asyncActions';
import { teachers } from '../../../../defaultData/admin/teachers/teachers';

const initialState = {
  adminTeachersData:[
    // ...teachers
  ],

  loading: false,
  pageLast: false,
  scrollReset: false,

  adminTeacherChecked: {
    id: 0,
    name: '',
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    email: '',
    password: ''
  },

  adminTecahersFilter: {
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    post: {}
  }

};

const adminTeachers = createSlice({
  name: 'AdminTeachers',
  initialState,
  reducers: {
    setAdminTeachersData(state, action) {
      state.adminTeachersData = action.payload;      
    },
    setAdminTeachersDataEdit(state, action) {
      state.adminTeachersData = state.adminTeachersData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminTeachersDataAdd(state, action) {
      state.adminTeachersData = [...state.adminTeachersData, action.payload];
    },
    setAdminTeachersDataDeleteElem(state, action) {
      state.adminTeachersData = state.adminTeachersData.filter(item=> item.id !== action.payload);
    },

    setAdminTeachersScrollReset(state, action) {
      state.scrollReset = action.payload;      
    },

    setAdminTeacherChecked(state, action){
        state.adminTeacherChecked = {...state.adminTeacherChecked, ...action.payload}
    },

    setAdminTeachersFilters(state, action){
        state.adminTecahersFilter = {...state.adminTecahersFilter, ...action.payload}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminTeachersData.pending, (state, action) => {
        state.loading = true;
    });
    builder.addCase(fetchAdminTeachersData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminTeachersData = [...state.adminTeachersData, ...action.payload.data];
            }
            else
                state.adminTeachersData = action.payload.data;
    
            if(action.payload.page === 0) state.scrollReset = true;
    
            if(action.payload.data.length < 30) state.pageLast = true;
            else state.pageLast = false;
            state.loading = false;
          }
    });
    builder.addCase(fetchAdminTeachersData.rejected, (state, action) => {
        state.loading = false;
    });
  },

});

const { actions, reducer } = adminTeachers;


export default reducer;

export const { 
  setAdminTeachersData,
  setAdminTeachersDataEdit,
  setAdminTeachersDataAdd,
  setAdminTeachersDataDeleteElem,
  setAdminTeachersScrollReset,

  setAdminTeacherChecked,

  setAdminTeachersFilters,
} = actions;

