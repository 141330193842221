import { useState } from 'react';
import { createPortal } from 'react-dom';

import '../../../../main.scss'


const DeleteBlockContainer = ({popupBack, title, subtitle, closePopup, clickFunk}) => {
    const [loading, setLoading] = useState()
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            closePopup('')
        }         
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                <div className={`popup__container admin-popup__container delete`}>
                    <div className='popup__title mb-24'>
                        {title}
                    </div>
                    <div className='popup__subtitle mb-24'>
                        {subtitle}
                    </div>
                    <div className='d-flex mt-24'>
                        <button className='popup__button wd-50-mr-16 cancel' onClick={()=>closePopup('')}>Отменить</button>
                        <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>{
                            setLoading(true)
                            clickFunk(setLoading)
                        }}>Удалить</button>
                    </div>
                </div>
            </div>
        </div>
	)
}

const DeleteBlock = ({popupBack, props = null}) =>{
    return createPortal(<DeleteBlockContainer popupBack={popupBack} title={props.title} subtitle={props.subtitle} clickFunk={props.clickFunk} closePopup={props.closePopup}/>, document.body)
}
export default DeleteBlock;