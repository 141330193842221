import { useEffect } from "react";

const ProcessingAgreement = () =>{
    useEffect(() => {
        document.title = 'Согласие на обработку персональных данных';
    }, []);

    return(
        <div className="document-container">
            <p>Согласие<br />
                на обработку персональных данных<br />
                Предоставляя настоящее согласие, пользователь, во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» свободно, своей волей и своем интересе, подтверждает свое согласие на обработку ООО "КФ 21" (ОГРН 1155027009884, ИНН 5027233072, 141701, Московская область, г. Долгопрудный, Проезд Лихачевский, д. 6, ком. 3), своих персональных данных. Данное настоящим документом Согласие является безусловным. Настоящим Согласием Пользователь подтверждает, что является субъектом предоставляемых персональных данных и/или законным представителем субъекта персональных данных, а также подтверждает достоверность предоставляемых данных.<br />
                1. Способы обработки персональных данных: <br />
                • обработка персональных данных на бумажных носителях<br />
                • обработка персональных данных в информационной системе персональных данных с использованием средств автоматизации, и без использования таких средств<br />
                • обработка смешанным способом, включающим признаки первых двух способов.<br />
                2. Состав обрабатываемых персональных данных:<br />
                • фамилия, имя, отчество<br />
                • год рождения<br />
                • месяц рождения<br />
                • дата рождения<br />
                • место рождения<br />
                • семейное положение<br />
                • пол<br />
                • адрес места жительства<br />
                • адрес регистрации<br />
                • адрес электронной почты<br />
                • номер телефона<br />
                • СНИЛС<br />
                • ИНН<br />
                • гражданство<br />
                • данные документа, удостоверяющего личность<br />
                • реквизиты банковской карты<br />
                • номер расчетного счета<br />
                • номер лицевого счета<br />
                • профессия<br />
                • должность<br />
                • сведения о трудовой деятельности (в том числе стаж работы, данные о трудовой занятости на текущее время с указанием наименования и расчетного счета организации)<br />
                • отношение к воинской обязанности<br />
                • сведения о воинском учете<br />
                • сведения об образовании<br />
                3. Цель обработки персональных данных:<br />
                • соблюдение законодательства Российской Федерации в области защиты персональных данных и иных нормативно – правовых актов в сфере защиты и обработки персональных данных;<br />
                • оказание услуг, предусмотренных сайтом, физическим лицам и субъектам ПДн, законными представителями которых являются пользователи;<br />
                • прием и обработка заявок Пользователя на получение услуг, предоставляемых Оператором ПДн, и реализованных на сайте;<br />
                • прием и обработка платежей, выполненных Пользователем в рамках получения услуг, предусмотренных сайтом;<br />
                • проведения маркетинговых, информационных, организационных рассылок, связанных с деятельностью сайта; <br />
                • выполнение иных операций, связанных с оказанием услуг, предусмотренных сайтом.<br />
                4. Перечень действий осуществляемых в рамках обработки персональных данных, на совершение которых даётся согласие:<br />
                • сбор<br />
                • запись<br />
                • систематизация<br />
                • накопление<br />
                • хранение<br />
                • уточнение (обновление, изменение)<br />
                • извлечение<br />
                • использование <br />
                • блокирование<br />
                • удаление<br />
                • уничтожение<br />
                5. Основания для обработки персональных данных:<br />
                • статья 24 Конституции Российской Федерации;<br />
                • статья 6 Федерального закона № 152-ФЗ «О персональных данных»;<br />
                • статья 16 Федерального закона N 149-ФЗ "Об информации, информационных технологиях и о защите информации".<br />
                6. Сроки обработки персональных данных:<br />
                • до достижения целей обработки, но не более 5 лет с момента предоставления согласия<br />
                7. Предоставляя настоящее согласие, Пользователь подтверждает, что ознакомлен и согласен с:<br />
                • Политикой обработки персональных данных, размещенной на сайте на странице <a href="https://kf21.ru/confidential-policy">«kf21.ru/confidential-policy»</a>;<br />
                • сроками обработки персональных данных Оператором;<br />
                • условиях и порядка отзыва согласия на обработку персональных данных.<br />
                • условия и порядок получения информации о персональных данных Пользователя, находящихся в обработке.</p><br />
            <br />
        </div>
    )
}

export default ProcessingAgreement;