import React from 'react';
import { createPortal } from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';

import '../../../main.scss'
import './index.scss';
import { selectPopup } from './../../../redux/popup/selectors';
import { setPopupBack } from '../../../redux/popup/popupSlice';
import TabelPopup from './Tabel';



const PopupContent = ({type, props}) => {
    const dispatch = useDispatch()
    const {popupBack} = useSelector(selectPopup)

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            if(type === ''){
                if(props.popupTypeEdit) props.popupTypeEdit('')
                if(props.setConectedProgramActive) props.setConectedProgramActive('')
            }
            dispatch(setPopupBack(''))
        }         
    }

    const unDisplay = () =>{
        if(type === '') props.popupTypeEdit('')
        dispatch(setPopupBack(''))
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                <TabelPopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
            </div>
        </div>
	)
}

const TeacherPopup = ({type, props = null}) =>{
    return createPortal(<PopupContent type={type} props={props} />, document.body)
}
export default TeacherPopup;