// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__finance-user-card-content {
  width: 100%;
  height: 700px; }
  .admin__finance-user-card-content-documents-and-discount {
    width: 40%; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Finance/UserCard/index.scss"],"names":[],"mappings":"AACI;EACI,WAAW;EACX,aAAa,EAAA;EAEb;IACI,UAAU,EAAA","sourcesContent":[".admin__finance-user-card{\n    &-content{\n        width: 100%;\n        height: 700px;\n        \n        &-documents-and-discount{\n            width: 40%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
