import { NavLink } from 'react-router-dom';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserNavigations } from './../../../../redux/users/navigations/selectors';
import { setUserNavBarActive } from '../../../../redux/users/navigations/userNavigationsSlice';


const Navbar = ({navbarData, financeAccess}) =>{
    const { userNavBarActive } = useSelector(selectUserNavigations);
    const dispatch = useDispatch();

    const addNavbarItem = (item, key) =>{
        const {label, link} = item;
        return(
            <NavLink to={link} className={`user-page__header-navbar-item ${(userNavBarActive===link)? 'active': ''} ${(link === '/finance' && !financeAccess)? 'deactive-button': ''}`} key={'user-page__header-navbar-'+key} onClick={()=>dispatch(setUserNavBarActive(key))}>
                {label}
            </NavLink>
        )
    }

    return (
        <div className={`user-page__header-navbar d-flex justify-between align-center`}>
            
            <div className={`user-page__header-navbar-item-active-elem position-${userNavBarActive}`}/>
            {
                navbarData.map(addNavbarItem)
            }
        </div>
    )
}

export default Navbar;