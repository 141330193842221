import './index.scss'
import { convertNumber } from './../../../../../services/clientHome';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import { useDispatch } from 'react-redux';
import { setAdminProgramsCheckedProgram } from '../../../../../redux/admin/programs/programs/programsSlice';

export const setTeachersName = (data) =>{
    let result = data;
    if(typeof result !== '' && result.length !== 0)
        result = result.map(item =>{
            let name = item.name.split(' ')
            name = (name.length > 1)? name[0] + ' ' + name[1]: name
            return name;
        }).join(', ')
    else 
        result = ''
    
    return result;
}

const ProgramsListElem = ({item, setPopupType,  selectedProgram, setSelectedProgram, setSubGroupActive}) =>{
    const {id, name, price, lessonsCount, teachers} = item;
    
    const dispatch = useDispatch();

    return(
        <div className={`admin__programs-list-elem hover-non ${selectedProgram.id === id? 'active': ''}`} key={`admin__programs-program-list-elem-${id}`} onClick={(e)=>{
            if(e.target.closest(".click-button") === null){
                setSubGroupActive(false)
                setSelectedProgram((selectedProgram.id === item.id)? {id: 0}: item)
            }
        }}>
            <div className='admin__programs-list-elem-top d-flex justify-between'>
                <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                    <div className="admin__programs-list-elem-title-label wd-1">{name}</div>
                </div>
                <div className='admin__programs-list-elem-btn-container click-button'>
                    <div className='admin__programs-list-elem-btn setting' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('EditProgram')
                        dispatch(setAdminProgramsCheckedProgram(item))
                    }}/>
                </div>
            </div>
            <div className='admin__programs-program-list-elem-additionaly'>
                {convertNumber(price)}₽<span>/год</span>
            </div>
            <div className='admin__programs-program-list-elem-additionaly'>
                {lessonsCount} урока<span>/год</span>
            </div>
            <div className='admin__programs-program-list-elem-teachers d-flex'>
                <div className='admin__programs-program-list-elem-teachers-icon'/>
                <div className="admin__programs-program-list-elem-teachers-label">{setTeachersName(teachers)} {!teachers.length? <div className='admin__programs-program-list-elem-default-text'/>: null}</div>
            </div>
        </div>
    )
}

export default ProgramsListElem;