export let teachers = [
    {
        id: 1,
        name: 'Фамилия Имя Отчество1',
        city: {
            id: 1,
            name: 'Красногорск'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'
        },
        email: 'example@example.com',
        password: 'pass12345'
    },
    {
        id: 2,
        name: 'Фамилия Имя Отчество2',
        city: {
            id: 1,
            name: 'Красногорск'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'
        },
        email: 'example@example.com',
        password: 'pass12345'
    },
    {
        id: 3,
        name: 'Фамилия Имя Отчество3',
        city: {
            id: 1,
            name: 'Красногорск3'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск3'
        },
        email: 'example@example.com3',
        password: 'pass12345333'
    },
    {
        id: 4,
        name: 'Фамилия Имя Отчество4',
        city: {
            id: 1,
            name: 'Красногорск4'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск4'
        },
        email: 'example@example.com4',
        password: 'pass1234544444'
    },
    {
        id: 5,
        name: 'Фамилия Имя Отчество5',
        city: {
            id: 1,
            name: 'Красногорск5'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск5'
        },
        email: 'example@example.com5',
        password: 'pass123455555'
    },
    {
        id: 6,
        name: 'Фамилия Имя Отчество6',
        city: {
            id: 1,
            name: 'Красногорск6'
        },
        school: {
            id: 1,
            name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск6'
        },
        email: 'example@example.com6',
        password: 'pass123456666'
    },
]