import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChildClass, City, MonthYear, School } from '../../../../Filters/FiltersList';
import { selectAdminFinanceClients } from './../../../../../redux/admin/finance/clients/selectors';
import { setAdminFinanceClientsFilters } from '../../../../../redux/admin/finance/clients/clientsSlice';


const FinanceClientsFilters = ({popupBack, closePopup, clickFunk}) =>{
    const dispatch = useDispatch()
    const { adminFinanceClientsFilters:filters } = useSelector(selectAdminFinanceClients);
    const [activeList, setActiveList] = useState('')

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        maternalСapital: false,
        month: {id: 0, label: ''},
        year: '',
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
    })

    useEffect(()=>{
        setFormData({
            ...filters,
            classNumber: filters.classObj.number,
            classLetter: filters.classObj.letter,
        })
        setSearchData({
            city: '',
            school: '',
        })
        setActiveList('')
    }, [popupBack])

    const saveFilters = () =>{
        setError('')
        let filterActiveData = {
            city: formData.city,
            school: formData.school,
            classObj: formData.classObj,
            maternalСapital: formData.maternalСapital,
            month: formData.month,
            year: formData.year,
        }
        let filtersPostData = {}
        if(formData.city.id) filtersPostData.cityId = formData.city.id;
        if(formData.school.id) filtersPostData.schoolId = formData.school.id;
        if(formData.classObj.number) filtersPostData.classObj = formData.classObj.number;
        if(formData.classObj.letter) filtersPostData.classObj += formData.classObj.letter;
        if(formData.maternalСapital !== filters.maternalСapital) filtersPostData.matcap = formData.maternalСapital;
        if(formData.month.id !== filters.month.id) filtersPostData.month = formData.month.id;
        if(formData.year !== filters.year) filtersPostData.year = formData.year;

        if(formData.city.id || formData.school.id || formData.classObj.number || formData.maternalСapital !== filters.maternalСapital || formData.month.id || formData.year){
            dispatch(setAdminFinanceClientsFilters({...filterActiveData, post: filtersPostData}))
            closePopup()     
        }
        else{
            
            setError('Необходимы что-то выбрать*')
        }
    }

    const cleanFilters = () =>{        
        dispatch(setAdminFinanceClientsFilters({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classObj: {id: 0, number: '', letter: ''},
            maternalСapital: false,
            month: {id: 0, label: ''},
            year: '',
            post: {}
        }))
        setFormData({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classNumber: '',
            classLetter: '',
            classObj: {id: 0, number: '', letter: ''},
            maternalСapital: false,
            month: {id: 0, label: ''},
            year: '',
        })
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open')  && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    city: '',
                    school: '',
                })
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <MonthYear
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}                
                setError={setError}
                // classes={''}
            />

            <div className={`popup__checkbox ${formData.maternalСapital? 'active': ''}`} onClick={()=>{
                setFormData({...formData, maternalСapital: !formData.maternalСapital})
            }}>
                Оплачивает материнским капиталом
            </div>

            <div className='popup__error'>{error}</div>

            <div className='d-flex'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={cleanFilters}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${!(formData.city.id || formData.school.id || formData.classObj.number || formData.maternalСapital !== filters.maternalСapital || formData.month.id !== filters.month.id || formData.year !== filters.year)? 'deactive-button': ''}`} onClick={saveFilters}>Применить</button>
            </div>       
        </div>
    )
}

export default FinanceClientsFilters;