import './index.scss'

const TeachersTableElem = ({item, clickFunc}) =>{
    const {id, name, city, school, login, password} = item;
        
    return (
        <div className={`admin__teachers-table-line d-flex justify-between align-center`} onClick={clickFunc}>
            <div className="admin__teachers-table-line-item name">{name}</div>
            <div className="admin__teachers-table-line-item city">{city.name}</div>
            <div className="admin__teachers-table-line-item school">{school.name}</div>
            <div className="admin__teachers-table-line-item email">{login}</div>
            {/* <div className="admin__teachers-table-line-item password">{password}</div> */}
            <input className="admin__teachers-table-line-item password" type="password" readOnly={true} value={password}/>
        </div>
    )
}

export default TeachersTableElem;