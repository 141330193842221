
export const TabelValue = ({lesson, editLessonFunc, studentId, tabelTeacherPassDates, isClosed}) =>{
    let dateIsBlocking = tabelTeacherPassDates.filter(item=> item.date === lesson.date && item.isTeacherPass);
    dateIsBlocking = (dateIsBlocking.length)? dateIsBlocking[0]: null;
    return(
        <div className="admin__tabel-table-line-item lesson">
            <input className='admin__tabel-table-line-item-input' readOnly={(dateIsBlocking || isClosed)? true: false} value={lesson.value} type="text"  onChange={(e)=>{
                if(e.target.value.toLocaleUpperCase() === 'Б' || e.target.value.toLocaleUpperCase() === 'Н' || e.target.value === '') editLessonFunc(studentId, lesson.date, e.target.value.toLocaleUpperCase())
            }}/>
        </div>
    )
}

const TabelLine = ({item, editLessonFunc, getLessonsPageFunc, tabelTeacherPassDates, isClosed}) =>{
    const {id, name, lessons} = item;

    return(
        <div className='admin__tabel-table-line d-flex justify-between align-center'>
            <div className="admin__tabel-table-line-item name">
                <div className='admin__tabel-table-line-item-container name'>{name}</div>
            </div>
            {
                (lessons && lessons.length)? getLessonsPageFunc(lessons).map((lesson, key) => <TabelValue key={`admin__tabel-table-line-id-${key}`} lesson={lesson} studentId={id} isClosed={isClosed} editLessonFunc={editLessonFunc} tabelTeacherPassDates={tabelTeacherPassDates}/>):(
                    <div className="admin__tabel-table-line-item lesson">
                        <input className='admin__tabel-table-line-item-input' readOnly={true} value={''} type="text"/>
                    </div>
                )
            }
        </div>
    )
}

export default TabelLine;