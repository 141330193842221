// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-page__header {
  height: 120px;
  width: 100%;
  padding: 32px 0; }
  .user-page__header-logo {
    width: 113px;
    height: 56px;
    margin-right: 25px; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Header/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,WAAW;EACX,eAAe,EAAA;EAEf;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB,EAAA","sourcesContent":[".user-page__header{\n    height: 120px;\n    width: 100%;\n    padding: 32px 0;\n\n    &-logo{\n        width: 113px;\n        height: 56px;\n        margin-right: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
