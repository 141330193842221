import './index.scss'
import Teachers from './Teachers/Teachers';

const TeachersPage = () =>{
    return (
        <div className={`admin__applications pl-sidebar`}>
            <Teachers/>
        </div>
    )
}

export default TeachersPage;