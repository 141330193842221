import React, { useState } from 'react';

import './index.scss'
import RestorePassword from './RestorePassword';
import SignIn from './Signin';
import Registration from './Registration';
import СonfirmEmail from './ConfirmEmail';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPopupBack } from '../../redux/popup/popupSlice';



const Autorization = () =>{
    const dispatch = useDispatch()

    const [authPage, setAuthPage] = useState('')
    const [confirmEmailData, setConfirmEmailData] = useState({email: null, code: null})
    const [restorePassData, setRestorePassData] = useState({email: null, code: null})
    

    useEffect(()=>{
        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get('type') === 'reset_password'){
            setRestorePassData({email: searchParams.get('email'), code: window.location.search.match('code=(.*?)&')[1]})
            if(searchParams.get('email') && searchParams.get('code')){
                setAuthPage('restorePassword')
            }
        }
        else{
            setConfirmEmailData({email: searchParams.get('email'), code: searchParams.get('code')})
            if(searchParams.get('email') && searchParams.get('code')){
                setAuthPage('confirmEmail')
            }
        }
        dispatch(setPopupBack(''))
        
    }, [])


	return (
        <div className={`autorization d-flex justify-center align-center`}>
            <div className={`autorization__content d-flex justify-center align-center ${(authPage === 'registration')? 'registration':''}`}>
                <div className={`autorization__logo ${(authPage === '' || authPage === 'restorePassword')? '': 'small-logo'}`}/>
                {
                    (authPage === 'restorePassword')? <RestorePassword setAuthPage={setAuthPage} restorePassData={restorePassData} setRestorePassDataReset={()=>setRestorePassData({email: '', code: '',})}/>
                    :(authPage === 'confirmEmail')? <СonfirmEmail setAuthPage={setAuthPage} data={confirmEmailData} setConfirmData={setConfirmEmailData}/>
                    :(authPage === 'registration')? <Registration setAuthPage={setAuthPage} setEmailChecked={(email) => setConfirmEmailData({...confirmEmailData, email: email})}/>
                    :<SignIn setAuthPage={setAuthPage}/>
                }
            </div>
        </div>
	)
}

export default Autorization;