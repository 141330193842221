import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { fetchAdminConectedProgramAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';

import './index.scss'
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';


const ProgramConnection = ({popupBack, closePopup, type}) =>{
    const dispatch = useDispatch();

    const { adminConectedProgramAplication:programData } = useSelector(selectAdminAplicationConfirm);

    const [loading, setLoading] = useState(false)
    
    useEffect(()=>{
        dispatch(fetchAdminConectedProgramAplication({id: programData.id}))
    }, [popupBack])

    const confirmAndRefuseFunc = (confirm) =>{
        const postData = {
            id: programData.id,
            status: confirm,
        }
        setLoading(true)
        axiosProxyCatchPost({
            url: (type === 'ProgramConnection')? '/conection': '/desabled',
            data: postData,
            resolved: (data)=>{
                dispatch(setAdminAplicationsDataDeleteElem(programData.id))
                closePopup()
                setLoading(false)
            },
            rejected: (error)=>{
                setLoading(false)                
            }
        })
    }

    return(
        <div className={`popup__container admin__popup-program-connection`}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title fs-24'>
                    {(type === 'ProgramConnection')? 'Подключение программы' :'Отключение программы'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label className="popup__label mb-4">ФИО ребенка</label>
            <div className="popup__input mb-16">{programData.name}</div>

            <label className="popup__label mb-4">Город</label>
            <div className="popup__input mb-16">{programData.city}</div>

            <label className="popup__label mb-4">Школа</label>
            <div className="popup__textarea scool mb-16">{programData.school}</div>

            <label className="popup__label mb-4">Класс</label>
            <div className="popup__input mb-16">{programData.classNumber}{programData.classLetter}</div>

            <label className="popup__label mb-4">Программа</label>
            <div className="popup__input mb-16 d-flex align-center">
                <div className={`admin__popup-program-connection-program-item ${programData.program.color}`}>{programData.program.name}</div>
            </div>

            <div className='d-flex mt-24'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(false)}>Отклонить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(true)}>Принять</button>
            </div>
        </div>
    )
}

export default ProgramConnection;