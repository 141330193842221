import DocumentIcon from '../../../../img/document-purple-icon.svg';
import DocumentRedIcon from '../../../../img/documents-red-icon.svg';
import DocumentGreenIcon from '../../../../img/document-green-icon.svg'
import DocumentGreyIcon from '../../../../img/document-grey-icon.svg';

import './index.scss'
import { downloadFilePost, UploadFileFunc } from '../../../../services/clientHome';
import { useState } from 'react';

export const getStatus = (id) =>{
    if(id === -1)
        return {color: 'red', label: 'Отказано'}
    else if(id === 0)
        return {color: 'red', label: 'Не подтверждено'}
    else if(id === 1)
        return {color: 'green', label: 'Подтверждено'}
    else
        return {color: '', label: 'Не отправлено'}
}

const downloadFileFunc = (e, id, sendFunc, docTypeId, setLoading) =>{    
    UploadFileFunc(e,
        (uploadFileData)=>{
            if(uploadFileData){
                let resultFile = {
                    id: id,
                    size: uploadFileData.size,
                    extension: uploadFileData.extension,
                    status: 2,
                    link: uploadFileData.link,
                    fileBytes: uploadFileData.fileBytes,
                };
                sendFunc(resultFile, docTypeId, setLoading) 
            }  
        }
    )          
}

const getDocumentIcon = (id) =>{
    if(id === -1 || id === 0) return DocumentRedIcon
    else if(id === 1) return DocumentGreenIcon
    else if(id === 'not-access') return DocumentGreyIcon
    else return DocumentIcon
}


const DocumentElem = ({item, sendFunc, keyVal, docTypeId, checkedChild, selectionDocument, access, blockedDocuments = []}) =>{
    const {id, layoutId, documentId, name, size, docType, status, link, comment=''} = item;    
    let statusObj = getStatus(status);
    const [loading, setLoading] = useState(false)

    return (
        <div className={`user-page__documents-doc-line d-flex align-center ${((!access && docTypeId === 2) || blockedDocuments.indexOf(id) !== -1)? 'not-access': ''} ${(selectionDocument && selectionDocument === id)? 'animation': ''}`} key={`${keyVal}-${id}`}>
            <div className='user-page__documents-doc-line-left d-flex align-center'>
                <img className="user-page__documents-doc-line-image mr-16" src={getDocumentIcon(((!access && docTypeId === 2) || blockedDocuments.indexOf(id) !== -1)? 'not-access': status)} alt=""/>
                <div className='user-page__documents-doc-line-information'>
                    <div className='user-page__documents-doc-line-information-name mb-6'>{name}</div>
                    <div className='user-page__documents-doc-line-information-size-and-type d-flex align-center'>
                        <div>{size}</div> <span/> <div>{docType.replace('.', '')}</div>
                    </div>
                </div>
            </div>
            <div className={`user-page__documents-doc-line-status ${statusObj.color}`}>
                <div className='user-page__documents-doc-line-status-label'>{statusObj.label}</div>
                {
                    (status === -1 && comment)? <div className='user-page__documents-doc-line-status-title' data-tooltip={comment}/>: null
                }                
            </div>
            <div className='user-page__documents-doc-line-buttons-container d-flex align-center'>
                <div className={`user-page__documents-doc-line-button d-flex justify-between align-center ${loading? 'deactive-button': ''} ${(selectionDocument && selectionDocument === id)? 'animation': ''}`} onClick={() => downloadFilePost({url: link && status !== -1? '/api/v1/parental/documents/get-my-file?': '/api/v1/parental/document/get-layout?', name, type: docType, postData: (link && status !== -1)? {documentId: documentId? documentId: id}: {id: (status === -1)? layoutId: id, studentId: checkedChild.id}, setLoading: setLoading})}>
                    <div className='user-page__documents-doc-line-button-label mr-8'>Скачать</div>
                    <div className={`user-page__documents-doc-line-button-icon download`}/>
                </div>
                
                <input type="file" name='files' accept="" className='user-page__documents-doc-line-button d-none' id={`documents-doc-download-input-${keyVal}-${id}`} onChange={(e)=>{
                    setLoading(true)
                    downloadFileFunc(e, (status === 0 || status === -1)? layoutId: id, sendFunc, docTypeId, setLoading)
                }}/>
                <label className={`user-page__documents-doc-line-button d-flex justify-between align-center ${((link && status !== -1) || loading)? 'deactive-button': ''} ${(selectionDocument && selectionDocument === id)? 'animation': ''}`} htmlFor={`documents-doc-download-input-${keyVal}-${id}`}>
                    <div className='user-page__documents-doc-line-button-label mr-8'>Отправить</div>
                    <div className={`user-page__documents-doc-line-button-icon send`}/>
                </label>
            </div>
        </div>
    )
}

export default DocumentElem;