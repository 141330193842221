import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Header';
import ClassesListElem from './ClassesListElem/ClassesListElem';

import './index.scss'
import AdminPopup from '../../Popup/AdminPopup';
import { useDispatch, useSelector } from 'react-redux';
import DeleteBlock from '../../Popup/DeleteBlock/DeleteBlock';
import { setPopupBack } from '../../../../redux/popup/popupSlice';
import WarningBlock from './../../Popup/WarningBlock/WarningBlock';
import { selectAdminProgramsClasses } from './../../../../redux/admin/programs/classes/selectors';
import { fetchAdminProgramsClassesData } from './../../../../redux/admin/programs/classes/asyncActions';
import { axiosProxyCatchGet, axiosProxyCatchPost } from '../../../../axiosProxy';
import { setAdminProgramsClassesDataDelete, setAdminProgramsClassesScrollReset } from '../../../../redux/admin/programs/classes/classesSlice';
import { selectAdminProgramsSchools } from './../../../../redux/admin/programs/schools/selectors';
import { scrollingPagination, useDebouncedValue } from '../../../../services/clientHome';




const Classes = () =>{
    const dispatch = useDispatch();

    const { adminProgramsClassesData:classesData, adminProgramsCheckedClass, adminProgramsClassesLoading:loading, adminProgramsClassesPageLast:pageLast, adminProgramsClassesScrollReset:scrollReset, } = useSelector(selectAdminProgramsClasses);
    const { adminProgramsCheckedSchool } = useSelector(selectAdminProgramsSchools);

    const [query, setQuery] = useState('')
    const [page, setPage] = useState(0)
    const [popupType, setPopupType] = useState('')

    const searchText = useDebouncedValue(query, 600)

    useEffect(()=>{
        dispatch(fetchAdminProgramsClassesData({schoolId: adminProgramsCheckedSchool.id, count:55, page:0, isScrolling: false, query: query}))
        setPage(0)
    }, [searchText])


    const ProgramsLinkRef = useRef()

    const deletedClass = (setLoading) =>{
        // dispatch(setAdminProgramsClassesDataDelete(adminProgramsCheckedClass.id))
        // setPopupType('')

        axiosProxyCatchPost({
            url:'/api/v1/administrative/programs/delete-class', 
            data: {id: adminProgramsCheckedClass.id},
            resolved: (data)=>{
                dispatch(setAdminProgramsClassesDataDelete(adminProgramsCheckedClass.id))
                setPopupType('')
                setLoading(false)
            },
            rejected: (error)=>{
                setPopupType('Warning')
                setLoading(false)
            }
        })
    }

    return(
        <div className={`admin__programs-classes `}>
            <NavLink to={'/schools/classes/programs'} ref={ProgramsLinkRef}/>
            <Header query={query} setQuery={setQuery}/>

            <div className='admin-wrapper'>
                <div className="admin__programs-top">
                    <NavLink to={`/schools`} className="admin__programs-top-back d-flex align-center">
                        <div className='admin__programs-top-back-icon'/>
                        <div className='admin__programs-top-back-label school-name'>{adminProgramsCheckedSchool.name}</div>
                    </NavLink>
                    <div className='admin__programs-top-buttons d-flex'>
                        <button className='admin__programs-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('AddClass')
                            // setPopupType('Delete')
                        }}>
                            <div className='admin__programs-top-button-label'>Создание класса</div>
                            <div className='admin__programs-top-button-icon plus'/>
                        </button>
                        {/* <button className='admin__programs-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('EditSchool')
                        }}>
                            <div className='admin__programs-top-button-label'>Настройка школы</div>
                            <div className='admin__programs-top-button-icon setting'/>
                        </button> */}
                    </div>
                </div>
            </div>

            <div className='admin-wrapper scroll-container'>
                <div className='admin__programs-classes-list'   
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchAdminProgramsClassesData({schoolId: adminProgramsCheckedSchool.id, query, count: 55, page: page+1, isScrolling: true})),
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setAdminProgramsClassesScrollReset(false))
                )}>
                    {
                        classesData.map(item=><ClassesListElem item={{...item, school: adminProgramsCheckedSchool}} setPopupType={setPopupType} programsLinkRef={ProgramsLinkRef} key={`admin__programs-classes-list-elem-container-${item.id}`}/>)
                    }
                </div>
            </div>
            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление класса', subtitle:<>Вы действительно хотите удалить <span>{adminProgramsCheckedClass.number}{(adminProgramsCheckedClass.letter.toLowerCase() === 'группа')? ` ${adminProgramsCheckedClass.letter}`: `${adminProgramsCheckedClass.letter} класс`}</span>?</>, closePopup: setPopupType, clickFunk:(setLoading)=>deletedClass(setLoading)}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle:'Не удалось удалить класс, попробуйте еще раз.', closePopup: setPopupType}}/>
            {/* <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'ВНИМАНИЕ', subtitle:'Этот класс нельзя удалить, в нем есть ученики.', closePopup: setPopupType}}/> */}
        </div>
    )
}

export default Classes;