// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__popup-program-connection-program-item {
  padding: 3px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  border-radius: 8px;
  width: max-content; }
  .admin__popup-program-connection-program-item.red {
    background: #f5c04a1A;
    color: #f5c04a; }
  .admin__popup-program-connection-program-item.blue {
    background: #4791FF1A;
    color: #4791FF; }
  .admin__popup-program-connection-program-item.purple {
    background: #A347FF1A;
    color: #A347FF; }
  .admin__popup-program-connection-program-item.green {
    background: #47ca561A;
    color: #47ca56; }
  .admin__popup-program-connection-program-item.yellow {
    background: #cec2121A;
    color: #cec212; }
  .admin__popup-program-connection-program-item.orange {
    background: #e37b1f1A;
    color: #e37b1f; }
  .admin__popup-program-connection-program-item.pink {
    background: #ff96a81A;
    color: #ff96a8; }
  .admin__popup-program-connection-program-item.brown {
    background: #a52a2a1A;
    color: #a52a2a; }
  .admin__popup-program-connection-program-item.light_blue {
    background: #61cbed1A;
    color: #61cbed; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Applications/ProgramConnection/index.scss"],"names":[],"mappings":"AACI;EACI,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB,EAAA;EANrB;IASO,qBAAqB;IACrB,cAAc,EAAA;EAVrB;IAcO,qBAAqB;IACrB,cAAc,EAAA;EAfrB;IAmBO,qBAAqB;IACrB,cAAc,EAAA;EApBrB;IAwBO,qBAAqB;IACrB,cAAc,EAAA;EAzBrB;IA6BO,qBAAqB;IACrB,cAAc,EAAA;EA9BrB;IAkCO,qBAAqB;IACrB,cAAc,EAAA;EAnCrB;IAuCO,qBAAqB;IACrB,cAAc,EAAA;EAxCrB;IA4CO,qBAAqB;IACrB,cAAc,EAAA;EA7CrB;IAiDO,qBAAqB;IACrB,cAAc,EAAA","sourcesContent":[".admin__popup-program-connection{\n    &-program-item{\n        padding: 3px 8px;\n        font-size: 14px;\n        font-weight: 500;\n        line-height: 16.8px;\n        border-radius: 8px;\n        width: max-content;\n\n        &.red{\n            background: #f5c04a1A;\n            color: #f5c04a;\n        }\n\n        &.blue{\n            background: #4791FF1A;\n            color: #4791FF;\n        }\n\n        &.purple{\n            background: #A347FF1A;\n            color: #A347FF;\n        }\n\n        &.green{\n            background: #47ca561A;\n            color: #47ca56;\n        }\n\n        &.yellow{\n            background: #cec2121A;\n            color: #cec212;\n        }\n\n        &.orange{\n            background: #e37b1f1A;\n            color: #e37b1f;\n        }\n\n        &.pink{\n            background: #ff96a81A;\n            color: #ff96a8;\n        }\n\n        &.brown{\n            background: #a52a2a1A;\n            color: #a52a2a;\n        }\n\n        &.light_blue{\n            background: #61cbed1A;\n            color: #61cbed;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
