import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDropListName } from '../../redux/dropDownList/dropDownListSlice';
import { selectDropDownListData } from '../../redux/dropDownList/selectors';

import './index.scss'
import { selectUserProfile } from './../../redux/users/profile/selectors';
import { useEffect } from 'react';
import Header from './Header/Header';
import Tabel from './Tabel/index';


const Teacher = () =>{
    const dispatch = useDispatch();
    const { listName } = useSelector(selectDropDownListData)
    const { profileData } = useSelector(selectUserProfile);

    const navigate = useNavigate()
    
    // useEffect(()=>{
    //     navigate('/')
    // }, [])

    return (
        <div className={`teacher`} onClick={(e)=>{
            if(listName !== '' && !e.target.classList.contains('popupBlock-click')){
                dispatch(setDropListName(''))
            }
        }}>
            <Header/>
            <Routes>
                <Route path='/' element={<Tabel/>} exact/>
                {/* <Route path='/teacher-tabel' element={<Documents/>} exact/> */}
                <Route path='/*' element={<div>Страница не найдена</div>} exact/>
            </Routes>
        </div>
    )
}

export default Teacher;