import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminFinanceSearchVal: ''
};

const adminFinanceSearch = createSlice({
  name: 'AdminFinanceSearch',
  initialState,
  reducers: {
    setAdminFinanceSearchVal(state, action) {
      state.adminFinanceSearchVal = action.payload;      
    },
  },
});

const { actions, reducer } = adminFinanceSearch;


export default reducer;

export const { 
    setAdminFinanceSearchVal
} = actions;