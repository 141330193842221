import { createPortal } from 'react-dom';

import '../../../../main.scss'


const WarningBlockContainer = ({popupBack, title, subtitle, closePopup}) => {
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            closePopup('')
        }         
    }

	return (
        <div className={`popup__back doble-popup ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                <div className={`popup__container admin-popup__container delete`}>
                    <div className='popup__title mb-24'>
                        {title}
                    </div>
                    <div className='popup__subtitle mb-24'>
                        {subtitle}
                    </div>
                    <button className='popup__button' onClick={()=>closePopup('')}>Назад</button>
                </div>
            </div>
        </div>
	)
}

const WarningBlock = ({popupBack, props = null, type}) =>{
    return createPortal(<WarningBlockContainer popupBack={popupBack} title={props.title} subtitle={props.subtitle} closePopup={props.closePopup}/>, document.body)
}
export default WarningBlock;