import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Calend from './../../../../Calendar/Calendar';

import './index.scss'
import { Child, ChildClass, City, Parrent, PaymentType, School } from '../../../../Filters/FiltersList';
import { selectAdminFinancePaymentHistory } from '../../../../../redux/admin/finance/paymentsHistory/selectors';
import { setAdminFinancePaymentHistoryFilters } from '../../../../../redux/admin/finance/paymentsHistory/paymentsHistorySlice';
import { getDateFormatFunc } from '../../../../../services/clientHome';



const PaymentHistoryFilters = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const [activeList, setActiveList] = useState('')
    const { adminFinancePaymentHistoryFilters:filters } = useSelector(selectAdminFinancePaymentHistory);

    const [error, setError] = useState('')

    const [calendActive, setCalendActive] = useState(false)

    const [formData, setFormData] = useState({
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        parrent: {id: 0, name: ''},
        child: {id: 0, name: ''},
        paymentType: {id: 0, name: '', color: ''},
        dates: [],
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''}
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
        parrent: '',
        child: '',
        payment: '',
    })

    useEffect(()=>{
        setFormData({
            ...filters,
            classNumber: filters.classObj.number,
            classLetter: filters.classObj.letter,
        })
        setSearchData({
            city: '',
            school: '',
            parrent: '',
            child: '',
            payment: '',
        })
        setActiveList('')
    }, [popupBack])

    const saveFilters = () =>{
        setError('')
        let filterActiveData = {
            city: formData.city,
            school: formData.school,
            classObj: formData.classObj,
            parrent: formData.parrent,
            child: formData.child,
            paymentType: formData.paymentType,
            dates: formData.dates,
        }
        let filtersPostData = {}
        if(formData.city.id) filtersPostData.cityId = formData.city.id;
        if(formData.school.id) filtersPostData.schoolId = formData.school.id;
        if(formData.classObj.number) filtersPostData.classObj = formData.classObj.number;
        if(formData.classObj.letter) filtersPostData.classObj += formData.classObj.letter;
        if(formData.parrent.id) filtersPostData.clientId = formData.parrent.id;
        if(formData.child.id) filtersPostData.studentId = formData.child.id;
        if(formData.paymentType.id) filtersPostData.method = formData.paymentType.id;

        if(formData.city.id || formData.school.id || formData.classObj.number || formData.parrent.id || formData.child.id || formData.paymentType.id || formData.dates.length){
            let dates = formData.dates.length? {from: formData.dates[0], to:formData.dates[1]}: {};
            dispatch(setAdminFinancePaymentHistoryFilters({...filterActiveData, post: filtersPostData, postDates: {...dates}}))
            closePopup()
        }
        else{
            setError('Необходимы что-то выбрать*')        
        }
    }

    const cleanFilters = () =>{         
        dispatch(setAdminFinancePaymentHistoryFilters({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            parrent: {id: 0, name: ''},
            child: {id: 0, name: ''},
            paymentType: {id: 0, name: '', color: ''},
            dates: [],
            classObj: {id: 0, number: '', letter: ''},
            post: {},
            postDates: {}
        }))
        setFormData({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            parrent: {id: 0, name: ''},
            child: {id: 0, name: ''},
            paymentType: {id: 0, name: '', color: ''},
            dates: [],
            classNumber: '',
            classLetter: '',
            classObj: {id: 0, number: '', letter: ''}
        })
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container finanse-page-history-filter`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('admin-popup__finance-page-payment-type-val') && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    city: '',
                    school: '',
                    parrent: '',
                    child: '',
                    payment: '',
                })
            }
            if(calendActive && !e.target.classList.contains("admin-popup__sertificate-confirm-line-item-validate-item") && e.target.closest(".admin-popup__finance-page-calendar") === null){                
                setCalendActive(false)
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}                
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            /> 

            <Parrent 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            /> 

            <Child 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                setError={setError}
                url='/api/v1/administrative/finance'
            /> 

            {/* <div className='d-flex justify-between align-center'>
                <div className='wd-45 mr-16'>
                    <PaymentType 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        classes={'open-list-top'}
                    /> 
                </div>

                <div className='wd-55'>
                    <label htmlFor="" className='popup__label mb-4'>Период</label>
                    <div className='admin-popup__finance-page-calendar-container'>
                        <div className='admin-popup__finance-page-calendar-input' onClick={()=>{
                            setCalendActive(!calendActive)
                        }}>
                            {(formData.dates.length)? `${formData.dates[0]} - ${formData.dates[1]}`: ''}
                        </div>
                        
                        <Calend classes={`admin-popup__finance-page-calendar ${calendActive? 'active': ''}`} isRange={true} checkDate={(formData.dates.length)?[getDateFormatFunc(formData.dates[0]), getDateFormatFunc(formData.dates[1])]: []} setCheckDate={(data)=>{
                            setFormData({...formData, dates: [data[0].toLocaleDateString(), data[1].toLocaleDateString()]}) 
                            setCalendActive(false)
                        }}/>
                    </div>
                </div>                
            </div>             */}


            <label htmlFor="" className='popup__label mb-4'>Период</label>
            <div className='admin-popup__finance-page-calendar-container'>
                <div className='admin-popup__finance-page-calendar-input' onClick={()=>{
                    setCalendActive(!calendActive)
                }}>
                    {(formData.dates.length)? `${formData.dates[0]} - ${formData.dates[1]}`: ''}
                </div>
                
                <Calend classes={`admin-popup__finance-page-calendar ${calendActive? 'active': ''}`} isRange={true} checkDate={(formData.dates.length)?[getDateFormatFunc(formData.dates[0]), getDateFormatFunc(formData.dates[1])]: []} setCheckDate={(data)=>{
                    setFormData({...formData, dates: [data[0].toLocaleDateString(), data[1].toLocaleDateString()]}) 
                    setCalendActive(false)
                }}/>
            </div>
            
            <div className='popup__error'>{error}</div>

            <div className='d-flex'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={cleanFilters}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${!(formData.city.id || formData.school.id || formData.classObj.number || formData.parrent.id || formData.child.id || formData.paymentType.id || formData.dates.length)? 'deactive-button': ''}`} onClick={saveFilters}>Применить</button>
            </div>       
        </div>
    )
}

export default PaymentHistoryFilters;