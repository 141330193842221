
import { convertNumber, getPaymentTypeObj } from './../../../../../../services/clientHome';

const PaymentHistoryListElem = ({item}) =>{
    const {id, studentName, program, date, summ, paymentType} = item;
    const paymentObj = getPaymentTypeObj(paymentType)
    return (
        <div className={`admin__finance-table-line pd-12 d-flex justify-between align-center user-card-payment-history`}>
            <div className='admin__finance-user-card-payment-history-table-line-item name'>
                {studentName}
            </div>
            <div className='admin__finance-user-card-payment-history-table-line-item date-summ'>
                <div className='admin__finance-user-card-payment-history-table-line-item-date-summ-item mb-6'>
                    <span>Дата: </span> {date}
                </div>
                <div className='admin__finance-user-card-payment-history-table-line-item-date-summ-item'>
                    <span>Сумма: </span> {convertNumber(summ)}{summ? '₽': ''}
                </div>
            </div>

            <div className='admin__finance-user-card-payment-history-table-line-item payment-type d-flex justify-center align-center'>
                <div className={`admin__finance-user-card-payment-history-table-line-item-payment-type ${paymentObj.color}`}>
                    {paymentObj.name}
                </div>
            </div>
        </div>
    )
}

export default PaymentHistoryListElem;