import { NavLink } from 'react-router-dom';

const CLientsListElem = ({item, clickFunc}) =>{
    const {name} = item;
    return (
        <NavLink to={'/finance/user-profile-card'} className='admin__finance-table-line d-flex justify-between' onClick={clickFunc}>
            <div className='admin__finance-page-clients-table-line-item name'>{name}</div>
        </NavLink>
    )
}

export default CLientsListElem;