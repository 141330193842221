import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import dropDownList from './dropDownList/dropDownListSlice';
import userPopup from './users/popup/userPopupSlice';
import popup from './popup/popupSlice';
import userProfile from './users/profile/profileSlice';
import userChildren from './users/children/childrenSlice';
import userDocuments from './users/documents/documentsSlice';
import userPrograms from './users/programs/programsSlice';
import userPaymentHistory from './users/paymentHistory/paymentHistorySlice';
import userNavigations from './users/navigations/userNavigationsSlice';
import authorization, { setUserData } from './authorization/authorization';
import adminSlise from './admin';
import teacherSlise from './teacher';



export const store = configureStore({
  reducer: {
    authorization,
    popup, 
    dropDownList, 
    userNavigations, 
    userPopup, 
    userProfile,
    userChildren,
    userDocuments,
    userPrograms,
    userPaymentHistory,
    ...adminSlise,
    ...teacherSlise
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const logout = () =>{
  store.dispatch(setUserData({id: 0, name: '', token: '', role: ''}))
  // store.dispatch(setLeaderPopupBack({popupBack: ''}))
}

export default store;

export const useAppDispatch = () => useDispatch();

