import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../../axiosProxy';


export const fetchAdminProgramsClassesData = createAsyncThunk(
  'AdminProgramsClasses/fetchAdminProgramsClassesData',
  async (dataObj={schoolId: 0, count:60, page:0, isScrolling: false, query: ''}) => {
    let postData = {count: dataObj.count, page: dataObj.page, schoolId: dataObj.schoolId};
    if(dataObj.query) postData.query = dataObj.query;

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/programs/get-class?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


