import { createSlice } from '@reduxjs/toolkit';
import { fetchPaymentHistoryData } from './asyncActions';

const initialState = {
    paymentHistoryData: [
    ],

    loading: false,

    pageLast: true,

    scrollReset: false
};

const userPaymentHistory = createSlice({
  name: 'UserPaymentHistory',
  initialState,
  reducers: {
    setUserPaymentHistoryData(state, action) {
      state.paymentHistoryData = action.payload;      
    },    
    setUserPaymentHistoryDataAdd(state, action) {
      state.paymentHistoryData = [...state.paymentHistoryData, action.payload];      
    },
    setUserPaymentHistoryScrollReset(state, action) {
      state.scrollReset = action.payload;      
    },    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentHistoryData.pending, (state, action) => {
      state.loading = true;      
    });
    builder.addCase(fetchPaymentHistoryData.fulfilled, (state, action) => {      
        if(action.payload){
          if(action.payload.isScrolling){
            state.paymentHistoryData = [...state.paymentHistoryData, ...action.payload.data];
          }
          else
            state.paymentHistoryData = action.payload.data;
  
          if(action.payload.page === 0) state.scrollReset = true;
  
          if(action.payload.data.length < 6) state.pageLast = true;
          else state.pageLast = false;
  
          
        }
        state.loading = false;

    });
    builder.addCase(fetchPaymentHistoryData.rejected, (state, action) => {
      state.loading = false;
    });
  },

});

const { actions, reducer } = userPaymentHistory;


export default reducer;

export const {
  setUserPaymentHistoryData,
  setUserPaymentHistoryDataAdd,
  setUserPaymentHistoryScrollReset  
} = actions;

