// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/arrow-left-black-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../img/arrow-right-balck-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__tabel-tabel-pages {
  width: 100%;
  margin-top: 32px; }
  .admin__tabel-tabel-pages-container {
    margin: auto; }
  .admin__tabel-tabel-pages-item {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: .3s background-color; }
    .admin__tabel-tabel-pages-item.active {
      background-color: #B158FF;
      color: #fff; }
  .admin__tabel-tabel-pages-points {
    width: 38px;
    height: 38px;
    font-size: 18px;
    font-weight: 600; }
  .admin__tabel-tabel-pages-arrow {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: contain; }
    .admin__tabel-tabel-pages-arrow.left {
      margin-right: 22px;
      background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat; }
    .admin__tabel-tabel-pages-arrow.right {
      margin-left: 22px;
      background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center no-repeat; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Tabel/Tabel/PagesBlock/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,gBAAgB,EAAA;EAEhB;IACI,YAAY,EAAA;EAGhB;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gCAAgC,EAAA;IAPnC;MAWO,yBAAyB;MACzB,WAAW,EAAA;EAInB;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB,EAAA;EAGpB;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IAWf,wBAAwB,EAAA;IAd3B;MAMO,kBAAkB;MAClB,oEAA8E,EAAA;IAPrF;MAUO,iBAAiB;MACjB,oEAA+E,EAAA","sourcesContent":[".admin__tabel-tabel-pages{\n    width: 100%;\n    margin-top: 32px;\n\n    &-container{\n        margin: auto;\n    }\n\n    &-item{\n        width: 38px;\n        height: 38px;\n        border-radius: 4px;\n        font-size: 18px;\n        font-weight: 500;\n        cursor: pointer;\n        transition: .3s background-color;\n\n\n        &.active{\n            background-color: #B158FF;\n            color: #fff;\n        }\n    }\n\n    &-points{\n        width: 38px;\n        height: 38px;\n        font-size: 18px;\n        font-weight: 600;\n    }\n\n    &-arrow{\n        width: 20px;\n        height: 20px;\n        cursor: pointer;\n\n        &.left{\n            margin-right: 22px;\n            background: url(../../../../../img/arrow-left-black-icon.svg) center no-repeat;\n        }\n        &.right{\n            margin-left: 22px;\n            background: url(../../../../../img/arrow-right-balck-icon.svg) center no-repeat;\n        }\n        \n        background-size: contain;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
