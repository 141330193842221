import './index.scss'
import MaskedInput from './../../../MaskedInput/MaskedInput';
import { useState, useEffect } from 'react';

import DocumentIcon from '../../../../img/document-purple-icon.svg';
import { useDispatch } from 'react-redux';
import { setUserAegrotatsAdd } from '../../../../redux/users/documents/documentsSlice';
import Calend from '../../../Calendar/Calendar';
import { getDateFormatFunc, getDateFormatFuncServer, getDatePostFormatFunc, UploadFileFunc } from '../../../../services/clientHome';
import axiosProxy, { axiosProxyCatchPost } from '../../../../axiosProxy';


export const convertFileSize = (size) =>{
    let result = size;
    if(result < 1024){
        result = (result).toFixed(2) + 'Б'
    }
    else if(result / 1024 < 1024){
        result = (result / 1024).toFixed(2) + 'КБ'
    }
    else if(result / 1024 / 1024 < 1024){
        result = (result / 1024 / 1024).toFixed(2) + 'МБ'
    }
    else if(result / 1024 / 1024 / 1024 < 1024){
        result = (result / 1024 / 1024 / 1024).toFixed(2) + 'ГБ'
    }
    return result;
}

const downloadFileFunc = (e, setFile, setPostFile) =>{    
    UploadFileFunc(e,
        (uploadFileData)=>{
            if(uploadFileData){
                let resultFile = {
                    size: uploadFileData.size,
                    docType: uploadFileData.extension,
                    status: 0,
                    link: uploadFileData.link,
                    fileBytes: uploadFileData.fileBytes,
                };
                setPostFile(resultFile)
                
                setFile({
                    name: (uploadFileData.name.length > 25)? uploadFileData.name.slice(0, 25) + '...': uploadFileData.name.replace(`.${uploadFileData.extension}`),
                    size: uploadFileData.size,
                    type: uploadFileData.extension,
                    file: uploadFileData.file,
                }) 
            }  
        }
    )
}

const FileElem = ({item, deleteFileFunc}) =>{
    const {name, type, size} = item
    return(
        <div className="user-popup__add-aegrotat-file-block d-flex justify-between align-center mb-24" key={`user-popup__add-aegrotat-file-block-1`}>
            <div className='user-popup__add-aegrotat-file-block-left d-flex align-center'>
                <img className="user-popup__add-aegrotat-file-block-image mr-16" src={DocumentIcon} alt=""/>
                <div className='user-popup__add-aegrotat-file-block-information'>
                    <div className='user-popup__add-aegrotat-file-block-information-name mb-6'>{name}</div>
                    <div className='user-popup__add-aegrotat-file-block-information-size-and-type d-flex align-center'>
                        <div>{size}</div> <span/> <div>{type}</div>
                    </div>
                </div>
            </div>
            <div className='user-popup__add-aegrotat-file-block-delete' onClick={deleteFileFunc}/>
        </div>
    )
}

const AddAegrotat = ({popupBack, closePopup, checkedChild}, key) =>{
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        file: {
            type: '',
            name: '',
            size: '',
            file: null
        },
        startDate: '',
        endDate: ''
    })

    const [error, setError] = useState('')
    const [postFile, setPostFile] = useState({})
    const [calendActive, setCalendActive] = useState(false)
    const [dateActive, setDateActive] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        setFormData({
            file: {
                type: '',
                name: '',
                size: '',
                file: null
            },
            startDate: '',
            endDate: ''
        })
        setCalendActive(false)
    }, [popupBack])

    const postData = () =>{
        if(((typeof formData.startDate === 'object' && formData) || (typeof formData.startDate === 'string' && formData.startDate.length === 10 && formData.startDate.indexOf('_') === -1)) && ((typeof formData.endDate === 'object' && formData) || (typeof formData.endDate === 'string' && formData.endDate.length === 10 && formData.endDate.indexOf('_') === -1)) && formData.file.file){
            let postData = {
                file: postFile.fileBytes,
                extension: postFile.docType,
                studentId: checkedChild.id,
                startDate: typeof formData.startDate === 'object'? getDateFormatFuncServer(formData.startDate.toLocaleDateString()): getDateFormatFuncServer(formData.startDate),
                endDate: typeof formData.endDate === 'object'? getDateFormatFuncServer(formData.endDate.toLocaleDateString()): getDateFormatFuncServer(formData.endDate)
            }
            
            setLoading(true)
            axiosProxyCatchPost({
                url:'/api/v1/parental/document/create-document', 
                data: postData,
                resolved: (response) =>{
                    closePopup()
                    setLoading(false)
                    setFormData({
                        file: {
                            type: '',
                            name: '',
                            size: '',
                            file: null
                        },
                        startDate: '',
                        endDate: ''
                    })
                    dispatch(setUserAegrotatsAdd({
                        id: response.id,
                        name: formData.startDate.toLocaleDateString() + ((formData.endDate)?(' - ' + formData.endDate.toLocaleDateString()): ''),
                        size: postFile.size,
                        date: new Date().toLocaleDateString(),
                        docType: postFile.docType, 
                        status: 0,
                        documentLink: '',
                    }))      
                },
                rejected: (error) =>{
                    setLoading(false)
                },
            })
        }
        else{
            if(!((typeof formData.startDate === 'object' && formData) || (typeof formData.startDate === 'string' && formData.startDate.length === 10 && formData.startDate.indexOf('_') === -1)) && !((typeof formData.endDate === 'object' && formData) || (typeof formData.endDate === 'string' && formData.endDate.length === 10 && formData.endDate.indexOf('_') === -1)) && !formData.file.file)
                setError('Необходимо добавить файл и выбрать период*')
            else if(!formData.file.file)
                setError('Необходимо добавить файл*')
            else if(!((typeof formData.startDate === 'object' && formData) || (typeof formData.startDate === 'string' && formData.startDate.length === 10 && formData.startDate.indexOf('_') === -1)) && !((typeof formData.endDate === 'object' && formData) || (typeof formData.endDate === 'string' && formData.endDate.length === 10 && formData.endDate.indexOf('_') === -1)))
                setError('Необходимо выбрать период*')
        }
    }

    return(
        <div className={`popup__container user-popup__container add-aegrotat`} onClick={(e)=>{
            if(calendActive && e.target.closest(".user-popup__add-aegrotat-calendar") === null){
                setCalendActive(false)
            }
        }}>
            {
                (formData.file.file)?<FileElem item={formData.file} deleteFileFunc={()=>setFormData({...formData, file: {type: '', name: '', size: '', file: null}})}/>
                :(
                    <div className='user-popup__add-aegrotat-download-file mb-24'>
                        <input type="file" name='files' accept="" className='user-popup__add-aegrotat-download-file-input' id={`add-aegrotat-download-file-id`} onChange={(e)=>downloadFileFunc(e, (fileInform)=>setFormData({...formData, file: fileInform}), setPostFile)}/>
                        <label className='user-popup__add-aegrotat-download-file-label d-flex justify-center align-center' htmlFor={`add-aegrotat-download-file-id`}>
                            <div className='user-popup__add-aegrotat-download-file-label-val mr-8'>Добавить</div>
                            <div className={`user-popup__add-aegrotat-download-file-label-icon`}/>
                        </label>
                    </div>
                )
            }
            
            <div className='user-popup__add-aegrotat-date-container d-flex'>
                <div className="wd-50-mr-16">
                    <label htmlFor="" className='popup__label mb-4'>С какого числа</label>
                    
                    <div className='user-popup__add-aegrotat-date'>
                        <MaskedInput
                            className={`user-popup__add-aegrotat-date-input`}
                            mask="__.__.____"
                            replacement={{_ : /[0-9]/}}
                            defaultValue={(formData.startDate !== null && typeof formData.startDate === 'object')? `${formData.startDate.toLocaleDateString()}г`: formData.startDate}
                            name = 'startDate'
                            readOnly={false}
                            placeholder=''
                            onChange={(event)=>{
                                setFormData({...formData, startDate: event.target.value})
                            }}
                        />
                        <div className='user-popup__add-aegrotat-date-icon' onClick={()=>{
                            setDateActive('start')
                            setCalendActive(!calendActive)
                        }}/>
                    </div>
                </div>
                <div className="wd-50-mr-16">
                    <label htmlFor="" className='popup__label mb-4'>По какое число</label>
                    <div className='user-popup__add-aegrotat-date'>
                        <MaskedInput
                            className={`user-popup__add-aegrotat-date-input`}
                            mask="__.__.____"
                            replacement={{_ : /[0-9]/}}
                            showMask={true}
                            separate={true}
                            defaultValue={(formData.endDate !== null && typeof formData.endDate === 'object')? `${formData.endDate.toLocaleDateString()}г`: formData.endDate}
                            name = 'endDate'
                            readOnly={false}
                            placeholder=''
                            onChange={(event)=>{
                                setFormData({...formData, endDate: event.target.value})
                            }}
                        />
                        <div className='user-popup__add-aegrotat-date-icon' onClick={()=>{
                            setDateActive('end')
                            setCalendActive(!calendActive)
                        }}/>
                    </div>
                </div>
                <Calend classes={`user-popup__add-aegrotat-calendar ${calendActive? 'active': ''}`} checkDate={(dateActive === 'start')? formData.startDate: formData.endDate} setCheckDate={(data)=>{
                    if(dateActive === 'start')setFormData({...formData, startDate: data}) 
                    if(dateActive === 'end')setFormData({...formData, endDate: data}) 
                    setCalendActive(false)
                    setDateActive('')
                }}/>
            </div>

            <div className='user-popup__add-aegrotat-subtitle mt-12'>Укажите период действия справки</div>

            <div className='popup__error'>{error}</div>

            <button className={`popup__button ${loading? 'deactive-button': ''}`} onClick={postData}>Добавить</button>
        </div>
    )
}

export default AddAegrotat;