// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../img/plus-purple-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-page__aegrotat-add-button {
  height: 40px;
  cursor: pointer;
  padding: 8px; }
  .user-page__aegrotat-add-button-label {
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px; }
  .user-page__aegrotat-add-button-icon {
    width: 20px;
    height: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center; }

.user-page__aegrotat-list.scroll-active {
  padding-right: 12px;
  width: 100%;
  max-height: calc(280px);
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden; }
  .user-page__aegrotat-list.scroll-active::-webkit-scrollbar {
    width: 10px; }
  .user-page__aegrotat-list.scroll-active::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 5px; }
  .user-page__aegrotat-list.scroll-active::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #f3f3f3; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Documents/AegrotatContainer/index.scss"],"names":[],"mappings":"AACI;EACI,YAAY;EACZ,eAAe;EACf,YAAY,EAAA;EAGZ;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAA;EAGrB;IACI,WAAW;IACX,YAAY;IACZ,oEAAsE,EAAA;;AAI7E;EAEO,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB,EAAA;EAPzB;IAUW,WAAW,EAAA;EAVtB;IAaW,yBAAyB;IACzB,kBAAkB,EAAA;EAd7B;IAiBW,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB,EAAA","sourcesContent":[".user-page__aegrotat{\n    &-add-button{\n        height: 40px;\n        cursor: pointer;\n        padding: 8px;\n        \n\n        &-label{\n            font-size: 18px;\n            font-weight: 500;\n            margin-right: 8px;\n        }\n\n        &-icon{\n            width: 20px;\n            height: 20px;\n            background: url(../../../../img/plus-purple-icon.svg) no-repeat center;\n        }\n    }\n\n    &-list{\n        &.scroll-active{\n            padding-right: 12px;\n            width: 100%;\n            max-height: calc(280px);\n            height: calc(100% - 60px);\n            overflow-y: auto;\n            overflow-x: hidden;\n                \n            &::-webkit-scrollbar{\n                width: 10px;\n            }\n            &::-webkit-scrollbar-track {\n                background-color: #f3f3f3;\n                border-radius: 5px;    \n            }\n            &::-webkit-scrollbar-thumb {\n                background-color: #fff;\n                border-radius: 10px;\n                border: 2px solid #f3f3f3;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
