import './index.scss'
import { convertNumber } from './../../../../services/clientHome';

const Program = ({item, conectedButtonFunc, childrenLength, conectedProgramActive,  programChecked}) =>{
    const {id, name, price, conectedChildren, awaitsDisabledChildren, awaitsConectedChildren, permittedChildren} = item;
    return(
        <div className={`user-page__finance-program d-flex justify-between flex-column ${(conectedProgramActive && programChecked.id === id)? 'conected-program-active': ''}`} key={`user-page__finance-program-${id}`}>
            <div className='user-page__finance-program-name'>{name}</div>
            <div>
                <div className='user-page__underline finance-child-underline'/>
                <div className='user-page__finance-program-price d-flex justify-between align-center'>
                    <div className='user-page__finance-program-price-label'>Цена:</div>
                    <div className='user-page__finance-program-price-val'>
                        {convertNumber(price)}₽<span>/год</span>
                    </div>
                </div>
                <button className={`user-page__finance-program-button connect ${(conectedChildren.length + awaitsDisabledChildren.length + awaitsConectedChildren.length >= permittedChildren.length)? 'deactive-button': ''}`} onClick={()=>conectedButtonFunc('conected', item)}>Подключить</button>
                <button className={`user-page__finance-program-button disable ${(!conectedChildren.length)? 'deactive-button': ''}`} onClick={()=>conectedButtonFunc('disabled', item)}>Отключить</button>
            </div>
        </div>
    )
}

export default Program;