// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__programs-classes-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 0.35fr));
  grid-template-rows: max-content;
  align-content: start;
  gap: 16px;
  justify-items: auto;
  grid-auto-flow: dense;
  padding-right: 12px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden; }
  .admin__programs-classes-list::-webkit-scrollbar {
    width: 10px; }
  .admin__programs-classes-list::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px; }
  .admin__programs-classes-list::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #ececec; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Programs/Classes/index.scss"],"names":[],"mappings":"AAEI;EACI,aAAa;EACb,8DAA8D;EAC9D,+BAA+B;EAC/B,oBAAoB;EACpB,SAAS;EACT,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,kBAAkB,EAAA;EAXrB;IAcO,WAAW,EAAA;EAdlB;IAiBO,yBAAyB;IACzB,kBAAkB,EAAA;EAlBzB;IAqBO,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB,EAAA","sourcesContent":[".admin__programs-classes{\n\n    &-list{\n        display: grid;\n        grid-template-columns: repeat(auto-fit, minmax(270px, 0.35fr));\n        grid-template-rows: max-content;\n        align-content: start;\n        gap: 16px;\n        justify-items: auto;\n        grid-auto-flow: dense;\n        padding-right: 12px;\n        height: calc(100vh - 200px);\n        overflow-y: scroll;\n        overflow-x: hidden;\n            \n        &::-webkit-scrollbar{\n            width: 10px;\n        }\n        &::-webkit-scrollbar-track {\n            background-color: #ececec;\n            border-radius: 5px;\n        }\n        &::-webkit-scrollbar-thumb {\n            background-color: #fff;\n            border-radius: 10px;\n            border: 2px solid #ececec;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
