import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';


export const fetchAdminTabelData1 = createAsyncThunk(
  'AdminTabel/fetchAdminTabelData',
  async (dataObj={query: '', filters:null}) => {
    
    let postData = {count: dataObj.count, page: dataObj.page};
    if(dataObj.query) postData.query = dataObj.query;

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/5?`+ new URLSearchParams({...postData, ...dataObj.filters}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


export const fetchAdminTabelData = createAsyncThunk(
  'TeacherTabel/fetchAdminTabelData',
  async (dataObj={filters:{}}) => {    
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-student-low?`+ new URLSearchParams({...dataObj.filters, page: 0, count: 1000}))      
      if(response.status < 400){
        let students = response.data.data;
        if(students.length){
          const response = await axiosProxy.get(`/api/v1/administrative/tabel/get-tabel?`+ new URLSearchParams(dataObj.filters))
          if(response.status < 400){

            let tabelData = response.data.data;
            if(tabelData){
              let studentsId = students.map(item=>item.id)
              tabelData = JSON.parse(tabelData);
              if(tabelData.tabel && tabelData.tabel.length){
                let tabelStudentsId = tabelData.tabel.map(item=>item.id)
                let newStudents = students.filter(item=> tabelStudentsId.indexOf(item.id) === -1)
                if(!newStudents.length){
                  tabelData.tabel = tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1)
                }
                else{
                  if(tabelData.tabel[0].lessons && tabelData.tabel[0].lessons.length){
                    let lessons = tabelData.tabel[0].lessons.map(item=>({...item, value: ''}))
                    newStudents = newStudents.map(item=>({...item, lessons}))
                  }
                  tabelData.tabel = [...tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1), ...newStudents];
                }
              }
            }
            else{
              tabelData = {
                tabel: students,
                passDates: []
              }
            }


            return {tabel: tabelData.tabel.sort((a, b) => a.name > b.name ? 1 : -1), passDates: tabelData.passDates, isClosed: (response.data.isClosed)? response.data.isClosed: false};
          }
          else{
            // console.log(response.response.statusText)
          } 
        }
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


