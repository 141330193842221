import Header from '../../Header';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

import './index.scss'
import ProgramsListElem from './ProgramsListElem/ProgramsListElem';
import { setPopupBack } from '../../../../redux/popup/popupSlice';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopup from './../../Popup/AdminPopup';
import DeleteBlock from './../../Popup/DeleteBlock/DeleteBlock';
import WarningBlock from './../../Popup/WarningBlock/WarningBlock';
import Students from './Students/Students';
import { selectAdminProgramStudents } from '../../../../redux/admin/programs/students/selectors';
import { fetchAdminProgramStudentsData, fetchAdminProgramStudentsSubgroupData } from '../../../../redux/admin/programs/students/asyncActions';
import StudentsSubgroup from './StudentsSubgroup';
import { selectAdminProgramsPagePrograms } from './../../../../redux/admin/programs/programs/selectors';
import { fetchAdminProgramsProgramsData } from './../../../../redux/admin/programs/programs/asyncActions';
import { selectAdminProgramsClasses } from './../../../../redux/admin/programs/classes/selectors';
import { setAdminProgramsAdditionalyProgramsDataDelete, setAdminProgramsProgramsDataDelete } from '../../../../redux/admin/programs/programs/programsSlice';
import { axiosProxyCatchGet, axiosProxyCatchPost } from '../../../../axiosProxy';
import { useDebouncedValue } from '../../../../services/clientHome';
import { setAdminProgramsStudentsDataEdit } from '../../../../redux/admin/programs/students/studentsSlice';


const Programs = () =>{
    const dispatch = useDispatch();

    const [query, setQuery] = useState('')
    const [popupType, setPopupType] = useState('')
    const [subGroupActive, setSubGroupActive] = useState(false)
    const [selectedProgram, setSelectedProgram] = useState({id: 0})
    
    const searchText = useDebouncedValue(query, 600)

    const { adminProgramsStudentsData, adminProgramsStudentsSubgroupData } = useSelector(selectAdminProgramStudents);
    const { adminProgramsProgramsData:programsData, adminProgramsAdditionalyProgramsData:additionalyProgramsData, adminProgramsCheckedProgram } = useSelector(selectAdminProgramsPagePrograms);
    const { adminProgramsCheckedClass:checkClass } = useSelector(selectAdminProgramsClasses);


    useEffect(()=>{
        dispatch(fetchAdminProgramStudentsData({query, classId: checkClass.id}))
        dispatch(fetchAdminProgramsProgramsData({query: query, classId: checkClass.id}))
    }, [searchText])

    useEffect(()=>{
        if(selectedProgram.id){
            dispatch(fetchAdminProgramStudentsSubgroupData({programId: selectedProgram.id}))
            // else dispatch(setAdminProgramsStudentsDataEdit(adminProgramsStudentsData))
        }
    }, [selectedProgram])


    const deletedProgram = (setLoading) =>{
        // if(adminProgramsCheckedProgram.type === 1){
        //     dispatch(setAdminProgramsProgramsDataDelete(adminProgramsCheckedProgram.id))
        //     setPopupType('')
        // }
        // else{
        //     // dispatch(setAdminProgramsAdditionalyProgramsDataDelete(adminProgramsCheckedProgram.id))
        //     setPopupType('')
        // }


        axiosProxyCatchPost({
            url:'/api/v1/administrative/delete-program', 
            data: {id: adminProgramsCheckedProgram.id},
            resolved: (data)=>{
                if(adminProgramsCheckedProgram.type === 1){
                    dispatch(setAdminProgramsProgramsDataDelete(adminProgramsCheckedProgram.id))
                    setPopupType('')
                }
                else{
                    dispatch(setAdminProgramsAdditionalyProgramsDataDelete(adminProgramsCheckedProgram.id))
                    setPopupType('')
                }
                setLoading(false)
            },
            rejected: (error)=>{
                setPopupType('Warning')
                setLoading(false)
            }
        })
    }

    return(
        <div className={`admin__programs-program`} onClick={(e)=>{
            // if(!e.target.classList.contains('click-button') && e.target.closest(".admin__programs-list-elem") === null && e.target.closest(".popup__back") === null && e.target.closest(".admin__programs-program-students") === null){                
            //     setSelectedProgram({id: 0})
            // }
        }}>
            <Header query={query} setQuery={setQuery}/>
            <div className='admin-wrapper'>
                <div className="admin__programs-top">
                    <NavLink to={`/schools/classes`} className="admin__programs-top-back d-flex align-center">
                        <div className='admin__programs-top-back-icon'/>
                        <div className='admin__programs-top-back-label'>{checkClass.number}{checkClass.letter} класс</div>
                    </NavLink>
                    <div className='admin__programs-top-buttons d-flex' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('AddProgram')
                    }}>
                        <button className='admin__programs-top-button d-flex align-center'>
                            <div className='admin__programs-top-button-label'>Создать программу</div>
                            <div className='admin__programs-top-button-icon plus'/>
                        </button>
                    </div>
                </div>
            </div>
            <div className='admin-wrapper'>
                <div className='admin__programs-program-title mb-16'>Основные программы</div>

                <div className='admin__programs-program-list mb-24'>
                    {
                        programsData.map(item=><ProgramsListElem item={item} selectedProgram={selectedProgram} setSelectedProgram={setSelectedProgram} setSubGroupActive={setSubGroupActive} setPopupType={setPopupType} key={`admin__programs-program-list-elem-container-${item.id}`}/>)
                    }

                    {
                        (programsData.length)? null:(
                            <>
                            <div className={`admin__programs-list-elem admin__programs-program-list-elem cursor-def default`}>
                                <div className='admin__programs-list-elem-top d-flex justify-between'>
                                    <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                                        <div className="admin__programs-list-elem-title-label wd-1"><div className='admin__programs-program-list-elem-default-text'/></div>
                                    </div>
                                    <div className='admin__programs-list-elem-btn-container'>
                                        <div className='admin__programs-list-elem-btn setting'/>
                                    </div>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-teachers d-flex'>
                                    <div className='admin__programs-program-list-elem-teachers-icon'/>
                                    <div className="admin__programs-program-list-elem-teachers-label"><div className='admin__programs-program-list-elem-default-text'/></div>
                                </div>
                            </div>
                            <div className={`admin__programs-list-elem admin__programs-program-list-elem cursor-def default`}>
                                <div className='admin__programs-list-elem-top d-flex justify-between'>
                                    <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                                        <div className="admin__programs-list-elem-title-label wd-1"><div className='admin__programs-program-list-elem-default-text'/></div>
                                    </div>
                                    <div className='admin__programs-list-elem-btn-container'>
                                        <div className='admin__programs-list-elem-btn setting'/>
                                    </div>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-teachers d-flex'>
                                    <div className='admin__programs-program-list-elem-teachers-icon'/>
                                    <div className="admin__programs-program-list-elem-teachers-label"><div className='admin__programs-program-list-elem-default-text'/></div>
                                </div>
                            </div>
                            <div className={`admin__programs-list-elem admin__programs-program-list-elem cursor-def default`}>
                                <div className='admin__programs-list-elem-top d-flex justify-between'>
                                    <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                                        <div className="admin__programs-list-elem-title-label wd-1"><div className='admin__programs-program-list-elem-default-text'/></div>
                                    </div>
                                    <div className='admin__programs-list-elem-btn-container'>
                                        <div className='admin__programs-list-elem-btn setting'/>
                                    </div>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-additionaly'>
                                    <div className='admin__programs-program-list-elem-default-text wd-50-mr-16 grey'/>
                                </div>
                                <div className='admin__programs-program-list-elem-teachers d-flex'>
                                    <div className='admin__programs-program-list-elem-teachers-icon'/>
                                    <div className="admin__programs-program-list-elem-teachers-label"><div className='admin__programs-program-list-elem-default-text'/></div>
                                </div>
                            </div>                            
                            </>
                        )
                    }
                </div>
                {
                    (additionalyProgramsData.length)?(
                        <>
                            <div className='admin__programs-program-title mb-16'>Дополнительные программы</div>
                            <div className='admin__programs-program-list mb-60'>
                                {
                                    additionalyProgramsData.map(item=><ProgramsListElem item={item} selectedProgram={selectedProgram} setSelectedProgram={setSelectedProgram} setSubGroupActive={setSubGroupActive} setPopupType={setPopupType} key={`admin__programs-program-list-elem-container-${item.id}`}/>)
                                }
                            </div>
                        </>
                    ): null
                }
            </div>

            <div className='admin-wrapper'>
                {
                    ((subGroupActive && selectedProgram.id) || (selectedProgram.id && selectedProgram.isContainsSubgroup))?( 
                        <StudentsSubgroup 
                            classes={'admin__programs-program-students'}
                            data={(selectedProgram.isContainsSubgroup? adminProgramsStudentsSubgroupData: adminProgramsStudentsData)}
                            selectedProgram={selectedProgram}
                            subGroupActive={subGroupActive}
                            setSubGroupActive={setSubGroupActive}
                            checkClass={checkClass}
                            setSelectedProgram={setSelectedProgram}
                        />
                    )
                    :<Students 
                        classes={'admin__programs-program-students'}
                        data={adminProgramsStudentsData}
                        subGroupActive={subGroupActive} 
                        setSubGroupActive={setSubGroupActive}
                        selectedProgram={selectedProgram}
                    />
                }
            </div>

            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title:'Удаление программы', subtitle:<>Вы действительно хотите удалить программу <span>{adminProgramsCheckedProgram.name}</span>?</>, closePopup: setPopupType, clickFunk:deletedProgram}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle:'Не удалось удалить программу, попробуйте еще раз.', closePopup: setPopupType}}/>
            {/* <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'ВНИМАНИЕ', subtitle:'Эту программу нельзя удалить, за ней закреплены дети.', closePopup: setPopupType}}/> */}
        </div>
    )
}

export default Programs;