import './index.scss'
import { axiosProxyCatchPost } from './../../axiosProxy';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = ({setAuthPage, data, setConfirmData}) =>{

    const navigation = useNavigate()

    const confirmEmailFunc = () =>{
        axiosProxyCatchPost({
            url: '/api/v1/identity/confirm-user',
            data: data,
            resolved: (data)=>{
                navigation('/')
            },
            rejected: (error)=>{
                
            },
            isNotAuthorize: true
        })
    }

    useEffect(()=>{
        if(data.code && data.email){
            confirmEmailFunc()
        }
    }, [])

	return (
        <form className="autorization__content-form">
            <div className="autorization__content-form-title">Подтверждение почты</div>
            <div className='autorization__content-form-subtitle'>{(data.code && data.email)? `Ваша почта ${data.email} подтверждена, можете войти в личный кабинет.`: `Письмо с подтверждением аккаунта отправлено на Вашу почту ${data.email}.`}</div>
            <div className='autorization__content-form-registration-btn' onClick={()=>{
                setConfirmData({email: null, code: null})
                setAuthPage('')
                navigation('/')
            }}>Войти в личный кабинет</div>
        </form>
	)
}
export default ConfirmEmail;