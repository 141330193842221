import { useState, useEffect } from 'react';
import DocumentTemplatesElem from './DocumentTemplatesElem';
import './index.scss'

const DocumentTemplates = ({data, checkedElems, classes, addDocumentTemplates}) =>{
    const [checkTemplates, setCheckTemplates] = useState([])

    const checkTemplatesFunc = (id) =>{
        if(checkTemplates.indexOf(id) === -1)
            setCheckTemplates([...checkTemplates, id])
        else
            setCheckTemplates(checkTemplates.filter(item=> item !== id))
    }

    useEffect(()=>{
        setCheckTemplates([])
    }, [classes])

    return(
        <div className={`admin-finance-popup__documents-templates-list ${classes}`}>
            <div className='admin-finance-popup__documents-templates-list-scroll'>
                {
                    data.filter(item =>checkedElems.indexOf(item.id) === -1).map(item => <DocumentTemplatesElem item={item} key={`admin-finance-popup__documents-templates-list-item-container-${item.id}`} isActive={checkTemplates.indexOf(item.id) !== -1} clickFunc={checkTemplatesFunc}/>)
                }
            </div>
            <button className='admin-finance-popup__documents-templates-buttton mt-16'>Добавить</button>
        </div>
    )
}

export default DocumentTemplates;