import './index.scss'
import HeaderProfileBlock from './../../HeaderProfileBlock/index';

const Header = ({query='', setQuery=null, isDisabledSearch=false}) =>{
    return(
        <div className={`admin__header`}>
            <div className="admin-wrapper d-flex justify-between align-center">
                
                {
                    !isDisabledSearch?(
                        <div className={`admin__header-search`}>
                            <input type="text" className='admin__header-search-input' placeholder='Поиск' value={query} onChange={(e)=>setQuery(e.target.value)}/>
                        </div>
                    ) : <div/>
                }
                <HeaderProfileBlock  user={{id: 1, name: 'Администратор'}}/>
            </div>
        </div>
    )
}

export default Header;