import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { City } from '../../../../Filters/FiltersList';
import { selectAdminProgramsSchools } from './../../../../../redux/admin/programs/schools/selectors';
import { setAdminProgramsSchoolFilters } from '../../../../../redux/admin/programs/schools/schoolsSlice';



const SchoolFilters = ({popupBack, closePopup, clickFunk}) =>{
    const dispatch = useDispatch()
    const { adminProgramsSchoolFilters } = useSelector(selectAdminProgramsSchools);

    const [activeList, setActiveList] = useState('')

    const [formData, setFormData] = useState({
        city: {id: 0, name: ''}, 
    })
    const [searchData, setSearchData] = useState({
        city: '', 
    })

    useEffect(()=>{
        setFormData(adminProgramsSchoolFilters)
        setSearchData({city: ''})
        setActiveList('')
    }, [popupBack, adminProgramsSchoolFilters.city.id])

    
    const filtersConfirm = () =>{
        dispatch(setAdminProgramsSchoolFilters(formData))
        closePopup()
    }

    const filtersClean = () =>{
        setFormData({city:{id: 0, name: ''}})
        dispatch(setAdminProgramsSchoolFilters({city: {id: 0, name: ''}}))
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input')){
                setActiveList('')
                setSearchData({city: ''})
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                url='/api/v1/administrative/programs'
            />

            <div className='d-flex mt-24'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={filtersClean}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${(formData.city.id !== 0 && formData.city.id === adminProgramsSchoolFilters.city.id || formData.city.id === 0)? 'deactive-button': ''}`} onClick={filtersConfirm}>Применить</button>
            </div>       
        </div>
    )
}

export default SchoolFilters;