import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';
// import axios from 'axios';





export const fetchPaymentHistoryData = createAsyncThunk(
  'UserPaymentHistory/fetchPaymentHistoryData',
  async (dataObj={count:6, page:0, isScrolling: false}) => {
    let postData = {count: dataObj.count, page: dataObj.page};
    try {
      const response = await axiosProxy.get(`/api/v1/parental/finance/get-payments?`+ new URLSearchParams(postData))
      
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                       
      }
      else{
        console.log(response.response)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);


