import { createSlice } from '@reduxjs/toolkit';
import { programsData } from '../../../../defaultData/admin/programs/programs';
import { fetchAdminProgramsAdditionalyProgramsData, fetchAdminProgramsProgramsData } from './asyncActions';

const initialState = {
  adminProgramsProgramsData:[ 
    // ...programsData
  ],
  adminProgramsAdditionalyProgramsData:[

  ],

  adminProgramsCheckedProgram:{
    id: 0,
    name: "",
    lessonsCount: 0,
    price: 0,
    type: 0,
    teachers: []
},

};

const adminProgramsPrograms = createSlice({
  name: 'AdminProgramsPagePrograms',
  initialState,
  reducers: {
    setAdminProgramsProgramsData(state, action) {
      state.adminProgramsProgramsData = action.payload;      
    },
    setAdminProgramsProgramsDataEdit(state, action) {
      state.adminProgramsProgramsData = state.adminProgramsProgramsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminProgramsProgramsDataAdd(state, action) {
      state.adminProgramsProgramsData = [...state.adminProgramsProgramsData, action.payload];
    },
    setAdminProgramsProgramsDataDelete(state, action) {
      state.adminProgramsProgramsData = state.adminProgramsProgramsData.filter(item=> item.id !== action.payload);
    },


    setAdminProgramsCheckedProgram(state, action) {
        state.adminProgramsCheckedProgram = {...state.adminProgramsCheckedProgram, ...action.payload};      
    },



    setAdminProgramsAdditionalyProgramsData(state, action) {
      state.adminProgramsAdditionalyProgramsData = action.payload;      
    },
    setAdminProgramsAdditionalyProgramsDataEdit(state, action) {
      state.adminProgramsAdditionalyProgramsData = state.adminProgramsAdditionalyProgramsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminProgramsAdditionalyProgramsDataAdd(state, action) {
      state.adminProgramsAdditionalyProgramsData = [...state.adminProgramsAdditionalyProgramsData, action.payload];
    },
    setAdminProgramsAdditionalyProgramsDataDelete(state, action) {
      state.adminProgramsAdditionalyProgramsData = state.adminProgramsAdditionalyProgramsData.filter(item=> item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProgramsProgramsData.pending, (state, action) => {
    });
    builder.addCase(fetchAdminProgramsProgramsData.fulfilled, (state, action) => {
        if(action.payload && action.payload.programs && Array.isArray(action.payload.programs)){
            state.adminProgramsProgramsData = action.payload.programs.filter(item=> item.type === 1);
            state.adminProgramsAdditionalyProgramsData = action.payload.programs.filter(item=> item.type === 2);
        }
    });
    builder.addCase(fetchAdminProgramsProgramsData.rejected, (state, action) => {
    });

    
  },

});

const { actions, reducer } = adminProgramsPrograms;


export default reducer;

export const {
    setAdminProgramsProgramsData,
    setAdminProgramsProgramsDataEdit,
    setAdminProgramsProgramsDataAdd,
    setAdminProgramsProgramsDataDelete,
    setAdminProgramsProgramsScrollReset,
    setAdminProgramsCheckedProgram,

    setAdminProgramsAdditionalyProgramsData,
    setAdminProgramsAdditionalyProgramsDataEdit,
    setAdminProgramsAdditionalyProgramsDataAdd,
    setAdminProgramsAdditionalyProgramsDataDelete,
    setAdminProgramsAdditionalyProgramsScrollReset,
} = actions;

