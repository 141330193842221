// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../img/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 248px;
  height: 100vh;
  border-right: 1px solid #E4E4E4;
  background-color: #fff; }
  .admin__sidebar-logo {
    width: 91px;
    height: 47px;
    margin: 18px 0 38px 28px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
    background-size: contain; }

.pl-sidebar {
  padding-left: 248px; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Sidebar/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,+BAA+B;EAC/B,sBAAsB,EAAA;EAEtB;IAGI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,oEAAuD;IAEvD,wBAAwB,EAAA;;AAIhC;EACI,mBAAmB,EAAA","sourcesContent":[".admin__sidebar{\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 248px;\n    height: 100vh;\n    border-right: 1px solid #E4E4E4;\n    background-color: #fff;\n\n    &-logo{\n        // width: 71px;\n        // height: 36px;\n        width: 91px;\n        height: 47px;\n        margin: 18px 0 38px 28px;\n        background: url(../../../img/logo.svg) center no-repeat;\n        // background: url(../../../img/admin-sidebar-logo.svg) center no-repeat;\n        background-size: contain;\n    }\n}\n\n.pl-sidebar{\n    padding-left: 248px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
