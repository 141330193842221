import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';

import './index.scss'
import PaymentHistoryListElem from './PaymentHistoryListElem';
import { selectAdminFinancePaymentHistory } from '../../../../../redux/admin/finance/paymentsHistory/selectors';
import { fetchFinanceUserPaymentHistoryData } from '../../../../../redux/admin/finance/paymentsHistory/asyncActions';
import { useEffect, useState } from 'react';
import { setAdminFinanceUserPaymentHistoryScrollReset } from '../../../../../redux/admin/finance/paymentsHistory/paymentsHistorySlice';
import { scrollingPagination, useDebouncedValue } from './../../../../../services/clientHome';
import ExportButton from '../../../../Export';


const PaymentHistory = ({setPopupType, query, checkedClient, activeList, setActiveList}) =>{    
    const dispatch = useDispatch();
    const { adminFinanceUserPaymentHistoryData, adminFinanceUserPaymentHistoryPageLast: pageLast, adminFinanceUserPaymentHistoryScrollReset: scrollReset, adminFinanceUserPaymentHistoryLoading:loading, adminFinanceUserPaymentHistoryFilters:filters } = useSelector(selectAdminFinancePaymentHistory);

    const [page, setPage] = useState(0)

    const searchText = useDebouncedValue(query, 600)
    
    useEffect(()=>{
        dispatch(fetchFinanceUserPaymentHistoryData({query, count: 30, page: 0, pageLast, clientId: checkedClient.id, isScrolling: false, filters: filters.post, date: filters.date}))
        setPage(0)
    }, [filters, searchText])

    const getExportParams = () =>{
        return {}
    }

    return(
        <div className={`admin__finance-container user-payment-history `}>
            <div className='admin__finance-container-top d-flex justify-between align-center mb-16'>
                <div className='admin__finance-title'>История платежей</div>

                <div className='d-flex align-center'>
                    <button className='admin__finance-button d-flex align-center mr-8' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('UserCardHistoryFilters')
                    }}>
                        <div className='admin__finance-button-label'>Фильтры</div>
                        <div className='admin__finance-button-icon filters'/>
                    </button>

                    <ExportButton name={'admin-finance-clients-export'} activeList={activeList} setActiveList={setActiveList} elems={[]}/>

                </div>
            </div>

            <div className='admin__finance-table-line title d-flex justify-between'>
                <div className='admin__finance-user-card-payment-history-table-line-item name'>ФИО</div>
                <div className='admin__finance-user-card-payment-history-table-line-item date-summ'>Дата и сумма</div>
                <div className='admin__finance-user-card-payment-history-table-line-item payment-type'>Способ оплаты</div>
            </div>

            <div className='admin__finance-table'   
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchFinanceUserPaymentHistoryData({query, count: 30, page: page+1, parrentId: checkedClient, isScrolling: true})),
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setAdminFinanceUserPaymentHistoryScrollReset(false))
                )}
            >
                {
                    adminFinanceUserPaymentHistoryData.map(item=><PaymentHistoryListElem item={item} key={`admin__finance-user-card-payment-history-table-line-item-conatiner-${item.id}`}/>)
                }
            </div>
        </div>
    )
}

export default PaymentHistory;