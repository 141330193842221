import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminAplicationsData = createAsyncThunk(
  'AdminAplications/fetchAdminAplicationsData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', filters:null, month:''}) => {
    
    let postData = {count: dataObj.count, page: dataObj.page};
    if(dataObj.query) postData.query = dataObj.query;
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-applications?`+ new URLSearchParams({...postData, ...dataObj.filters}) + dataObj.month)
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);



export const fetchAdminTeachersAplicationsData = createAsyncThunk(
  'AdminAplications/fetchAdminTeachersAplicationsData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', filters:null}) => {
    
    let postData = {count: dataObj.count, page: dataObj.page};
    if(dataObj.query) postData.query = dataObj.query;
    try {
      // const response = await axiosProxy.get(`/api/v1/administrative/applications/get-teachers-applications`)
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-teachers-applications?`+ new URLSearchParams({...postData, ...dataObj.filters}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


