import { getDocumentType } from "../../../../../../services/clientHome"


export const getStatus = (id) =>{
    if(id === 2)
        return {color: 'green', label: ''}
    else if(id === 1)
        return {color: 'red', label: ''}
    else
        return {color: '', label: ''}
}

const DocumentsListElem = ({item}) =>{
    const {id, name, startDate, endDate, date, status, type} = item;
    const typeObj = getDocumentType(type)
    return(
        <div className={`admin__finance-user-card-documents-list-item d-flex align-center`}>
            <div className={`admin__finance-user-card-documents-list-item-icon ${getStatus(status).color}`}/>
            <div className='admin__finance-user-card-documents-list-item-document-data'>
                <div className="admin__finance-user-card-documents-list-item-document-data-name">{(name)? name: (startDate && endDate)? `${startDate} - ${endDate}`: 'Неизвестно'}</div>
                <div className="admin__finance-user-card-documents-list-item-document-data-date-type d-flex align-center">
                    <div>{date}</div> <span/> <div>{typeObj.label}</div>
                </div>
            </div>
        </div>
    )
}

export default DocumentsListElem;