import { createSlice } from '@reduxjs/toolkit';


const initialState = {
   listName: ''
};



const dropDownList = createSlice({
  name: 'dropDownList',
  initialState,
  reducers: {
    setDropListName(state, action) {
        state.listName = action.payload;
    },
  },
});

const { actions, reducer } = dropDownList;


export default reducer;

export const { 
  setDropListName
} = actions;

