import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminProgramStudentsData = createAsyncThunk(
  'AdminProgramStudents/fetchAdminProgramStudentsData',
  async (dataObj={query: '', classId: 0}) => {
    let postData = {classId: dataObj.classId, page: 0, count: 1000};
    // if(dataObj.query) postData.query = dataObj.query;

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-student-low?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {students: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminProgramStudentsSubgroupData = createAsyncThunk(
  'AdminProgramStudents/fetchAdminProgramStudentsSubgroupData',
  async (dataObj={programId: 0}) => {
    let postData = {programId: dataObj.programId, page: 0, count: 1000};
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/programs/get-subgroups?`+ new URLSearchParams(postData))
      if(response.status < 400){
        return {students: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


