// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/discount-purple-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-poup__class-edit-discount-input {
  padding-right: 40px; }

.admin-poup__class-edit-discount::before {
  content: '';
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
  transition: .3s all; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Programs/ClassEdit/index.scss"],"names":[],"mappings":"AAEQ;EACI,mBAAmB,EAAA;;AAF1B;EAMO,WAAW;EACX,oEAA6E;EAC7E,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,mBAAmB,EAAA","sourcesContent":[".admin-poup__class-edit{\n    &-discount{        \n        &-input{\n            padding-right: 40px;\n        }\n\n        &::before{\n            content: '';\n            background: url(../../../../../img/discount-purple-icon.svg) no-repeat center;\n            display: block;\n            width: 24px;\n            height: 24px;\n            position: absolute;\n            right: 16px;\n            top: calc(50% - 12px);\n            transition: .3s all;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
