import React from 'react';
import { createPortal } from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';

// import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';

import '../../../main.scss'
import './index.scss';
// import ClassEdit from './Programs/ClassEdit';
import { selectPopup } from './../../../redux/popup/selectors';
import { setPopupBack } from '../../../redux/popup/popupSlice';
// import ProgramEdit from './Programs/ProgramEdit/index';
// import SchoolFilters from './Programs/SchoolFilters/index';
// import SchoolEdit from './Programs/SchoolEdit/index';
import ProgramsPopup from './Programs';
import ApplicationPopup from './Applications';
import FinancePopup from './Finance/index';
import TeachersPopup from './Teachers/index';
import TabelPopup from './Tabel';



const PopupContent = ({type, props}) => {
    const dispatch = useDispatch()
    const {popupBack} = useSelector(selectPopup)

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            if(type === ''){
                if(props.popupTypeEdit) props.popupTypeEdit('')
                if(props.setConectedProgramActive) props.setConectedProgramActive('')
            }
            dispatch(setPopupBack(''))
        }         
    }

    const unDisplay = () =>{
        if(type === '') props.popupTypeEdit('')
        dispatch(setPopupBack(''))
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                <ProgramsPopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
                <ApplicationPopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
                <FinancePopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
                <TeachersPopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
                <TabelPopup unDisplay={unDisplay} popupBack={popupBack} type={type} props={props}/>
            </div>
        </div>
	)
}

const AdminPopup = ({type, props = null}) =>{
    return createPortal(<PopupContent type={type} props={props} />, document.body)
}
export default AdminPopup;