import { useState, useEffect } from 'react';
import axiosProxy from './../axiosProxy';



export const monthData = [
    {
        id: 1,
        label: 'Январь',
        date: new Date(new Date().getFullYear(), 0, 1),
    },
    {
        id: 2,
        label: 'Февраль',
        date: new Date(new Date().getFullYear(), 1, 1)
    },
    {
        id: 3,
        label: 'Март',
        date: new Date(new Date().getFullYear(), 2, 1)
    },
    {
        id: 4,
        label: 'Апрель',
        date: new Date(new Date().getFullYear(), 3, 1)
    },
    {
        id: 5,
        label: 'Май',
        date: new Date(new Date().getFullYear(), 4, 1)
    },
    {
        id: 6,
        label: 'Июнь',
        date: new Date(new Date().getFullYear(), 5, 1)
    },
    {
        id: 7,
        label: 'Июль',
        date: new Date(new Date().getFullYear(), 6, 1)
    },
    {
        id: 8,
        label: 'Август',
        date: new Date(new Date().getFullYear(), 7, 1)
    },
    {
        id: 9,
        label: 'Сентябрь',
        date: new Date(new Date().getFullYear(), 8, 1)
    },
    {
        id: 10,
        label: 'Октябрь',
        date: new Date(new Date().getFullYear(), 9, 1)
    },
    {
        id: 11,
        label: 'Ноябрь',
        date: new Date(new Date().getFullYear(), 10, 1)
    },
    {
        id: 12,
        label: 'Декабрь',
        date: new Date(new Date().getFullYear(), 11, 1)
    },
]


export const scrollingPagination = (e, setPage, fetchData, pageLast, loading, scrollReset=false, setScrollReset=()=>{}, minusHeight = 200) =>{
    let scrollTop = e.target.offsetHeight + e.target.scrollTop;
    let height = e.target.scrollHeight;    
    
    if( +scrollTop > height - minusHeight && !pageLast && !scrollReset && !loading){
        fetchData()
        setPage()
    }

    if(scrollReset){
        e.target.scrollTo(0, 0);
        setScrollReset()
    }
}

export const DateFilterConvert = (range, month, year) =>{
    let monthKey = {
        "Январь": 1,
        "Февраль": 2,
        "Март": 3,
        "Апрель": 4,
        "Май": 5,
        "Июнь": 6,
        "Июль": 7,
        "Август": 8,
        "Сентябрь": 9,
        "Октябрь": 10,
        "Ноябрь": 11,
        "Декабрь": 12
    }
    let date = [];
    if(range.length === 0 && (month === 'Месяц' || month === null) && (year === 'Год' || year === null)){
        date = null;
    }
    else if(range.length === 0 && (month !== 'Месяц' && month !== null) && (year !== 'Год' && year !== null)){
        date[0] = new Date(year, monthKey[month]-1, 1);
        date[1] = new Date(year, monthKey[month], 1);
        date[1].setDate(date[1].getDate() - 1);
    }
    else if(range.length === 0 && (month === 'Месяц' || month === null) && (year !== 'Год' && year !== null)){
        date[0] = new Date(year, 0, 1);
        date[1] = new Date(year, 11, 31);
    }
    else if(range.length === 2){
        date = range;
        if(date[0].toLocaleDateString() === date[1].toLocaleDateString())
            date[1].setDate(date[1].getDate() + 1);
    }
    
    return date? {from: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date[0]).replaceAll('/', '.'), to: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date[1]).replaceAll('/', '.')}: null
}

export const convertNumber = (number) =>{
    return number? new Intl.NumberFormat("ru-RU").format(number): 0;
}


export const getDateFormatFunc = (date) =>{    
    let result = date.replace('г', '').split('.'); 
    result = new Date(`${+result[1]}/${+result[0]}/${+result[2]}`);
    return result;
}

export const getDateFormatFuncServer = (date) =>{    
    let result = date.replace('г', '').split('.');   
    result = new Date(+result[2], +result[1]-1, +result[0]+1, 0,0,0);
    return result;
}

export const getDatePostFormatFunc = (date) =>{
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(date).replaceAll('/', '.')

}

export const getResponseDateFormatFunc = (date, isString=false) =>{
    let result = new Date(date)
    result.setDate(result.getDate() - 1)
    return isString? result.toLocaleDateString(): result
}


export const UploadFileFunc = (e, resolved) =>{    
    const reader = new FileReader();
    const file = e.target.files[0];
    let resultFile = {}
    if(file !== undefined){
        reader.readAsArrayBuffer(file);
        reader.addEventListener('load', async(event) => {
            resultFile = {
                name: file.name.replace(`.${file.name.substring(file.name.lastIndexOf('.') + 1)}`, ''),
                size: convertFileSize(file.size),
                extension: file.name.substring(file.name.lastIndexOf('.')),
                link: event.target.result,
                file: file,
                fileBytes: Array.prototype.slice.call(new Uint8Array(event.target.result)).join(' '),
                // fileBytes: Array.prototype.slice.call(new Uint8Array(event.target.result)),
                // fileBytes: Array.prototype.slice.call(new Uint8Array(event.target.result)).join(''),
            };
            e.target.value = ''   
                               
            resolved(resultFile)
        });
    }
    // return resultFile
}


export const convertFileSize = (size) =>{
    let result = size;
    if(result < 1024){
        result = +(result).toFixed(2) + 'Б'
    }
    else if(result / 1024 < 1024){
        result = +(result / 1024).toFixed(2) + 'КБ'
    }
    else if(result / 1024 / 1024 < 1024){
        result = +(result / 1024 / 1024).toFixed(2) + 'МБ'
    }
    else if(result / 1024 / 1024 / 1024 < 1024){
        result = +(result / 1024 / 1024 / 1024).toFixed(2) + 'ГБ'
    }
    return result;
}


export const getRequestArray = (name, mass) =>{
    return `&${name}=` + mass.join(`&${name}=`)
}


export const getPaymentTypeObj = (name) =>{
    if(name !== undefined && name){
        if(name === 'SBP'){
            return{ id: 1, name: name, color: 'sbp'}
        }
        else if(name === 'TinkoffPay'){
            return{ id: 2, name: name, color: 'tincoff'}
        }
        else if(name === 'SberPay'){
            return{ id: 3, name: name, color: 'sber'}
        }
        else if(name === 'ВТБ'){
            return{ id: 4, name: name, color: 'vtb'}
        }
        else if(name === 'АЛЬФА БАНК'){
            return{ id: 5, name: name, color: 'alfabank'}
        }
        else{
            return{ id: 0, name: name, color: 'default'}
        }
    }
    else{
        return{ id: 0, name: 'Неизвестно', color: 'default'}
    }
} 

export const getDocumentType = (typeId) =>{
    if(typeId === 1){
        return { id: typeId, label: 'Основные'}
    }
    else if(typeId === 2){
        return { id: typeId, label: 'Дополнительные'}
    }
    else if(typeId === 3){
        return { id: typeId, label: 'Справки'}
    }
    else{
        return { id: typeId, label: 'Неопределенные'}
    }
}

export const getChildPaymentStatus = (status) =>{
    if(status === 0){
        return { id: status, color: 'red', label: 'Не оплачено'}
    }
    else if(status === 1){
        return { id: status, color: 'green', label: 'Оплачено'}
    }
    else if(status === 2){
        return { id: status, color: 'green', label: 'Мат.Капитал'}
    }
    else{
        return { id: -1, color: 'red', label: 'Не найден'}
    }
}



export const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);  // Освобождаем таймер
        };
    }, [value, delay]);

    return debouncedValue;
}


export const isCorrectDate = (date) =>{    
    return getDateFormatFunc(date).toString() !== 'Invalid Date'
}


export const downloadFilePost = ({url, name, type, postData, isNotAuthorize=false, setLoading=()=>{}}) =>{
    setLoading(true)
    axiosProxy.get(url + (postData? new URLSearchParams(postData): ''),
        {
            responseType: 'blob',
            headers: {
                ...{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                    'Content-Disposition': 'attachment'
                },
                ...(isNotAuthorize? {}:
                {
                    'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                })
            }
        }
    ).then((response) => {
        setLoading(false)
        if(response && response.data){
            let href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', name + (type? type: '.pdf'));
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    }).catch(()=>{
        setLoading(false)
    });
}

export const downloadFilePostP = ({url, name, type, postData, isNotAuthorize=false, setLoading=()=>{}}) =>{
    setLoading(true)
    axiosProxy.post(url, postData,
        {
            responseType: 'blob',
            headers: {
                ...{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                    // 'Content-Disposition': 'attachment'
                },
                ...(isNotAuthorize? {}:
                {
                    'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                })
            }
        }
    ).then((response) => {
        setLoading(false)
        if(response && response.data){
            let href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', name + (type? type: '.pdf'));
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    }).catch(()=>{
        setLoading(false)
    });
}


export const checkIsEditingChild = (formData, checkData) =>{
    let result = {}
    
    if(checkData && formData){
        if(formData.name !== checkData.name) result.name = true;
        if(formData.birthDay !== checkData.birthDay) result.birthDay = true;
        if(formData.city.id !== checkData.city.id) result.city = true;
        if(formData.school.id !== checkData.school.id) result.school = true;
        if(formData.classObj.id !== checkData.classObj.id) result.classObj = true;
        if(formData.month && checkData.startMonth && (formData.month.id !== checkData.startMonth || formData.month.year !== new Date(checkData.startDate).getFullYear())) result.month = true;
    }

    return Object.keys(result).length
}

export const correctorName = (name) =>{
    let userName = name.split(' ');
    if((userName)){
        userName = userName.filter(item=> item)
        return userName.join(' ')
    }
    else return name;
}

export const getWordMonthDate = (month) =>{
    let date = monthData.filter(item=> item.label.toUpperCase() === month.toUpperCase())
    if(date.length){
        date = date[0]
    }
    else date = null
    
    return date;
}


export const getPagesMass = (count) =>{    
    // return [...Array(20).keys()].map(i => i+1)
    return [...Array(count).keys()].map(i => i+1)
}

export const getPagesCount = ({dataLength, maxSize, elemsSize, minusVal}) =>{
    let elemsCount =  Math.floor(((maxSize - minusVal) / elemsSize));
    elemsCount = elemsCount? elemsCount: 1;
    let pages = Math.floor(dataLength / elemsCount);
    let additonalPage = (dataLength % elemsCount)? 1: 0;
    
    if(pages + additonalPage >= 1)
        return {pages: pages + additonalPage, elems: elemsCount};
    else
        return {pages: 1, elems: elemsCount};
}

export const getPageElems = ({data, page, elemsCount}) =>{
    if(data.length <= elemsCount)
        return data;
    else{
        return data.slice(elemsCount * (page-1), elemsCount * page)
    }
}




// export const testFunc = () =>{
//     let students = [
//         {
//             id: 1,
//             name: 'Семенов Андрей Петрович1',
//         },
//         {
//             id: 2,
//             name: 'Семенов Андрей Петрович1',
//         },
//         {
//             id: 3,
//             name: 'Семенов Андрей Петрович1',
//         },
//         {
//             id: 4,
//             name: 'Семенов Андрей Петрович1',
//         },
//     ]
//     let tabelData = {
//         tabel: [
//                 {
//                     id: 1,
//                     name: 'Семенов Андрей Петрович1',
//                     lessons: [
//                         {
//                             date: '01.09.2024',
//                             value: 'Н',
//                         },
//                         {
//                             date: '02.09.2024',
//                             value: '',
//                         },
//                         {
//                             date: '03.09.2024',
//                             value: '',
//                         },
//                         {
//                             date: '04.09.2024',
//                             value: 'Б',
//                         }    
//                     ]
//                 },
//                 // {
//                 //     id: 2,
//                 //     name: 'Семенов Андрей Петрович1',
//                 //     lessons: [
//                 //         {
//                 //             date: '01.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '02.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '03.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '04.09.2024',
//                 //             value: '',
//                 //         }    
//                 //     ]
//                 // },
//                 // {
//                 //     id: 3,
//                 //     name: 'Семенов Андрей Петрович1',
//                 //     lessons: [
//                 //         {
//                 //             date: '01.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '02.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '03.09.2024',
//                 //             value: '',
//                 //         },
//                 //         {
//                 //             date: '04.09.2024',
//                 //             value: '',
//                 //         }    
//                 //     ]
//                 // },
//             ],
//             passDates: [],
//         };
//     if(tabelData){
//         let studentsId = students.map(item=>item.id)
//         if(tabelData.tabel && tabelData.tabel.length){
//         let tabelStudentsId = tabelData.tabel.map(item=>item.id)
//         let newStudents = students.filter(item=> tabelStudentsId.indexOf(item.id) === -1)
//         if(!newStudents.length){
//             tabelData.tabel = tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1)
//         }
//         else{
//             if(tabelData.tabel[0].lessons && tabelData.tabel[0].lessons.length){
//             let lessons = tabelData.tabel[0].lessons.map(item=>({...item, value: ''}))
//             newStudents = newStudents.map(item=>({...item, lessons}))
//             }
//             tabelData.tabel = [...tabelData.tabel.filter(item=>studentsId.indexOf(item.id) !== -1), ...newStudents]
//         }
        
//         }
//     }
//     else{
//         tabelData = {
//         tabel: students,
//         passDates: []
//         }
//     }

//     console.log(tabelData);
    
// }


export const getAcademicYear = (year, setLoading=()=>{}) =>{
    setLoading(false)
    return [
        {
            id: 6,
            label: 'Июнь',
            year: year
        },
        {
            id: 7,
            label: 'Июль',
            year: year
        },
        {
            id: 8,
            label: 'Август',
            year: year
        },
        {
            id: 9,
            label: 'Сентябрь',
            year: year
        },
        {
            id: 10,
            label: 'Октябрь',
            year: year
        },
        {
            id: 11,
            label: 'Ноябрь',
            year: year
        },
        {
            id: 12,
            label: 'Декабрь',
            year: year
        },
        {
            id: 1,
            label: 'Январь',
            year: year + 1
        },
        {
            id: 2,
            label: 'Февраль',
            year: year + 1
        },
        {
            id: 3,
            label: 'Март',
            year: year + 1
        },
        {
            id: 4,
            label: 'Апрель',
            year: year + 1
        },
        {
            id: 5,
            label: 'Май',
            year: year + 1
        },
    ]
}

export const getMonthName = (month) =>{
    if(month <=12 && month > 0) return monthData.filter(item => item.id === month)[0].label;
    else return ''
}



export const getUserProfileStatus = (status) =>{
    if(status === -1) return {status, label: 'Верификация отклонена'}
    else if(status === 0) return {status, label: 'Аккаунт ожидает верифицикации'}
    else if(status === 1) return {status, label: 'Аккаунт верифицирован'}
    else if(status === 2) return {status, label: 'Ожидает подтверждения изменений'}
    else if(status === 3) return {status, label: 'Изменения были отклонены'}
    else return {status, label: 'Не найден'}
}