import './index.scss'
import Header from '../../Header';
import { useState, useEffect } from 'react';
import AdminPopup from './../../Popup/AdminPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../redux/popup/popupSlice';
import UsersTableElem from './UsersTableElem';
import { selectAdminAplications } from '../../../../redux/admin/aplications/aplications/selectors';
import { setAdminTabelFilters } from '../../../../redux/admin/tabel/tabelSlice';
import { fetchAdminAplicationsData, fetchAdminTeachersAplicationsData } from '../../../../redux/admin/aplications/aplications/asyncActions';
import { setAdminConectedProgramAplication, setAdminConfirmCertificateAplication, setAdminConfirmDocumentAplication, setAdminEditChildConfirmAplication, setAdminUserEditedAplication, setAdminUserVerificationAplication } from '../../../../redux/admin/aplications/aplicationConfirm/aplicationConfirmSlice';
import { getRequestArray, scrollingPagination, useDebouncedValue } from '../../../../services/clientHome';
import { setAdminAplicationsScrollReset, setAdminTeachersAplicationsScrollReset } from '../../../../redux/admin/aplications/aplications/aplicationsSlice';
import TeachersTableElem from './TeachersTableElem';



const Applications = () =>{
    const dispatch = useDispatch();

    const { 
        adminAplicationsData, pageLast, scrollReset, loading, adminAplicationFilter:filters,
        adminTeachersAplicationsData, teachersAplicationsPageLast, teachersAplicationsScrollReset, teachersAplicationsLoading, adminTeachersAplicationFilter:taechersFilters,
    } = useSelector(selectAdminAplications);

    const [query, setQuery] = useState('')
    const [page, setPage] = useState(0)
    const [teachersPage, setTeachersPage] = useState(0)
    const [popupType, setPopupType] = useState('')
    const [switchActive, setSwitchActive] = useState('users')
    // const [switchActive, setSwitchActive] = useState('users')

    const searchText = useDebouncedValue(query, 600)
    // const searchTecherText = useDebouncedValue(query, 600)

    useEffect(()=>{
        if(switchActive === 'users'){
            dispatch(fetchAdminAplicationsData({query, count: 30, page: 0, isScrolling: false, filters: filters.post, month: (filters.month.length)? getRequestArray('month', filters.month.map(item=> item.id)): ''}))
            setPage(0)
        }
    }, [searchText, filters, switchActive])

    useEffect(()=>{
        if(switchActive === 'teachers'){
            dispatch(fetchAdminTeachersAplicationsData({query, count: 30, page: 0, isScrolling: false, filters: taechersFilters.post}))
            setTeachersPage(0)
        }
    }, [searchText, taechersFilters, switchActive])
    
    const clientClickFunc = (id, type) =>{
        dispatch(setPopupBack('open'))
        if(type.id === 0){
            setPopupType('UserVerification')
            dispatch(setAdminUserVerificationAplication({id}))
        }
        else if(type.id === 1){
            setPopupType('EditUserConfirm')
            dispatch(setAdminUserEditedAplication({applicationId: id}))
        }
        else if(type.id === 2){
            setPopupType('DocumentConfirm')
            dispatch(setAdminConfirmDocumentAplication({id}))
        }
        else if(type.id === 3){
            setPopupType(type.isConnection? 'ProgramConnection': 'ProgramDisconnection')
            dispatch(setAdminConectedProgramAplication({id}))
        }
        else if(type.id === 4){
            setPopupType('CertificateConfirm')
            dispatch(setAdminConfirmCertificateAplication({id}))
        }
        else if(type.id === 5){
            setPopupType('EditChildConfirm')
            dispatch(setAdminEditChildConfirmAplication({applicationId: id}))
        }
    }

    const onScrollFunc = (e) =>{
        if(switchActive === 'users'){
            scrollingPagination(
                e, 
                ()=>setPage(page+1), 
                ()=>dispatch(fetchAdminAplicationsData({query, count: 30, page: page+1, isScrolling: true, filters: filters.post, month: (filters.month.length)? getRequestArray('month', filters.month.map(item=> item.id)): ''})),
                pageLast,
                loading,
                scrollReset, 
                ()=>dispatch(setAdminAplicationsScrollReset(false))
            )
        }
        else{
            scrollingPagination(
                e, 
                ()=>setTeachersPage(teachersPage+1), 
                ()=>dispatch(fetchAdminTeachersAplicationsData({query, count: 30, page: teachersPage+1, isScrolling: true, filters: taechersFilters.post})),
                teachersAplicationsPageLast,
                teachersAplicationsLoading,
                teachersAplicationsScrollReset, 
                ()=>dispatch(setAdminTeachersAplicationsScrollReset(false))
            )
        }
    }

    const teacherAplicationCLick = ({item, type={id: 1}}) => {
        if(type.id === 1){
            dispatch(setPopupBack('open'))
            setPopupType('TabelConfirm')
            dispatch(setAdminTabelFilters(item))
        }
    }


    return(
        <div className={`admin__applications-aplications`}>
            <Header query={query} setQuery={setQuery}/>
            <div className='admin-wrapper'>
                <div className="admin__applications-top">
                    <div className="admin__applications-top-title">
                        Заявки
                    </div>
                </div>

                <div className='admin__applications-table'>
                    <div className="admin__applications-table-top">
                        <div className='admin__applications-table-top-switch d-flex'>
                            <div className={`admin__applications-table-top-switch-active ${switchActive === 'users'? '': 'two'}`}/>
                            <div className={`admin__applications-table-top-switch-item`} onClick={()=>setSwitchActive('users')}>
                                Пользователи
                            </div>
                            {/* <div className={`admin__applications-table-top-switch-item`} onClick={()=>setSwitchActive('teachers')}> */}
                            <div className={`admin__applications-table-top-switch-item deactive-button`} onClick={()=>setSwitchActive('teachers')}>
                                Преподаватели
                            </div>                            
                        </div>

                        <div className='d-flex align-center'>
                            <button className='admin__applications-table-top-button d-flex align-center' onClick={()=>{
                                dispatch(setPopupBack('open'))
                                setPopupType((switchActive === 'users')? 'ApplicationFilters': 'TeacherApplicationFilters')
                            }}>
                                <div className='admin__applications-table-top-button-label'>Фильтры</div>
                                <div className='admin__applications-table-top-button-icon filters'/>
                            </button>
                        </div>
                    </div>

                    <div className='admin__applications-table-underline'/>
                    
                    {
                        (switchActive === 'users')?(
                            <div className='admin__applications-table-line title d-flex justify-between align-center'>
                                <div className="admin__applications-table-line-item type">Вид заявки</div>
                                <div className="admin__applications-table-line-item name">ФИО</div>
                                <div className="admin__applications-table-line-item city">Город</div>
                                <div className="admin__applications-table-line-item school">Школа</div>
                                <div className="admin__applications-table-line-button title">Действие</div>
                            </div>
                        ):(
                            <div className='admin__applications-table-line title d-flex justify-between align-center'>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item city">Город</div>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item school">Школа</div>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item class-name">Класс</div>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item program">Программа</div>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item subgroup">Подгруппа</div>
                                <div className="admin__applications-table-line-item admin__teachers-applications-table-line-item month">Месяц</div>
                                <div className="admin__applications-table-line-button title">Действие</div>
                            </div>
                        )
                    }

                    <div className='admin__applications-table-container'onScroll={(e)=>onScrollFunc(e)}>
                        {
                            (switchActive === 'users')?
                            adminAplicationsData.map(item=><UsersTableElem key={`admin__applications-table-users-elem-${item.id}`} item={item} buttonLabel='Перейти' clickFunc={clientClickFunc}/>)
                            :adminTeachersAplicationsData.map(item=><TeachersTableElem key={`admin__teachers-applications-table-users-elem-${item.id}`} item={item} buttonLabel='Перейти' clickFunc={teacherAplicationCLick}/>)
                        }
                    </div>
                </div>
            </div>
            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
        </div>
    )
}

export default Applications;


{/* <NavLink to={'/programs/classes'}>
    Классы
</NavLink> */}

// clickFunk:(id)=>deletedProgram(id)