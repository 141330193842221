export let applications = [
    {
        id: 1,
        type: 0,
        name: 'Фамилия Имя Отчество 1',
        city: 'Санкт-Петербург',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    {
        id: 3,
        type: 0,
        name: null,
        city: 'Новокузнецк',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    {
        id: 4,
        type: 1,
        name: 'Фамилия Имя Отчество 4',
        city: 'Москва',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    {
        id: 5,
        type: 0,
        name: 'Фамилия Имя Отчество 5',
        city: 'Москва',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    {
        id: 77,
        type: 5,
        name: 'Фамилия Имя Отчество 5',
        city: 'Москва',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    {
        id: 88,
        type: 5,
        name: 'Фамилия Имя Отчество 5',
        city: 'Москва',
        school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    },
    // {
    //     id: 2,
    //     type: 3,
    //     name: 'Фамилия Имя Отчество 5',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 6,
    //     type: 4,
    //     name: 'Фамилия Имя Отчество 6',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 7,
    //     type: 3,
    //     name: 'Фамилия Имя Отчество 7',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 8,
    //     type: 3,
    //     name: 'Фамилия Имя Отчество 8',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 9,
    //     type: 1,
    //     name: 'Фамилия Имя Отчество 9',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 10,
    //     type: 2,
    //     name: 'Фамилия Имя Отчество 10',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 11,
    //     type: 2,
    //     name: 'Фамилия Имя Отчество 11',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 12,
    //     type: 1,
    //     name: 'Фамилия Имя Отчество 12',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 13,
    //     type: 1,
    //     name: 'Фамилия Имя Отчество 13',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 14,
    //     type: 4,
    //     name: 'Фамилия Имя Отчество 13',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 15,
    //     type: 2,
    //     name: 'Фамилия Имя Отчество 13',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 16,
    //     type: 1,
    //     name: 'Фамилия Имя Отчество 13',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
    // {
    //     id: 17,
    //     type: 2,
    //     name: 'Фамилия Имя Отчество 17',
    //     city: 'Москва',
    //     school: 'МБОУ Гудермесская СШ №12 им. А. А. Кадырова'
    // },
];

export let teacherApplications = [
    {
        id: 1,
        city: {id: 1, name: 'Красногорск'},
        school: {id: 1, name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'},
        classObj: {id: 1, number: 1, letter: 'A'},
        program: {id: 1, name: 'Занимательная математика, ступень 1'},
        month: {id: 9, label: 'Сентябрь'},
        subgroup: {id: 1, name: 'Подгруппа 1'},
        year: 2024
    },
    {
        id: 2,
        city: {id: 1, name: 'Красногорск'},
        school: {id: 1, name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'},
        classObj: {id: 1, number: 1, letter: 'группа'},
        program: {id: 1, name: 'Занимательная математика, ступень 1'},
        month: {id: 9, label: 'Сентябрь'},
        year: 2024
    },
    {
        id: 3,
        city: {id: 1, name: 'Красногорск'},
        school: {id: 1, name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'},
        classObj: {id: 1, number: 1, letter: 'A'},
        program: {id: 1, name: 'Занимательная математика, ступень 1'},
        month: {id: 9, label: 'Сентябрь'},        
        subgroup: {id: 1, name: 'Подгруппа 1'},
        year: 2024
    },
    {
        id: 4,
        city: {id: 1, name: 'Красногорск'},
        school: {id: 1, name: 'МБОУ Средняя общеобразовательная школа «Мозаика» г. Красногорск'},
        classObj: {id: 1, number: 1, letter: 'A'},
        program: {id: 1, name: 'Занимательная математика, ступень 1'},
        month: {id: 9, label: 'Сентябрь'},
        year: 2024
    },
]

