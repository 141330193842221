import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../axiosProxy';
// import axios from 'axios';





export const fetchUserProgramsData = createAsyncThunk(
  'UserPrograms/fetchUserProgramsData',
  async () => {
    try {
      const response = await axiosProxy.get('/api/v1/parental/finance/get-additionals')
      if(response.status < 400){        
        return {data: response.data.data}                       
      }
      else{
        console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);


