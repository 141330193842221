import { useDispatch, useSelector } from 'react-redux';
import './index.scss'
import { useState } from 'react';
import Header from '../../Header';
import Clients from './Clients';
import History from './History';
import AdminPopup from './../../Popup/AdminPopup';
import { selectAdminFinanceSearch } from './../../../../redux/admin/finance/search/selectors';
import { setAdminFinanceSearchVal } from '../../../../redux/admin/finance/search/searchSlice';

const Finance = () =>{
    const dispatch = useDispatch();
    const { adminFinanceSearchVal:query } = useSelector(selectAdminFinanceSearch);
    const [popupType, setPopupType] = useState('')
    const [activeList, setActiveList] = useState('')

    return (
        <div className={`admin__finance-page`} onClick={(e)=>{
            if(e.target.closest('.export__container') === null || e.target.classList.contains('export__popup-list-item')){
                setActiveList('')
            }
        }}>
            <Header query={query} setQuery={(val)=>dispatch(setAdminFinanceSearchVal(val))}/>
            <div className='admin-wrapper d-flex justify-between flex-wrap mt-24'>
                <Clients query={query} setPopupType={setPopupType} activeList={activeList} setActiveList={setActiveList}/>
                <History query={query} setPopupType={setPopupType} activeList={activeList} setActiveList={setActiveList}/>                
            </div>
            <AdminPopup type={popupType} props={{popupTypeEdit: setPopupType}}/>
        </div>
    )
}

export default Finance;