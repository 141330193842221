import { NavLink } from 'react-router-dom';
import './index.scss'


const Navbar = () =>{
    return (
        <div className={`admin__sidebar-nav`}>
            <NavLink to={'/'} className={`admin__sidebar-nav-elem d-flex align-center`}>
                <div className={`admin__sidebar-nav-elem-icon aplication`}/>
                <div className='admin__sidebar-nav-elem-label'>Заявки</div>
            </NavLink>
            <NavLink to={'/finance'} className={`admin__sidebar-nav-elem d-flex align-center`}>
                <div className={`admin__sidebar-nav-elem-icon finance`}/>
                <div className='admin__sidebar-nav-elem-label'>Финансы</div>
            </NavLink>
            <NavLink to={'/schools'} className={`admin__sidebar-nav-elem d-flex align-center`}>
                <div className={`admin__sidebar-nav-elem-icon programs`}/>
                <div className='admin__sidebar-nav-elem-label'>Программы</div>
            </NavLink>
            <NavLink to={'/teachers'} className={`admin__sidebar-nav-elem d-flex align-center`}>
                <div className={`admin__sidebar-nav-elem-icon teachers`}/>
                <div className='admin__sidebar-nav-elem-label'>Преподаватели</div>
            </NavLink>
            <NavLink to={'/tabel'} className={`admin__sidebar-nav-elem d-flex align-center`}>
                <div className={`admin__sidebar-nav-elem-icon tabel`}/>
                <div className='admin__sidebar-nav-elem-label'>Табель</div>
            </NavLink>
        </div>
    )
}

export default Navbar;