import React from 'react';
import { InputMask } from '@react-input/mask';

const MaskedInput = ({onChange, mask, className, readOnly, placeholder = '', name = '', defaultValue = '', replacement='', showMask=false, separate=false }) => {
  function handleChange(e) {
    onChange({
      ...e,
      target: {
        ...e.target,
        value: e.target.value
      }
    });
  }

  return (
      <InputMask
        className={className}
        mask={mask}
        replacement={replacement}
        value={defaultValue}
        placeholder={placeholder}
        onChange={(e)=>handleChange(e)}
        // defaultValue={defaultValue}
        readOnly={readOnly}
        type='text'
        name={name}
        showMask={showMask}
        // separate={separate}
      />
  );
};

export default MaskedInput;