// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher {
  min-height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #F5F5F5;
  padding-bottom: 40px; }
  .teacher-wrap {
    width: 100%;
    margin: auto;
    padding: 0 130px; }
`, "",{"version":3,"sources":["webpack://./src/components/Teacher/index.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,yDAA+C;EAC/C,yBAAyB;EACzB,oBAAoB,EAAA;EAEpB;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB,EAAA","sourcesContent":[".teacher{\n    min-height: 100vh;\n    background-image: url(../../img/background.svg);\n    background-color: #F5F5F5;\n    padding-bottom: 40px;\n\n    &-wrap{\n        width: 100%;\n        margin: auto;\n        padding: 0 130px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
