import { useState, useEffect } from 'react';
import Header from '../../Header';
import User from './User';

import './index.scss'
import Childs from './Childs';
import Documents from './Documents';
import Discount from './Discount';
import PaymentHistory from './PaymentHistory';
import AdminPopup from './../../Popup/AdminPopup';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminFinanceUser } from './../../../../redux/admin/finance/user/selectors';
import DeleteBlock from './../../../Teacher/Popup/DeleteBlock/DeleteBlock';
import WarningBlock from './../../../Teacher/Popup/WarningBlock/WarningBlock';
import { useNavigate } from 'react-router-dom';
import { axiosProxyCatchPost } from './../../../../axiosProxy';
import { selectAdminFinanceChilds } from './../../../../redux/admin/finance/childs/selectors';
import { fetchAdminFinanceChildsData } from './../../../../redux/admin/finance/childs/asyncActions';


const UserCard = () =>{

    const dispatch = useDispatch()

    const { adminFinanceUserData:userData } = useSelector(selectAdminFinanceUser);
    const { adminFinanceChildsData } = useSelector(selectAdminFinanceChilds);


    const navigation = useNavigate()


    const [activeList, setActiveList] = useState('')
    const [error, setError] = useState('')
    const [query, setQuery] = useState('')
    const [popupType, setPopupType] = useState('')
    const [editProfileIsActive, setEditProfileIsActive] = useState(false)


    useEffect(()=>{
        dispatch(fetchAdminFinanceChildsData({clientId: userData.id}))
    }, [])

    const deletedUser = (setLoading) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/delete-client',
            data: {id: userData.id},
            resolved: (data)=>{
                setPopupType('')
                navigation('/finance')
                setLoading(false)
            },
            rejected: (error)=>{
                if(error && error.data && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Не удалось удалить пользователя, попробуйте еще раз.')
                setLoading(false)
                setPopupType('Warning')
            }
        })
    }


    return (
        <div className={`admin__finance-user-card`} onClick={(e)=>{
            if(e.target.closest('.export__container') === null || e.target.classList.contains('export__popup-list-item')){
                setActiveList('')
            }
        }}>
            <Header query={query} setQuery={setQuery}/>
            <div className='admin-wrapper d-flex justify-between flex-wrap mt-24'>
                <User setPopupType={setPopupType} item={userData} editProfileIsActive={editProfileIsActive} setEditProfileIsActive={setEditProfileIsActive}/>
                <div className='admin__finance-user-card-content d-flex mt-24'>
                    <Childs setPopupType={setPopupType} childs={adminFinanceChildsData}/>
                    <div className='admin__finance-user-card-content-documents-and-discount'>
                        <Documents setPopupType={setPopupType} checkedClient={userData}/>
                        <Discount isFullVerify={(adminFinanceChildsData.filter(item => item.isFullVerify).length > 0) ?true :false}/>
                    </div>
                </div>
                <PaymentHistory setPopupType={setPopupType} activeList={activeList} setActiveList={setActiveList} query={query} checkedClient={userData}/>            
            </div>
            <AdminPopup type={popupType} props={{popupTypeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление пользователя', subtitle:<>Вы действительно хотите удалить пользователя <span>{userData.name}</span>?</>, closePopup: setPopupType, clickFunk: deletedUser}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle: error, closePopup: setPopupType}}/>
        </div>
    )
}

export default UserCard;