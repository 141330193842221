import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminConectedProgramAplication, fetchAdminConfirmCertificateAplication, fetchAdminConfirmDocumentAplication, fetchAdminUserEditedAplication, fetchAdminUserVerificationAplication } from './asyncActions';
import { getResponseDateFormatFunc } from './../../../../services/clientHome';
import { editChildConfirmAplication } from './../../../../defaultData/admin/aplications/aplicatonsConfirm';

const initialState = {
  adminUserVerificationAplication:{
    id: 0,
    client: {
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: ''
    },
    students: [
    ]
  },

  // adminUserVerificationAplication:{
  //   id: 1,
  //   client: {
  //       id: 1,
  //       name: 'Name Name Name',
  //       email: 'ggggg@ggg.gg',
  //       password: '12345yyy',
  //       phone: '+7(928)685-12-34'
  //   },
  //   students: [
  //     {
  //       id: 1,
  //       name: 'Name Name Name',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       month: {id: 0, label: '', year: ''},
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}]

  //     },
  //     {
  //       id: 2,
  //       name: 'Name Name Name2',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       month: {id: 0, label: '', year: ''},
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}]

  //     },
  //   ]
  // },

  adminUserEditedAplication:{
    applicationId: 7,
    id: 1,
    name: 'Сергеев Максим Александрович',
    email: 'pochta@mail.ru',
    password: 'pass1234',
    phone: '+7(928) 765-47-27',

    update:{
      id: 1,
      name: 'Сергеев Максим Александрович1',
      email: 'pochta@mail.ru1',
      password: 'pass12341',
      phone: '+7(928) 666-47-27'
    }
  },

  adminConectedProgramAplication:{
    applicationId: 0,
    id: 0,
    name: '',
    city: '',
    school: '',
    classNumber: '',
    classLetter: '',
    program: {
        id: 0,
        name: '',
        color: ''
    }
  },

  adminConfirmDocumentAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        name: '',
        date: '',
        status: null,
        link: '',
        extension: ''
    },
  },
  
  adminConfirmCertificateAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        startDate: '',
        endDate: '',
        date: '',
        status: null,
        link: ''
    },
  },

  adminEditChildConfirmAplication:{
    ...editChildConfirmAplication
  },

  


};

const adminAplicationConfirm = createSlice({
  name: 'AdminAplicationConfirm',
  initialState,
  reducers: {
    setAdminUserVerificationAplication(state, action) {
      state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload};
    },

    setAdminUserEditedAplication(state, action) {      
      state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload};
    },

    setAdminConectedProgramAplication(state, action) {
      state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload};
    },

    setAdminConfirmDocumentAplication(state, action) {
      state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload};
    },

    setAdminConfirmCertificateAplication(state, action) {
      state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload};
    },

    setAdminEditChildConfirmAplication(state, action) {
      state.adminEditChildConfirmAplication = {...state.adminEditChildConfirmAplication, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUserVerificationAplication.pending, (state, action) => {
      state.adminUserVerificationAplication = {
        ...state.adminUserVerificationAplication,
        client: {
            id: 0,
            name: '',
            email: '',
            password: '',
            phone: ''
        },
        students: [
        ]
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userVerificationData){
        let students = action.payload.userVerificationData.students;
        if(students.length){
          students = students.map(item=>{
            return {
              ...item,
              birthDay: new Date(item.birthDay).toLocaleDateString(),
              classNumber: item.classObj.number,
              classLetter: item.classObj.letter,
            }
          })
        }
        state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload.userVerificationData, students: students};
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminUserEditedAplication.pending, (state, action) => {
      state.adminUserEditedAplication = {
        ...state.adminUserEditedAplication,
        id: 0,
        mail: '',
        phone: '',
        previousMail: '',
        previousPhone: '',
      }
    });
    builder.addCase(fetchAdminUserEditedAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userData) state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload.userData};
    });
    builder.addCase(fetchAdminUserEditedAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConectedProgramAplication.pending, (state, action) => {
      state.adminConectedProgramAplication = {
        ...state.adminConectedProgramAplication,
        id: 0,
        name: '',
        city: '',
        school: '',
        classNumber: '',
        classLetter: '',
        program: {
            id: 0,
            name: '',
            color: ''
        }
      }

    });
    builder.addCase(fetchAdminConectedProgramAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.programData) state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload.programData};
    });
    builder.addCase(fetchAdminConectedProgramAplication.rejected, (state, action) => {
    });

    
    builder.addCase(fetchAdminConfirmDocumentAplication.pending, (state, action) => {
      state.adminConfirmDocumentAplication = {
        ...state.adminConfirmDocumentAplication,
        clientName: '',
        type: 0,
        document: {
            id: 0,
            name: '',
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.documentData) state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload.documentData};
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConfirmCertificateAplication.pending, (state, action) => {
      state.adminConfirmCertificateAplication = {
        ...state.adminConfirmCertificateAplication,
        clientName: '',
        type: {
            id: 0,
            name: ''
        },
        document: {
            id: 0,
            validity: {
                startDate: '',
                endDate: '',
            },
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.fulfilled, (state, action) => {
      console.log(action.payload);
      if(action.payload && action.payload.certificateData) state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload.certificateData};
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminAplicationConfirm;


export default reducer;

export const { 
    setAdminUserVerificationAplication,
    setAdminUserEditedAplication,
    setAdminConfirmDocumentAplication,
    setAdminConfirmCertificateAplication,
    setAdminConectedProgramAplication,
    setAdminEditChildConfirmAplication
} = actions;

