import ClassEdit from './ClassEdit';
import CreateCity from './CreateCity';
import ProgramEdit from './ProgramEdit';
import SchoolEdit from './SchoolEdit';
import SchoolFilters from './SchoolFilters/index';

const ProgramsPopup = ({unDisplay, popupBack, type, props}) =>{
    return(        
        (type === "AddCity")? <CreateCity closePopup={unDisplay} popupBack={popupBack} type={type}/>
        :(type === "AddClass" || type === "EditClass")? <ClassEdit closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "AddProgram" || type === "EditProgram")? <ProgramEdit closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "AddSchool" || type === "EditSchool")? <SchoolEdit closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "SchoolFilters")? <SchoolFilters closePopup={unDisplay} popupBack={popupBack} clickFunk={props.clickFunk}/>
        : null
    )
}

export default ProgramsPopup;