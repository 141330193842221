import axiosProxy from './../../axiosProxy';
import './index.scss'


const ExportListElem = ({elem}) =>{
    let {name, clickFunc, loading} = elem;
    return(
        <div className={`export__popup-list-item ${loading? 'deactive-button': ''}`} onClick={clickFunc}>
            {name}
        </div>
    )
}

const ExportButton = ({name, activeList, setActiveList, elems=[]}) =>{
    return(
        <div className='export__container'>
            <button className={`export__button d-flex align-center ${elems.length? '': 'none-click'}`} onClick={()=>{
                if(activeList !== name){
                    setActiveList(name)
                }
                else{
                    setActiveList('')
                }
            }}>
                <div className='export__button-icon'/>
            </button>
            <div className={`export__popup-list ${(activeList === name)? 'active': ''}`}>
                <div className='export__popup-list-scroll'>
                    {
                        elems.map((item, key)=><ExportListElem elem={item} key={'export__popup-list-elem-' + key}/>)
                    }
                </div>
            </div>
        </div>
    )
}

export default ExportButton;