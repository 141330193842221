import React from 'react';
import { createPortal } from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';

import EditProfile from './EditProfile';

import { selectUserPopup } from './../../../redux/users/popup/selectors';
import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';

import '../../../main.scss'
import './index.scss';
import EditChild from './EditChild/index';
import AddAegrotat from './AddAegrotat/index';
import Payment from './Payment/index';
import ProfileCurrentChages from './ProfileCurrentChages/index';



const PopupContent = ({type, props}) => {
    const dispatch = useDispatch()
    const {popupBack} = useSelector(selectUserPopup)

    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            if(type === ''){
                if(props.popupTypeEdit) props.popupTypeEdit('')
                if(props.setConectedProgramActive) props.setConectedProgramActive('')
            }
            dispatch(setUserPopupBack(''))
        }         
    }

    const unDisplay = () =>{
        if(type === '') props.popupTypeEdit('')
        dispatch(setUserPopupBack(''))
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                {
                    (type === "EditProfile")? <EditProfile closePopup={unDisplay} popupBack={popupBack}/>
                    :(type === "EditChild")? <EditChild closePopup={unDisplay}/>
                    :(type === "AddAegrotat")? <AddAegrotat closePopup={unDisplay} popupBack={popupBack} checkedChild={props.checkedChild}/>
                    :(type === "Payment")? <Payment closePopup={unDisplay} popupBack={popupBack}/>
                    :(type === "ProfileCurrentChages")? <ProfileCurrentChages closePopup={unDisplay} popupBack={popupBack}/>
                    : null
                }
            </div>
        </div>
	)
}

const UserPopup = ({type, props = null}) =>{
    return createPortal(<PopupContent type={type} props={props} />, document.body)
}
export default UserPopup;