// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__programs-program {
  padding-bottom: 24px; }
  .admin__programs-program-title {
    padding-left: 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px; }
  .admin__programs-program-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 0.35fr));
    grid-template-rows: max-content;
    align-content: start;
    gap: 16px;
    justify-items: auto;
    grid-auto-flow: dense; }
    .admin__programs-program-list-elem.default {
      cursor: default;
      pointer-events: none; }
      .admin__programs-program-list-elem.default:hover {
        border-color: #e6e6e6; }
    .admin__programs-program-list-elem-default-text {
      max-width: 100%;
      height: 20px;
      border-radius: 4px;
      background-color: #d4d4d4; }
      .admin__programs-program-list-elem-default-text.grey {
        background-color: #e4e4e4; }
  .admin__programs-program-students-container {
    border-radius: 24px; }
    .admin__programs-program-students-container.subgroup-active {
      background-color: #fff;
      padding: 24px;
      border: 1px solid #E4E4E4; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Programs/Programs/index.scss"],"names":[],"mappings":"AAAA;EACI,oBAAoB,EAAA;EAEpB;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB,EAAA;EAGvB;IACI,aAAa;IACb,8DAA8D;IAC9D,+BAA+B;IAC/B,oBAAoB;IACpB,SAAS;IACT,mBAAmB;IACnB,qBAAqB,EAAA;IAEpB;MAEO,eAAe;MACf,oBAAoB,EAAA;MAH3B;QAKW,qBAAqB,EAAA;IAI7B;MACI,eAAe;MACf,YAAY;MACZ,kBAAkB;MAClB,yBAAyB,EAAA;MAJ5B;QAOO,yBAAyB,EAAA;EAOrC;IAEI,mBAAmB,EAAA;IAFtB;MAIO,sBAAsB;MACtB,aAAa;MACb,yBAAyB,EAAA","sourcesContent":[".admin__programs-program{\n    padding-bottom: 24px;\n\n    &-title{\n        padding-left: 16px;\n        font-size: 24px;\n        font-weight: 600;\n        line-height: 28.8px;\n    }\n\n    &-list{\n        display: grid;\n        grid-template-columns: repeat(auto-fit, minmax(380px, 0.35fr));\n        grid-template-rows: max-content;\n        align-content: start;\n        gap: 16px;\n        justify-items: auto;\n        grid-auto-flow: dense;\n\n        &-elem{\n            &.default{\n                cursor: default;\n                pointer-events: none;\n                &:hover{\n                    border-color: #e6e6e6;\n                }\n            }\n\n            &-default-text{\n                max-width: 100%;\n                height: 20px;\n                border-radius: 4px;\n                background-color: #d4d4d4;\n\n                &.grey{\n                    background-color: #e4e4e4;\n                }\n            }\n        }\n    }\n\n    &-students{\n        &-container{\n            // transition: .3s padding, .3s background-color;\n            border-radius: 24px;\n            &.subgroup-active{\n                background-color: #fff;\n                padding: 24px;\n                border: 1px solid #E4E4E4;\n            }\n    \n            \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
