import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminProgramsSchoolsData = createAsyncThunk(
  'AdminProgramsSchools/fetchAdminProgramsSchoolsData',
  async (dataObj={count:60, page:0, isScrolling: false, query: '', filters:null}) => {
    let postData = {count: dataObj.count, page: dataObj.page, orderCity: true};
    if(dataObj.query) postData.query = dataObj.query;
    let filtersData = {}
    if(dataObj.filters && dataObj.filters.city && dataObj.filters.city.id) filtersData.cityId = dataObj.filters.city.id

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/programs/get-school?`+ new URLSearchParams({...postData, ...filtersData}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


