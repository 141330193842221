
const StudentListElem = ({item, clickFunc, classes=''}) =>{
    const {name} = item;
    return (
        <div className={`admin__finance-table-line admin__programs-program-students-table-line d-flex justify-between cursor-pointer ${classes}`} onClick={clickFunc}>
            <div className='admin__programs-program-students-table-line-item name'>{name}</div>
        </div>
    )
}

export default StudentListElem;