import { createSlice } from '@reduxjs/toolkit';
import { fetchUserProgramsData } from './asyncActions';
import { programs } from './../../../defaultData/user/programs/programs';

const initialState = {
    programsData: [
      // ...programs
    ],

    programChecked: {
      id: 0, 
      name: '',
      price: 0, 
      conectedChildren: [],
      awaitsDisabledChildren: [],
      awaitsConectedChildren: [],
      color: ''
    }
};

const userPrograms = createSlice({
  name: 'UserPrograms',
  initialState,
  reducers: {
    setUserPrograms(state, action) {
      state.programsData = action.payload;      
    },
    setUserProgramsEdit(state, action) {
      state.programsData = action.payload;      
    },
    setUserProgramsConectedChild(state, action) {
      state.programsData = state.programsData.map(item =>{
          if(item.id === action.payload.programId)
            return {...item, awaitsConectedChildren: [...item.awaitsConectedChildren, action.payload.childId]};
          else
            return item;
      });
        
      state.programChecked = {...state.programChecked, awaitsConectedChildren: [...state.programChecked.awaitsConectedChildren, action.payload.childId]};
    },
    setUserProgramsDisableChild(state, action) {
      state.programsData = state.programsData.map(item =>{
          if(item.id === action.payload.programId)
            return {...item, awaitsDisabledChildren: item.awaitsDisabledChildren.filter(id => id !== action.payload.childId)};
          else
            return item;
      });
      state.programChecked = {...state.programChecked, awaitsDisabledChildren: state.programChecked.awaitsDisabledChildren.filter(id => id !== action.payload.childId)};      
    },
    setProgramChecked(state, action) {
      state.programChecked = {...state.programChecked, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProgramsData.pending, (state, action) => {
    });
    builder.addCase(fetchUserProgramsData.fulfilled, (state, action) => {      
      if(action.payload && action.payload.data) state.programsData = action.payload.data;
    });
    builder.addCase(fetchUserProgramsData.rejected, (state, action) => {
    });
  },
});

const { actions, reducer } = userPrograms;


export default reducer;

export const { 
  setUserPrograms,
  setUserProgramsEdit,
  setUserProgramsConectedChild,
  setUserProgramsDisableChild,
  setProgramChecked
} = actions;

