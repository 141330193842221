import { useState } from 'react';
import { createPortal } from 'react-dom';

import { useEffect } from 'react';


const ConfirmCancelContainer = ({popupBack, title, subtitle, error, closePopup, clickFunk}) => {
    const [loading, setLoading] = useState(false)
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target && !loading){
            closePopup('')
        }
    }

	return (
        <div className={`popup__back ${popupBack}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=>ClosePopup(event)}>
                <div className={`popup__container admin-popup__container delete`}>
                    <div className='popup__title mb-24'>
                        {title}
                    </div>
                    <div className='popup__subtitle mb-24'>
                        {subtitle}
                    </div>
                    <div className='d-flex mt-24'>
                        <button className='popup__button wd-50-mr-16 cancel' onClick={()=>{
                            if(!loading) closePopup('')
                        }}>Назад</button>
                        <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>{
                            setLoading(true)
                            clickFunk(setLoading)
                        }}>Подтвердить</button>
                    </div>
                </div>
            </div>
        </div>
	)
}

const ConfirmCancel = ({popupBack, props = null}) =>{
    return createPortal(<ConfirmCancelContainer popupBack={popupBack} title={props.title} subtitle={props.subtitle} clickFunk={props.clickFunk} closePopup={props.closePopup}/>, document.body)
}
export default ConfirmCancel;