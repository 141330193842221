import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminFinanceClientsData } from './asyncActions';

const initialState = {
  adminFinanceClientsData:[
  ],

  adminFinanceClientsPageLast: false,
  adminFinanceClientsScrollReset: false,
  adminFinanceClientsLoading: false,


  adminFinanceClientsFilters:{
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    maternalСapital: false,
    month: {id: 0, label: ''},
    year: '',
    post: {}
  }
};

const adminFinanceClients = createSlice({
  name: 'AdminFinanceClients',
  initialState,
  reducers: {
    setAdminFinanceClientsData(state, action) {
      state.adminFinanceClientsData = action.payload;      
    },
    setAdminFinanceClientsDataEdit(state, action) {
      state.adminFinanceClientsData = state.adminFinanceClientsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminFinanceClientsScrollReset(state, action) {
      state.adminFinanceClientsScrollReset = action.payload;      
    },


    setAdminFinanceClientsFilters(state, action) {
      state.adminFinanceClientsFilters = {...state.adminFinanceClientsFilters, ...action.payload};      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminFinanceClientsData.pending, (state, action) => {
      state.adminFinanceClientsLoading = true;
    });
    builder.addCase(fetchAdminFinanceClientsData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
          if(action.payload.isScrolling){
            state.adminFinanceClientsData = [...state.adminFinanceClientsData, ...action.payload.data];
          }
          else
            state.adminFinanceClientsData = action.payload.data;
  
          if(action.payload.page === 0) state.adminFinanceClientsScrollReset = true;
  
          if(action.payload.data.length < 30) state.adminFinanceClientsPageLast = true;
          else state.adminFinanceClientsPageLast = false;
  
          state.adminFinanceClientsLoading = false;
        }
    });
    builder.addCase(fetchAdminFinanceClientsData.rejected, (state, action) => {
      state.adminFinanceClientsLoading = false;
    });
  },

});

const { actions, reducer } = adminFinanceClients;


export default reducer;

export const { 
  setAdminFinanceClientsData,
  setAdminFinanceClientsPageLast,
  setAdminFinanceClientsScrollReset,


  setAdminFinanceClientsFilters
} = actions;

