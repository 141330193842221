export let profile = {
    "id": "01000000-ac12-0242-a233-08dcd2f11bb8",
    "name": "Абакарова Шанисат Газимагомедовна",
    "email": "abakarova.shanisat@mail.ru",
    "phone": "+7(988) 290-04-59",
    "password": "777777777",
    "status": -1,
    // comment: 'djkshfs fjkdsh fjhjksdhjkh fsdjkh hfjksd',
    "financeAccess": false,

    "update": {
        "id": "01000000-ac12-0242-a233-08dcd2f11bb8",
        "name": "Абакарова Шанисат Газимагомедовна",
        "email": "abakarova.shanisat@mail.ru",
        "phone": "+7(988) 666-66-55",
        "password": "777777777",
    }
}