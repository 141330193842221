import adminAplications from './aplications/aplications/aplicationsSlice';
import adminAplicationConfirm from './aplications/aplicationConfirm/aplicationConfirmSlice';

import adminFinanceSearch from './finance/search/searchSlice'; 
import adminFinanceClients from './finance/clients/clientsSlice'; 
import adminFinancePaymentHistory from './finance/paymentsHistory/paymentsHistorySlice';
import adminFinanceChilds from './finance/childs/childsSlice';
import adminFinanceDocuments from './finance/documents/documentsSlice';
import adminFinanceUser from './finance/user/userSlice';
import adminFinanceUserDiscount from './finance/discount/discountSlice';

import adminProgramsSchools from './programs/schools/schoolsSlice';
import adminProgramsClasses from './programs/classes/classesSlice';
import adminProgramsPrograms from './programs/programs/programsSlice';
import adminProgramStudents from './programs/students/studentsSlice';
import adminProgramTeachers from './programs/teachers/teachersSlice';

import adminTeachers from './teachers/teachers/teachersSlice';

import adminTabel from './tabel/tabelSlice';



export default {
    adminAplications,
    adminAplicationConfirm,
    
    adminFinanceSearch, 
    adminFinanceClients, 
    adminFinancePaymentHistory, 
    adminFinanceChilds, 
    adminFinanceDocuments, 
    adminFinanceUser,
    adminFinanceUserDiscount,

    adminProgramsSchools,
    adminProgramsClasses,
    adminProgramsPrograms,
    adminProgramStudents,
    adminProgramTeachers,

    adminTeachers,

    adminTabel,
}