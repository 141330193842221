import { createSlice } from '@reduxjs/toolkit';
import { fetchFinancePaymentHistoryData, fetchFinanceUserPaymentHistoryData } from './asyncActions';

const dateC = '2021 03 28T00:28:06.496Z';

const initialState = {
    adminFinancePaymentHistoryData:[
    ],

    adminFinancePaymentHistoryPageLast: false,
    adminFinancePaymentHistoryScrollReset: false,
    adminFinancePaymentHistoryLoading: false,

    adminFinancePaymentHistoryFilters:{
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        parrent: {id: 0, name: ''},
        child: {id: 0, name: ''},
        paymentType: {id: 0, name: '', color: ''},
        dates: [],
        postDates: [],
        classObj: {id: 0, number: '', letter: ''},
        post: {},
        dates: {}
    },


    adminFinanceUserPaymentHistoryData:[
    ],

    adminFinanceUserPaymentHistoryPageLast: false,
    adminFinanceUserPaymentHistoryScrollReset: false,
    adminFinanceUserPaymentHistoryLoading: false,

    adminFinanceUserPaymentHistoryFilters:{
        child: {id: 0, name: ''},
        paymentType: {id: 0, name: '', color: ''},
        date: '',
        post: {}
    }
};

const adminFinancePaymentHistory = createSlice({
  name: 'AdminFinancePaymentHistory',
  initialState,
  reducers: {
    setAdminFinancePaymentHistoryData(state, action) {
      state.adminFinancePaymentHistoryData = action.payload;
    },
    setAdminFinancePaymentHistoryDataEdit(state, action) {
      state.adminFinancePaymentHistoryData = state.adminFinancePaymentHistoryData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },

    setAdminFinancePaymentHistoryScrollReset(state, action) {
      state.adminFinancePaymentHistoryScrollReset = action.payload;
    },

    setAdminFinancePaymentHistoryFilters(state, action) {
      state.adminFinancePaymentHistoryFilters = {...state.adminFinancePaymentHistoryFilters, ...action.payload};
    },



    setAdminFinanceUserPaymentHistoryData(state, action) {
      state.adminFinanceUserPaymentHistoryData = action.payload;      
    },
    setAdminFinanceUserPaymentHistoryDataEdit(state, action) {
      state.adminFinanceUserPaymentHistoryData = state.adminFinanceUserPaymentHistoryData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },

    setAdminFinanceUserPaymentHistoryScrollReset(state, action) {
      state.adminFinanceUserPaymentHistoryScrollReset = action.payload;      
    },

    setAdminFinanceUserPaymentHistoryFilters(state, action) {
      state.adminFinanceUserPaymentHistoryFilters = {...state.adminFinanceUserPaymentHistoryFilters, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFinancePaymentHistoryData.pending, (state, action) => {
        state.adminFinancePaymentHistoryLoading = true;
    });
    builder.addCase(fetchFinancePaymentHistoryData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminFinancePaymentHistoryData = [...state.adminFinancePaymentHistoryData, ...action.payload.data];
            }
            else
                state.adminFinancePaymentHistoryData = action.payload.data;
    
            if(action.payload.page === 0) state.adminFinancePaymentHistoryScrollReset = true;
    
            if(action.payload.data.length < 30) state.adminFinancePaymentHistoryPageLast = true;
            else state.adminFinancePaymentHistoryPageLast = false;
    
            state.adminFinancePaymentHistoryLoading = false;
        }
    });
    builder.addCase(fetchFinancePaymentHistoryData.rejected, (state, action) => {
        state.adminFinancePaymentHistoryLoading = false;
    });


    builder.addCase(fetchFinanceUserPaymentHistoryData.pending, (state, action) => {
        state.adminFinanceUserPaymentHistoryLoading = true;
    });
    builder.addCase(fetchFinanceUserPaymentHistoryData.fulfilled, (state, action) => {
        if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
            if(action.payload.isScrolling){
                state.adminFinanceUserPaymentHistoryData = [...state.adminFinanceUserPaymentHistoryData, ...action.payload.data];
            }
            else
                state.adminFinanceUserPaymentHistoryData = action.payload.data;
    
            if(action.payload.page === 0) state.adminFinanceUserPaymentHistoryScrollReset = true;
    
            if(action.payload.data.length < 30) state.adminFinanceUserPaymentHistoryPageLast = true;
            else state.adminFinanceUserPaymentHistoryPageLast = false;

            state.adminFinanceUserPaymentHistoryLoading = false;
        }
    });
    builder.addCase(fetchFinanceUserPaymentHistoryData.rejected, (state, action) => {
        state.adminFinanceUserPaymentHistoryLoading = false;
    });
  },

});

const { actions, reducer } = adminFinancePaymentHistory;


export default reducer;

export const { 
  setAdminFinancePaymentHistoryData,
  setAdminFinancePaymentHistoryDataEdit,
  setAdminFinancePaymentHistoryScrollReset,
  setAdminFinancePaymentHistoryFilters,


  setAdminFinanceUserPaymentHistoryData,
  setAdminFinanceUserPaymentHistoryDataEdit,
  setAdminFinanceUserPaymentHistoryScrollReset,
  setAdminFinanceUserPaymentHistoryFilters,
} = actions;

