// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__teachers-applications-table-line-item {
  margin-right: 20px;
  font-size: 14px; }
  .admin__teachers-applications-table-line-item.city {
    width: calc(10% - 30px); }
  .admin__teachers-applications-table-line-item.school {
    width: calc(24% - 30px); }
  .admin__teachers-applications-table-line-item.class-name {
    width: calc(12% - 30px); }
  .admin__teachers-applications-table-line-item.program {
    width: calc(22% - 30px); }
  .admin__teachers-applications-table-line-item.subgroup {
    width: calc(12% - 30px); }
  .admin__teachers-applications-table-line-item.month {
    width: calc(16% - 30px); }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Application/Applications/TeachersTableElem/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,eAAe,EAAA;EAFnB;IAIQ,uBAAuB,EAAA;EAJ/B;IAOQ,uBAAuB,EAAA;EAP/B;IAUQ,uBAAuB,EAAA;EAV/B;IAaQ,uBAAuB,EAAA;EAb/B;IAgBQ,uBAAuB,EAAA;EAhB/B;IAmBQ,uBAAuB,EAAA","sourcesContent":[".admin__teachers-applications-table-line-item{\n    margin-right: 20px;\n    font-size: 14px;\n    &.city{\n        width: calc(10% - 30px);\n    }\n    &.school{\n        width: calc(24% - 30px);\n    }\n    &.class-name{\n        width: calc(12% - 30px);\n    }\n    &.program{\n        width: calc(22% - 30px);\n    }\n    &.subgroup{\n        width: calc(12% - 30px);\n    }\n    &.month{\n        width: calc(16% - 30px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
