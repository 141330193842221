// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher__tabel-filters {
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  background-color: #FFFFFF;
  border-radius: 16px;
  border: 1px solid #E6E6E6;
  margin-bottom: 48px; }
  .teacher__tabel-filters-item-container {
    width: calc(100% - 224px);
    margin-right: 24px; }
  .teacher__tabel-filters-subgroup {
    width: calc(25% - 10px);
    margin-left: 15px; }
  .teacher__tabel-filters-program {
    width: calc(75% - 0px); }
  .teacher__tabel-filters-button {
    width: 200px;
    padding: 12px;
    height: 54px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    cursor: pointer; }
    .teacher__tabel-filters-button.apply {
      background-color: #B158FF;
      color: #fff; }
  .teacher__tabel-filters-error {
    margin: auto;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #F53D3D;
    text-align: center;
    min-height: 8px;
    margin: 8px 0; }
`, "",{"version":3,"sources":["webpack://./src/components/Teacher/Tabel/index.scss"],"names":[],"mappings":"AAEI;EACI,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB,EAAA;EAEnB;IACI,yBAAyB;IACzB,kBAAkB,EAAA;EAGtB;IACI,uBAAuB;IACvB,iBAAiB,EAAA;EAGrB;IACI,sBAAsB,EAAA;EAG1B;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe,EAAA;IAXlB;MAcO,yBAAyB;MACzB,WAAW,EAAA;EAInB;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,aAAa,EAAA","sourcesContent":[".teacher__tabel{\n\n    &-filters{\n        width: 100%;\n        padding: 24px;\n        padding-bottom: 0;\n        background-color: #FFFFFF;\n        border-radius: 16px;\n        border: 1px solid #E6E6E6;\n        margin-bottom: 48px;\n\n        &-item-container{\n            width: calc(100% - 224px);\n            margin-right: 24px;\n        }\n\n        &-subgroup{\n            width: calc(25% - 10px);\n            margin-left: 15px;\n        }\n\n        &-program{\n            width: calc(75% - 0px);\n        }\n\n        &-button{\n            width: 200px;\n            padding: 12px;\n            height: 54px;\n            border-radius: 8px;\n            border: 1px solid #E6E6E6;\n            color: #1a1a1a;\n            font-size: 18px;\n            font-weight: 600;\n            line-height: 27px;\n            text-align: center;\n            cursor: pointer;\n\n            &.apply{\n                background-color: #B158FF;\n                color: #fff;\n            }\n        }\n        \n        &-error{\n            margin: auto;\n            width: 100%;\n            font-size: 14px;\n            font-weight: 500;\n            color: #F53D3D;\n            text-align: center;\n            min-height: 8px;\n            margin: 8px 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
