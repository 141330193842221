import { NavLink } from 'react-router-dom';
import './index.scss'
import { useEffect, useState } from 'react';
import MaskedInput from '../../../../MaskedInput/MaskedInput';
import { useDispatch } from 'react-redux';
import { setAdminFinanceUserData } from '../../../../../redux/admin/finance/user/userSlice';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import { correctorName } from './../../../../../services/clientHome';

const checkedDifferences = (editData, checkedData) =>{
    let result = {}
    if(editData.name !== checkedData.name) result.name = editData.name;
    if(editData.phone !== checkedData.phone) result.phone = editData.phone;
    if(editData.email !== checkedData.email) result.email = editData.email;
    if(editData.password !== checkedData.password) result.password = editData.password;
    return result
    
}

const User = ({item, editProfileIsActive, setEditProfileIsActive, setPopupType}) =>{
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState(item)
    const [edited, setEdited] = useState(item)
    const [error, setError] = useState('')
    const [emptyField, setEmptyField] = useState(false)

    useEffect(()=>{        
        if(Object.keys(checkedDifferences(userData, item)).length){
            setEdited(true)
        }
        else setEdited(false)
    }, [userData])

    useEffect(()=>{
        setUserData(item)
    }, [editProfileIsActive])

    const EditUserDataFunc = ()=>{
        setEmptyField(false)
        setError('')

        let {name, phone, email, password} = userData;
                
        if(correctorName(name) && email && phone.length === 17 && phone.indexOf('_') === -1 && password && password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && password.length >= 6 && (/[a-zA-Z]/.test(password))){
            setLoading(true)
            axiosProxyCatchPost({
                url: '/api/v1/administrative/finance/update-client-profile',
                data: {...userData, name: correctorName(name)},
                resolved: (data)=>{
                    dispatch(setAdminFinanceUserData(userData))
                    setLoading(false)
                    setEditProfileIsActive(false)
                },
                rejected: (error)=>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')
                    setLoading(false)
                }
            }) 
        }
        else if(password.length < 6 || !(/[a-zA-Z]/.test(password))){
            setEmptyField(true)
            setError('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
        }
        else if(!password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)){
            setEmptyField(true)
            setError('Невалидный пароль*')
        }
        else{
            setEmptyField(true)
            setError('Необходимо заполнить все поля*')
        }        
    }



    return(
        <div className={`admin__finance-user-card-user admin__finance-container`}>
            <div className="admin__finance-user-card-user-top mb-24">
                {
                    editProfileIsActive? (
                        <div className={`admin__finance-user-card-user-top-edited d-flex align-center justify-between`}>
                            <div className='admin__finance-user-card-user-input-name-container d-flex align-center'>
                                <NavLink to={'/finance'} className='admin__finance-user-card-user-name-back'/>
                                <input type="text" value={userData.name} className={`admin__finance-user-card-user-input back ${(emptyField && !userData.name)? 'emptyField': ''}`} onChange={(e)=>{
                                    if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setUserData({...userData, name: e.target.value})
                                }}/>
                            </div>
                            
                            <div className='popup__error admin__finance-user-card-user-top-edited-error'>{error}</div>

                            <div className='admin__finance-user-card-user-top-edited-button-container d-flex align-center'>
                                <div className={`admin__finance-user-card-user-top-edited-button cancel mr-16 ${loading? 'deactive-button': ''}`} onClick={()=>setEditProfileIsActive(false)}>Отменить</div>
                                <div className={`admin__finance-user-card-user-top-edited-button save ${edited && !loading? '': 'deactive-button'}`} onClick={EditUserDataFunc}>Сохранить</div>
                            </div>
                        </div>
                    ):(
                        <div className={`admin__finance-user-card-user-top-default d-flex align-center justify-between ${editProfileIsActive? 'deactive': ''}`}>
                            <div className="admin__finance-user-card-user-name d-flex align-center">
                                <NavLink to={'/finance'} className='admin__finance-user-card-user-name-back'/>
                                <div className='admin__finance-user-card-user-name-label'>{userData.name}</div>
                            </div>

                           <div className='d-flex justify-between align-center'>
                                <button className='admin__finance-user-card-user-top-button delete mr-16' onClick={()=>{
                                    setPopupType('Delete')
                                }}>
                                    Удалить профиль
                                </button>
                                <button className='admin__finance-user-card-user-top-button' onClick={()=>{
                                    setEditProfileIsActive(true)
                                }}>
                                    Редактировать профиль
                                </button>
                           </div>
                        </div>
                    )
                }
            </div>

            <div className='admin__finance-user-card-user-inputs-container d-flex justify-between align-center flex-wrap'>
                <div className='admin__finance-user-card-user-input-additionaly-container mail'>
                    <input type="mail" value={userData.email} readOnly={true} className={`admin__finance-user-card-user-input-additionaly ${(emptyField && !userData.email)? 'emptyField': ''} `} onChange={(e)=>{
                        if((e.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && e.target.value.indexOf('..') === -1) || !e.target.value) setUserData({...userData, email: e.target.value})
                    }}/>
                </div>
                
                <div className='admin__finance-user-card-user-input-additionaly-container phone'>
                    <MaskedInput
                        className={`admin__finance-user-card-user-input-additionaly ${(emptyField && !(userData.phone.length === 15 && userData.phone.indexOf('_') === -1))? 'emptyField': ''}`}
                        mask="+7(___) ___-__-__"
                        replacement={{_ : /[0-9]/}}
                        showMask={true}
                        separate={true}
                        defaultValue={userData.phone}
                        name = 'phone'
                        readOnly={!editProfileIsActive}
                        placeholder=''
                        onChange={(event)=>{
                            setUserData({...userData, phone: event.target.value})
                        }}
                    />
                </div>

                <div className='admin__finance-user-card-user-input-additionaly-container password'>
                    <input type={editProfileIsActive? 'text': 'password'} value={userData.password} readOnly={!editProfileIsActive} className={`admin__finance-user-card-user-input-additionaly ${(emptyField && !userData.password)? 'emptyField': ''}`}  onChange={(e)=>{
                        setUserData({...userData, password: e.target.value})
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default User;