import './index.scss'
import { NavLink } from 'react-router-dom';

import Logo from '../../../img/logo.svg'
import HeaderProfileBlock from '../../HeaderProfileBlock';
import { selectUserProfile } from '../../../redux/users/profile/selectors';
import { selectAuthorizationData } from '../../../redux/authorization/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserNavigations } from './../../../redux/users/navigations/selectors';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';


const Header = () =>{
    const dispatch = useDispatch()
    const { user } = useSelector(selectAuthorizationData);
    const { profileData } = useSelector(selectUserProfile);
    const { userNavBarActive } = useSelector(selectUserNavigations);

    return (
        <header className={`teacher-page__header d-flex justify-center align-center`}>
            <div className="teacher-wrap d-flex justify-between align-center">
                <img src={Logo} className='teacher-page__header-logo'/>
                <div className={`teacher-page__header-navbar d-flex justify-between align-center`}>
            
                    <div className={`teacher-page__header-navbar-item-active-elem position-${userNavBarActive}`}/>
                    
                    <NavLink to={'/'} className={`teacher-page__header-navbar-item ${(false)? 'active': ''} ${('' === '/tabel')? 'deactive-button': ''}`} onClick={()=>dispatch(setUserNavBarActive(0))}>
                        Табель
                    </NavLink>
                    <NavLink to={'/'} className={`teacher-page__header-navbar-item d-flex align-center justify-center ${(false)? 'active': ''} deactive-button`} onClick={()=>dispatch(setUserNavBarActive(1))}>
                        <div className={`teacher-page__header-navbar-item-point ${profileData.notifications}`}/>
                        <div className='teacher-page__header-navbar-item-label'>
                            Уведомления
                        </div>
                    </NavLink>
                </div>
                <HeaderProfileBlock user={user}/>
                {/* <HeaderProfileBlock user={profileData}/> */}
            </div>
        </header>
    )
}

export default Header;