import { useEffect } from 'react';

const ConfidentialPolicy = () =>{
    useEffect(() => {
        document.title = 'Политика обработки персональных данных';
    }, []);


    return(
        <div className="document-container">
            <p> Утверждено</p>

            <p>Приказом <br />
            Генерального директора <br />
            ООО "КФ 21"<br /></p>
            {/* ____________ от «___» _____2024 г.</p> */}

            <br />
            <p>Политика обработки персональных данных</p>

            <p>1. Общие положения<br />
            1.1 Настоящая Политика определяет объем и содержание персональных данных (далее – ПДн) пользователей сайта kf21.ru, определяет порядок обработки ПДн, а также права и обязанности субъектов персональных данных и лиц, приминающих участие в процессах обработки ПДн.<br />
            1.2 Требования настоящей Политики подлежат применению при организации обработки ПДн пользователей сайта kf21.ru, и субъектов персональных данных, законными представителями которых являются пользователи сайта (далее – Пользователь), в информационных системах ООО "КФ 21" в соответствии с требованиями законодательства Российской Федерации.<br />
            1.3. Требования настоящей Политики распространяются на все подразделения ООО «КФ 21» и работников Оператора, принимающих участие в процессах обработки ПДн Пользователей.<br />
            1.4 Настоящая Политика разработана на основании Конституции Российской Федерации, Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных», Федерального закона от 30.12.2020 №519-ФЗ «О внесении изменений в Федеральный закон «О персональных данных».<br />
            1.5 Целью настоящей Политики является обеспечение защиты прав и свобод пользователей при обработке из персональных данных, определение содержания и порядка обработки ПДн, мер, направленных на защиту ПДн, а также процедур, направленных на выявление и предотвращение нарушений законодательства Российской Федерации в области ПДн.<br />
            1.6 В рамках настоящей Политики, оператором персональных данных (далее – Оператор) выступает юридическое лицо:<br />
            ООО "КФ 21";<br />
            ОГРН 1155027009884 <br />
            ИНН 5027233072<br />
            КПП 504701001<br />
            ОКПО 11721932<br />
            Юр. Адрес: 141701, Московская область, г. Долгопрудный, Проезд Лихачевский, д. 6, ком. 3<br />
            Почтовый адрес: 141701, Московская область, г. Долгопрудный, Проезд Лихачевский, д. 6, ком. 3<br />
            Адрес электронной почты: klass21@go2phystech.ru<br />
            Телефон: +7 (967) 555-49-26<br />
            1.7 Порядок ввода и действие настоящей Политики и внесения в него изменений:<br />
            1.7.1. Настоящая Политика вступает в силу с момента его утверждения Приказом Генерального директора ООО «КФ 21» и действует до отмены или пересмотра.<br />
            1.7.2 Внесение изменений в настоящую Политику утверждаются приказом Генерального директора организации – оператора.</p>

            <p>2. Понятия, используемые в настоящей Политике<br />
            В настоящей Политике применяются следующие термины:<br />
            -автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;<br />
            -информация – сведения (сообщения, данные), независимо от формы их представления;<br />
            - информационная система персональных данных (ИСПДн) – информационная система, представляющая собой совокупность персональных данных, содержащихся в базе данных, а также информационных технологий и технических средств, позволяющих осуществлять обработку таких персональных данных с использованием средств автоматизации или без использования таких средств;<br />
            -конфиденциальность персональных данных – обязанность лиц, получивших доступ к персональным данным, содержать в тайне от третьих лиц и не распространять ПДн без согласия субъекта ПДн, если иное не предусмотрено законодательством Российской Федерации;<br />
            -обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту ПДн;<br />
            -оператор персональных данных – юридическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки, состав персональных данных, подлежащих обработке, перечня действий (операций) с персональными данными и порядка их совершения работниками оператора.<br />
            -обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств, с персональными данными, включая сбор (получение), запись, систематизацию, уточнение (обновление, изменение), передачу (распространение, предоставление), использование (передача, обезличивание, блокирование, удаление, уничтожение), хранение и защиту персональных данных;<br />
            - персональные данные (ПДн) – любая информация, относящаяся к определенному или определяемому на основании этой информации физическому лицу (субъекту ПДн);<br />
            - пользователи (субъекты ПДн) – физические лица, использующие ресурсы сайта <a href="https://kf21.ru">«kf21.ru»</a>;<br />
            -распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц;<br />
            -предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц<br />
            - блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);<br />
            -уничтожение персональных данных – действия, в результате которых становится невозможным восстановить содержание персональных данных в автоматизированной системе персональных данных и/или в результате которых уничтожаются материальные носители персональных данных;<br />
            -целостность информации – способность средства вычислительной техники или информационной системы обеспечивать неизменность информации в условиях случайного и/или преднамеренного искажения (разрушения, уничтожения).</p>

            <p>3. Объем и содержание обрабатываемых персональных данных<br />
            3.1 Объем и содержание ПДн пользователей сайта kf21.ru определяется настоящей Политикой, и включают в себя следующие ПДН:<br />
            • фамилия, имя, отчество<br />
            • год рождения<br />
            • месяц рождения<br />
            • дата рождения<br />
            • место рождения<br />
            • семейное положение<br />
            • пол<br />
            • адрес места жительства<br />
            • адрес регистрации<br />
            • адрес электронной почты<br />
            • номер телефона<br />
            • СНИЛС<br />
            • ИНН<br />
            • гражданство<br />
            • данные документа, удостоверяющего личность<br />
            • реквизиты банковской карты<br />
            • номер расчетного счета<br />
            • номер лицевого счета<br />
            • профессия<br />
            • должность<br />
            • сведения о трудовой деятельности (в том числе стаж работы, данные о трудовой занятости на текущее время с указанием наименования и расчетного счета организации)<br />
            • отношение к воинской обязанности<br />
            • сведения о воинском учете<br />
            • сведения об образовании<br />
            4. Цели обработки персональных данных<br />
            4.1. Оператор персональных данных осуществляет обработку ПДн, в следующих целях:<br />
            - соблюдение законодательства Российской Федерации в области защиты персональных данных и иных нормативно – правовых актов в сфере защиты и обработки персональных данных;<br />
            - оказание услуг, предусмотренных сайтом, физическим лицам, их законным представителям.<br />
            - проведения маркетинговых, информационных, организационных рассылок, связанных с деятельностью сайта; <br />
            - выполнение иных операций, связанных с оказанием услуг, предусмотренных сайтом.</p>

            <p>5. Сроки обработки<br />
            5.1 Сроки обработки ПДн Пользователей ограничивается следующими критериями - до достижения целей обработки, но не более 5 лет с момента предоставления согласия. <br />
            5.2 В случае получения Оператором от Пользователя письменного отзыва разрешения(согласия) на обработку ПДн, срок обработки прекращается в течение 30 календарных дней, с момента получения такого отзыва.</p>

            <p>6. Обработка персональных данных<br />
            6.1 Обработка ПДн Пользователей сайта kf21.ru включает в себя:<br />
            • сбор<br />
            • запись<br />
            • систематизация<br />
            • накопление<br />
            • хранение<br />
            • уточнение (обновление, изменение)<br />
            • извлечение<br />
            • использование <br />
            • блокирование<br />
            • удаление<br />
            • уничтожение</p>

            <p>6.2 Оператор не собирает и не ведёт обработку биометрических персональных данных пользователей.<br />
            6.3 Оператор осуществляет обработку персональных данных путём неавтоматизированной обработки, автоматизированной обработки и смешанной обработки.<br />
            6.4 Автоматизированная обработка ПДн Пользователей осуществляется Оператором с использованием информационных систем персональных данных в соответствии с настоящей Политикой.<br />
            6.5 Неавтоматизированная обработка ПДн Пользователей осуществляется работниками Оператора, путем отображения ПДн на материальных носителях. ПДн, обрабатываемые таким путём подлежат обособлению от иной информации, не являющейся ПДн. Лица, осуществляющие обработку ПДн проинформированы о факте обработки ими ПДн пользователей, а также об особенностях и правилах осуществления обработки ПДн указанным способом.<br />
            6.6 Все данные обрабатываются и используются Оператором исключительно в целях, указанных в настоящей Политике. Хранение осуществляется до момента отзыва согласия Пользователем, исполнения цели обработки данных, срока, указанного в п. 5 настоящей Политики, или прекращения деятельности Оператора.<br />
            6.7 По достижению целей обработки ПДн, истечения срока хранения или прекращения деятельности Оператора, персональные данные Пользователей, обработку которых осуществляет Оператор, подлежат уничтожению.</p>

            <p>7. Требования к обработке персональных данных<br />
            7.1 Под организацией обработки ПДн пользователей понимается приведение в соответствие процессов обработки ПДн требованиям действующего законодательства.<br />
            7.2 В рамках организации, устанавливаются следующие обязательные требования:<br />
            - осуществление обработки ПДн с соблюдением требований действующего законодательства, исключительно для достижения целей обработки, указанных в п. 4 настоящего Политики;<br />
            - полученные персональные данные пользователей не могут быть использованы в целях причинения имущественного и/или морального вреда пользователей, затруднения реализации прав и свобод человека.<br />
            - обработка ПДн пользователей без согласия не допускается.<br />
            - Пользователь предоставляет Оператору персональные данные лично, путём внесения информации в интерактивные формы на сайте или направлением сведений в иной форме.<br />
            - защита ПДн пользователя от неправомерного и нецелевого использования, обеспечивается Оператором за счет собственных средств, в порядке, предусмотренном законодательством Российской Федерации.<br />
            - Пользователь, предоставляя согласие на обработку его персональных данных, подтверждает, что ознакомлен с настоящей Политикой, и согласен с её положениями.<br />
            7.3 Внося свои персональные данные на Сайт (в любую форму, скрипт или плагин размещенные на любой из страниц сайта), как в процессе приобретения услуг, так и в процессе использования сайта, Пользователь предоставляет Оператору свое безоговорочное согласие на обработку его персональных данных, как загруженных самим Пользователем, так и полученных Оператором в автоматизированном режиме, в результате действий Пользователя.<br />
            7.4 В соответствии с пунктом 7 части 4 статьи 16 Федерального закона от 27 июля 2006 года № 149-ФЗ «Об информации, информационных технологиях и о защите информации» все обрабатываемые Оператором персональные данные хранятся на серверах, находящихся на территории Российской Федерации.</p>

            <p>8. Сбор, обработка, передача, хранение и уничтожение персональных данных<br />
            8.1 Сбор (получение) ПДн осуществляется путем представления их Пользователем на основании согласия, за исключением случаев, прямо предусмотренных действующим законодательством Российской Федерации. Если ПДн Пользователя возможно получить только у третьей стороны, то Пользователь уведомляется об этом заранее, и от него должно быть получено соответствующее согласие, предусмотренной законодательством о защите персональных данных.<br />
            8.1.1 В обязанности работников Оператора, осуществляющих сбор ПДн Пользователей, входит получение (проверка наличия подтверждения) их согласия на обработку ПДн.<br />
            8.2 Обработка (Использование) ПДн включает в себя действия (операции) с ПДн Пользователя, совершаемые в целях предусмотренных п. 4 настоящей Политики, порождающих юридически значимые последствия в отношении Пользователя.<br />
            8.2.1 Использование ПДн осуществляется только с Согласия Пользователя на обработку его персональных данных, полученных путём, предусмотренным настоящей Политикой.<br />
            8.2.2 Пользователь, заполняя форму и ставя соответствующую отметку в форме, безусловно подтверждает согласие на обработку персональных данных Оператором.<br />
            8.3 Передача персональных данных Пользователей третьим лицам осуществляется в исключительно рамках установленных настоящим положением целей, в порядке, предусмотренным законодательством Российской Федерации.<br />
            8.4 Хранение ПДн осуществляется Оператором, в соответствии с согласием пользователя и в течение срока, установленного настоящей Политикой. Хранение ПДн Пользователей осуществляется Оператором на машинных и физических носителях. Хранение персональных данных прекращается с момента достижения целей их обработки, получения запрета на обработку ПДн от субъекта ПДн или удаления сайта. При наступлении любого из указанных выше событий, ПДН Пользователя подлежит уничтожению. Хранение ПДн, цели обработки которых различны, осуществляются раздельно. Раздельное хранение ПДн осуществляется в ИСПДн Оператора, или на физических носителях, в рамках исполнения должностных обязанностей работников Оператора.<br />
            8.4.1 Резервное копирование персональных данных Оператор обеспечивает в своей ИСПДн и сетевой инфраструктуре с целью предотвращения потери информации при сбоях оборудования; программного обеспечения; аппаратных сбоях; сбоях операционной системы и прикладного программного обеспечения; заражении вредоносными программами; непреднамеренном уничтожении информации, ошибках работников Оператора, преднамеренном уничтожении информации. Резервное копирование создает возможность перемещения персональных данных от одной рабочей станции Оператор к другой, таким образом, снимает зависимость целостности персональных данных от конкретной рабочей станции и/или конкретного помещения. <br />
            Резервному копированию подлежит информация следующих основных категорий:<br />
            - Персональные данные Пользователей;<br />
            - Информация, необходимая для восстановления серверов и систем управления базами данных Сайта;<br />
            - Информация автоматизированных систем информационной инфраструктуры Оператора, в том числе баз данных.<br />
            Носители, предназначенные для хранения резервных копий данных, помечаются как содержащие информацию ограниченного доступа (например – коммерческая тайна), и охраняется в соответствии с действующим законодательством.<br />
            8.5 Блокирование обрабатываемых ПДн осуществляется при выявлении недостоверности обрабатываемых сведений или выявления незаконных действий в отношении Пользователя, а также в случаях:<br />
            - поступления требования от субъекта ПДн;<br />
            - поступления требования уполномоченного органа по защите прав Пользователя;<br />
            - выявления нарушений по результатам проведения внутренних контрольных мероприятий.<br />
            8.6 Уничтожение ПДн Пользователей производится на основаниях, предусмотренных настоящей Политикой, и заключаются в совершении работником Оператора действий, в результате которых становится невозможным восстановить содержание персональных данных в автоматизированной системе персональных данных и/или в результате которых уничтожаются материальные носители персональных данных;</p>

            <p>9. Доступ к персональным данным<br />
            9.1 Внутренний доступ к ПДн Пользователей имеют следующие работники Оператора:<br />
            - Генеральный директор;<br />
            - Заместитель генерального директора;<br />
            - Работник, назначенный ответственным за организацию обработки персональных данных;<br />
            - Руководителя структурных подразделений, выполняющих обработку ПДн.<br />
            - Работники Оператора, в должностные обязанности которых входит обработка входящих заявок на получение услуг, поступающих на сайт;<br />
            - Работники Оператора, в должностные обязанности которых входит обработка, учёт и организация хранения ПДн Пользователей.<br />
            9.2 Внешний доступ к ПДн Пользователей могут получить доступ лица, в рамках оказания услуг, представленных на сайте, или в иных случаях, предусмотренных законодательством Российской Федерации.<br />
            9.3 Субъект персональных данных имеет право получить доступ к обрабатываемым Оператором персональным данным Субъекта, направив сообщение по одному из указанных в п.1.6 настоящей Политики адресов письменное требование (в цифровом или бумажном виде). Оператор предоставляет Субъекту ПДн информацию о содержании и обработке его персональных данных в течении 5 рабочих дней с момента получения такого требования.</p>

            <p>10. Организация защиты персональных данных<br />
            10.1 Оператор при обработке ПДн Пользователя принимает все необходимые меры по защите ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения и иных неправомерных действий в отношении ПДн, а также обеспечивает необходимый и достаточный уровень защищенности и соблюдения конфиденциальности, целостности и доступности обрабатываемых им персональных данных на всех этапах обработки.<br />
            10.2 Оператором предусмотрены системы защиты ПДн, состоящие из систем правовой, организационной и технической защиты ПДн.<br />
            Система правовой защиты включает в себя комплекс мер правового, организационного и нормативного характера, обеспечивающих создание, функционирование и совершенствование системы защиты ПДн Оператора.<br />
            Система организационной защиты включает в себя организацию структуры управления системой защиты ПДН, разрешительную систему защиты информации, разграничение допусков при работе с данными Пользователей.<br />
            Система технической защиты включает в себя комплекс технических, программных и программно – аппаратных средств, обеспечивающих защиту ПДн Пользователей.<br />
            10.3 Перечень мер, предусмотренных Оператором для защиты обрабатываемых им ПДн Пользователей:<br />
            - назначение ответственного за контроль и организацию обработки ПДн работника.<br />
            - ограничение круга лиц, имеющих доступ к ПДн;<br />
            - проведение инструктажа и обучения лиц, имеющих доступ к ПДн;<br />
            - осуществление регулярного контроля и аудита соответствия требованиям законодательства о защите персональных данных, практики обработки ПДн работниками Оператора;<br />
            - разработка и внедрение локально – нормативных актов, регулирующих правила обработки ПДн Пользователей работниками Оператора;<br />
            - установление правил доступа к ПДн, обрабатываемым в ИСПДн Оператора, а также регистрация и учет всех действий, совершаемых с ПДн в ИСПДн Оператора;<br />
            - разграничение прав доступа в ИСПДн Оператора работников Оператора, с целью предотвращения неправомерного или случайного получения доступа к ПДн Пользователя неуполномоченных лиц;<br />
            - организация учёта и хранения машинных и физических носителей, содержащих ПДн Пользователей, обеспечивающих сохранность данных и исключающих несанкционированный допуск к ним;<br />
            - применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;<br />
            - организация резервного копирования информации;<br />
            - проведение работ по выявлению фактов несанкционированного доступа к ПДн и принятия соответствующих мер;<br />
            - применение иных мер, предусмотренных законодательством РФ в области защиты ПДн.<br />
            10.4 Лица, виновные в нарушении норм, регулирующих обработку и защиту ПДн Пользователя, несут ответственность в соответствии с действующим законодательством Российской Федерации.<br />
            10.5 Работники Оператора, допущенные к обработке ПДН Пользователей, за разглашение полученной в ходе трудовой деятельности информации, несут ответственность в соответствии с действующим законодательством Российской Федерации.</p>

            <p>11. Заключительные положения<br />
            11.1 В соответствии с действующим законодательством, настоящая Политика является общедоступным документом. Действующая редакция расположена на странице Сайта, и доступна по ссылке – <a href="https://kf21.ru/confidential-policy">«kf21.ru/confidential-policy»</a>.<br />
            11.2. Оператор имеет право в одностороннем порядке изменить текст настоящей Политики без предварительного уведомления об этом Пользователя. В таком случае, надлежащим уведомлением Пользователя будет являться публикация новой редакции Политики конфиденциальности на страницах Сайта. Ответственность за своевременное ознакомление с действующей редакцией Политики конфиденциальности целиком и полностью лежит на Пользователе.<br />
            11.3. Стороны, правоотношения которых регулируются настоящей Политикой, принимают и согласны с фактом отнесения вопроса их правоотношений к правовой юрисдикции и правовому полю Российской Федерации. <br />
            11.4 Согласие на условия настоящей Политики, данное Пользователем, не являющимся гражданином Российской Федерации, означает, что положения, изложенные в настоящем документе, ему понятны и в переводе он не нуждается. В случае необходимости в переводе, иностранные Пользователи обязуются осуществить перевод на нужный им язык своими силами и за свой счет. При возникновении разночтений между вариантом настоящей Политики на русском языке и версии Политики на иностранном языке, приоритет отдаётся русскоязычной версии.<br />
            12.4. В случае возникновения конфликтных или спорных ситуаций, между Оператором и субъектом персональных данных, вытекающих из использования Оператором персональных данных Пользователя, разрешаются в порядке, предусмотренном действующим законодательством РФ в претензионном досудебном порядке. Претензия может быть направлена в письменном или электронном виде, по адресам (электронным адресам) указанными сторонами в качестве контактных. Рассмотрение и предоставление ответа на претензию по вопросам обработки персональных данных составляет 30 (тридцать) рабочих дней. В случае невозможности разрешения конфликта или спора в досудебном порядке, вопрос подлежит рассмотрению в суде по месту нахождения Оператора, в порядке, предусмотренном законодательством Российской Федерации.<br />
            </p>
        </div>
    )
}

export default ConfidentialPolicy;