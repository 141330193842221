import { getMonthName } from '../../../../../services/clientHome';
import './index.scss';

const aplicationTypeData = (id) =>{
    if(id === 1)
        return { id, name: 'Проверка табеля' }
    else
        return { id: -1, name: 'Не найден'}

}

const TeachersTableElem = ({item, clickFunc, buttonLabel}) =>{
    const {id, city, school, classNumber, classLetter, program, month, year, subgroup, cityId, schoolId, classId, programId, subgroupId } = item;

    const checkTabelFunc = () =>{
        let filters = {
            tabelId: id,
            city: {id: cityId, name: city},
            school: {id: schoolId, name: school},
            classObj: {id: classId, number: classNumber, letter: classLetter},
            program: {id: programId, name: program, isContainsSubgroup: subgroupId? true: false},
            subgroup: subgroupId? {id: subgroupId, name: subgroup}: {id: 0, name: ''},
            month: {id: month, label: getMonthName(month)},
            year,
        }

        filters.post = {
            cityId: filters.city.id,
            schoolId: filters.school.id,
            classId: filters.classObj.id,
            programId: filters.program.id,
            month: filters.month.id,
            year: filters.year,
        }
        if(filters.subgroup.id) filters.post.groupId = filters.subgroup.id;
        clickFunc({item: filters})
    }

    return (
        <div className={`admin__applications-table-line d-flex justify-between align-center`}>
            <div className="admin__teachers-applications-table-line-item city">{city}</div>

            <div className="admin__teachers-applications-table-line-item school">{school}</div>

            <div className="admin__teachers-applications-table-line-item class-name">{classNumber}{(classLetter.toLowerCase() === 'группа')? (' ' + classLetter): (classLetter + ' класс')}</div>

            <div className="admin__teachers-applications-table-line-item program">{program}</div>

            <div className="admin__teachers-applications-table-line-item subgroup">{subgroup? subgroup: 'Нет'}</div>

            <div className="admin__teachers-applications-table-line-item month">{getMonthName(month)} {year}</div>

            <button className="admin__applications-table-line-button follow" onClick={checkTabelFunc}>{buttonLabel}</button>
        </div>
    )
}

export default TeachersTableElem;