import React, { useEffect } from 'react';
import Autorization from './components/Autorization/Autorization';
import { selectAuthorizationData } from './redux/authorization/selectors';
import { useSelector } from 'react-redux';
import { User } from './components';
import Admin from './components/Admin';
import { Route, Routes } from 'react-router-dom';
import ConfidentialPolicy from './components/Documents/ConfidentialPolicy';
import ProcessingAgreement from './components/Documents/ProcessingAgreement';
import Teacher from './components/Teacher/index';

function App() {
  const { user } = useSelector(selectAuthorizationData)
  return (
    <React.Fragment>
      {
        // <Admin />
        // <Teacher />
        // <User/>
        // <Autorization/>
        (user.role === 'administrative-api') ? <Admin/>
        :(user.role === 'teaching-api') ? <Teacher/>
        :(user.role === 'parental-api') ? <User/>
        :<Routes>
            <Route path='/confidential-policy' element={<ConfidentialPolicy/>} exact/>        
            <Route path='/processing-agreement' element={<ProcessingAgreement/>} exact/>        
            <Route path='/*' element={<Autorization/>} exact/>
        </Routes>

      }
    </React.Fragment>
  );
}

export default App;