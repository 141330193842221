// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../img/two-people-grey-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__programs-program-list-elem-additionaly {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px; }
  .admin__programs-program-list-elem-additionaly span {
    color: #727272; }

.admin__programs-program-list-elem-teachers {
  width: 100%; }
  .admin__programs-program-list-elem-teachers-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
    background-size: contain; }
  .admin__programs-program-list-elem-teachers-label {
    width: calc(100% - 28px);
    height: 20px;
    color: #727272;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Programs/Programs/ProgramsListElem/index.scss"],"names":[],"mappings":"AAEI;EACI,eAAe;EACf,gBAAgB;EAChB,mBAAmB,EAAA;EAHtB;IAMO,cAAc,EAAA;;AAItB;EACI,WAAW,EAAA;EACX;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,oEAA6E;IAC7E,wBAAwB,EAAA;EAG5B;IACI,wBAAwB;IACxB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB,EAAA","sourcesContent":[".admin__programs-program-list-elem{\n\n    &-additionaly{\n        font-size: 14px;\n        font-weight: 500;\n        margin-bottom: 12px;\n\n        & span{\n            color: #727272;\n        }\n    }\n\n    &-teachers{\n        width: 100%;\n        &-icon{\n            width: 20px;\n            height: 20px;\n            margin-right: 8px;\n            background: url(../../../../../img/two-people-grey-icon.svg) no-repeat center;\n            background-size: contain;\n        }\n\n        &-label{\n            width: calc(100% - 28px);\n            height: 20px;\n            color: #727272;\n            font-size: 14px;\n            font-weight: 500;\n            white-space: nowrap; // текст в одну строку\n            overflow: hidden; // скрывает все что выходит за зону контейнера\n            text-overflow: ellipsis; // заменяет скрытый текст на многоточие \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
