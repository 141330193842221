import { convertNumber } from './../../../../../services/clientHome';

const PaymentHistoryItem = (item, key) =>{
    const {id, name, date, summ} = item;

    return(
        <div className='user-page__finance-payment-history-table-line d-flex align-center justify-between' key={`user-page__finance-payment-history-table-line-${key}`}>
            <div className='user-page__finance-payment-history-table-line-item name'>{name}</div>
            <div className='user-page__finance-payment-history-table-line-item date'>{date}</div>
            <div className='user-page__finance-payment-history-table-line-item summ'>{convertNumber(summ)}₽</div>
        </div>
    )
}

export default PaymentHistoryItem;