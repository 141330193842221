import './index.scss'
import Applications from './Applications/Applications';

const ApplicationsPage = () =>{
    return(
        <div className={`admin__applications pl-sidebar`}>
            <Applications/>
        </div>
    )
}

export default ApplicationsPage;