// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__finance-page-clients-table-line-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px; }
  .admin__finance-page-clients-table-line-item.name {
    width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Finance/Finance/Clients/index.scss"],"names":[],"mappings":"AACI;EACI,eAAe;EACf,gBAAgB;EAChB,iBAAiB,EAAA;EAHpB;IAMO,WAAW,EAAA","sourcesContent":[".admin__finance-page-clients{\n    &-table-line-item{\n        font-size: 14px;\n        font-weight: 500;\n        line-height: 21px;\n\n        &.name{\n            width: 100%;\n        }        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
