import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Calend from './../../../../Calendar/Calendar';

import './index.scss'
import PopupDropListElem from '../../../../PopupDropListElem';
import { Child, PaymentType } from '../../../../Filters/FiltersList';
import { selectAdminFinancePaymentHistory } from '../../../../../redux/admin/finance/paymentsHistory/selectors';
import { setAdminFinanceUserPaymentHistoryFilters } from '../../../../../redux/admin/finance/paymentsHistory/paymentsHistorySlice';
import { getDateFormatFunc } from '../../../../../services/clientHome';


const UserPaymentHistoryFilters = ({popupBack, closePopup, clickFunk}) =>{
    const dispatch = useDispatch()
    
    const { adminFinanceUserPaymentHistoryFilters:filters } = useSelector(selectAdminFinancePaymentHistory);

    const [activeList, setActiveList] = useState('')

    const [calendActive, setCalendActive] = useState(false)

    const [formData, setFormData] = useState({
        child: {id: 0, name: ''},
        paymentType: {id: 0, name: '', color: ''},
        date: '',
    })

    const [searchData, setSearchData] = useState({
        child: '',
    })

    useEffect(()=>{
        setFormData(filters)
        setSearchData({
            child: '',
        })
        setActiveList('')
    }, [popupBack])

    const saveFilters = () =>{
        let filterActiveData = {
            child: formData.child,
            paymentType: formData.paymentType,
            date: formData.date,
        }
        let filtersPostData = {}
        if(formData.child.id) filtersPostData.studentId = formData.child.id;
        if(formData.paymentType.id) filtersPostData.method = formData.paymentType.id;

        if(formData.child.id || formData.paymentType.id || formData.date){
            dispatch(setAdminFinanceUserPaymentHistoryFilters({...filterActiveData, post: filtersPostData, date: formData.date}))
            closePopup()
        }
    }

    const cleanFilters = () =>{         
        dispatch(setAdminFinanceUserPaymentHistoryFilters({
            child: {id: 0, name: ''},
            paymentType: {id: 0, name: '', color: ''},
            date: '',
            post: {}
        }))
        setFormData({
            child: {id: 0, name: ''},
            paymentType: {id: 0, name: '', color: ''},
            date: '',
        })
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('admin-popup__finance-page-payment-type-val') && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    child: '',
                })
            }
            if(calendActive && !e.target.classList.contains("admin-popup__sertificate-confirm-line-item-validate-item") && e.target.closest(".admin-popup__finance-page-user-card-calendar") === null){                
                setCalendActive(false)
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <Child 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                isFilter={true}
                url='/api/v1/administrative/finance'
            /> 

            <label htmlFor="" className='popup__label mb-4'>Дата</label>
            <div className='admin-popup__finance-page-calendar-container mb-16'>
                <div className='admin-popup__finance-page-calendar-input' onClick={()=>{
                    setCalendActive(!calendActive)
                }}>
                    {(formData.date)? `${formData.date}`: ''}
                </div>
                
                <Calend classes={`admin-popup__sertificate-confirm-calendar admin-popup__finance-page-user-card-calendar ${calendActive? 'active': ''}`} checkDate={formData.date? getDateFormatFunc(formData.date): formData.date} setCheckDate={(data)=>{
                    setFormData({...formData, date: data.toLocaleDateString()})
                    setCalendActive(false)
                }}/>
            </div>

            <PaymentType 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
            /> 


            <div className='d-flex mt-24'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={cleanFilters}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${!(formData.child.id || formData.paymentType.id || formData.date)? 'deactive-button': ''}`} onClick={saveFilters}>Применить</button>
            </div>       
        </div>
    )
}

export default UserPaymentHistoryFilters;