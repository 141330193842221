const Child = ({item, status, clickFunk}) =>{
    const {id, name, classObj, birthDay, city, school, isEdit} = item;
    const type = 1
    return (
       ( type === 1)?
        <div className="user-page__about-container child w50-m24 d-flex flex-column justify-between" key={`user-page__about-container-child-elem-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <div className={`user-page__field-icon edit-child mr-12 ${status !== -1? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/>
                    {/* <div className={`user-page__field-icon edit-child mr-12 ${status === 0? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/> */}
                    <div className='user-page__about-profile-name child'>{name}</div>
                </div>
                <div className='user-page__about-profile-btn cursor-def'>{classObj.number}{classObj.letter} класс</div>
            </div>
            <div className='user-page__underline'/>
            <div>
                <div className='d-flex justify-between align-center mb-16'>
                    <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                        <div className='user-page__field-text'>{birthDay}г</div>
                        <div className='user-page__field-icon calendar'/>
                    </div>
                    <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                        <div className='user-page__field-text'>{city.name}</div>
                        <div className='user-page__field-icon location'/>
                    </div>
                </div>
                <div className='user-page__field d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{school.name}</div>
                    <div className='user-page__field-icon scool'/>
                </div>

                {
                    (isEdit)? 
                    <div className="user-page__about-child-block-button">
                        Посмотреть изменения
                    </div>: null                
                }
            </div>
        </div>
        :(type === 2)?
        <div className="user-page__about-container child w50-m24 d-flex flex-column justify-between" key={`user-page__about-container-child-elem-${id}`}>
            <div>
                <div className='d-flex justify-between about-profile-underline'>
                    <div className='d-flex align-center'>
                        <div className={`user-page__field-icon edit-child mr-12 ${status !== -1? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/>
                        {/* <div className={`user-page__field-icon edit-child mr-12 ${status === 0? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/> */}
                        <div className='user-page__about-profile-name child'>{name}</div>
                    </div>
                    <div className='user-page__about-profile-btn cursor-def'>{classObj.number}{classObj.letter} класс</div>
                </div>
                <div className='user-page__underline'/>
            </div>
            <div>
                <div className='d-flex justify-between align-center mb-16'>
                    <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                        <div className='user-page__field-text'>{birthDay}г</div>
                        <div className='user-page__field-icon calendar'/>
                    </div>
                    <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                        <div className='user-page__field-text'>{city.name}</div>
                        <div className='user-page__field-icon location'/>
                    </div>
                </div>
                <div className='user-page__field d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{school.name}</div>
                    <div className='user-page__field-icon scool'/>
                </div>

                {
                    (isEdit)? 
                    <div className="user-page__about-child-block-button">
                        Посмотреть изменения
                    </div>: null                
                }
            </div>
        </div>
        :(type === 3)?
        <div className="user-page__about-container child w50-m24" key={`user-page__about-container-child-elem-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <div className={`user-page__field-icon edit-child mr-12 ${status !== -1? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/>
                    {/* <div className={`user-page__field-icon edit-child mr-12 ${status === 0? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}/> */}
                    <div className='user-page__about-profile-name child'>{name}</div>
                </div>
                <div className='user-page__about-profile-btn cursor-def'>{classObj.number}{classObj.letter} класс</div>
            </div>

            <div className='user-page__underline'/>

            <div className='d-flex justify-between align-center mb-16'>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{birthDay}г</div>
                    <div className='user-page__field-icon calendar'/>
                </div>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{city.name}</div>
                    <div className='user-page__field-icon location'/>
                </div>
            </div>
            <div className='user-page__field d-flex justify-between align-center'>
                <div className='user-page__field-text'>{school.name}</div>
                <div className='user-page__field-icon scool'/>
            </div>

            {
                (isEdit)? 
                <div className="user-page__about-child-block-button">
                    Посмотреть изменения
                </div>: null                
            }
        </div>
        :<div className="user-page__about-container child w50-m24" key={`user-page__about-container-child-elem-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <div className='user-page__about-profile-name child'>{name}</div>
                </div>
                <div className='user-page__about-profile-btn cursor-def'>{classObj.number}{classObj.letter} класс</div>
            </div>

            <div className='user-page__underline'/>

            <div className='d-flex justify-between align-center mb-16'>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{birthDay}г</div>
                    <div className='user-page__field-icon calendar'/>
                </div>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{city.name}</div>
                    <div className='user-page__field-icon location'/>
                </div>
            </div>
            <div className='user-page__field d-flex justify-between align-center'>
                <div className='user-page__field-text'>{school.name}</div>
                <div className='user-page__field-icon scool'/>
            </div>

            <div className="user-page__about-child-block-button" onClick={()=>clickFunk(item)}>
                {isEdit? 'Посмотреть изменения': 'Редактировать'}
            </div>
        </div>
    )
}

export default Child;