import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import { useEffect, useState } from 'react';
import { fetchAdminFinanceClientsData } from '../../../../../redux/admin/finance/clients/asyncActions';
import { selectAdminFinanceClients } from '../../../../../redux/admin/finance/clients/selectors';
import CLientsListElem from '../ClientsListElem';


import './index.scss';
import { scrollingPagination, useDebouncedValue, downloadFilePost } from './../../../../../services/clientHome';
import { setAdminFinanceClientsScrollReset } from '../../../../../redux/admin/finance/clients/clientsSlice';
import { setAdminFinanceUserData } from '../../../../../redux/admin/finance/user/userSlice';
import ExportButton from '../../../../Export';



const Clients = ({setPopupType, query, activeList, setActiveList}) =>{
    const dispatch = useDispatch();
    const { adminFinanceClientsData, adminFinanceClientsPageLast:pageLast, adminFinanceClientsScrollReset:scrollReset, adminFinanceClientsLoading:loading, adminFinanceClientsFilters:filters } = useSelector(selectAdminFinanceClients);

    const [page, setPage] = useState(0)
    const [exportXlsLoading, setExportXlsLoading] = useState(false)

    const searchText = useDebouncedValue(query, 600)

    useEffect(()=>{
        dispatch(fetchAdminFinanceClientsData({query, count: 30, page: 0, pageLast, isScrolling: false, filters: filters.post}))
        setPage(0)
    }, [filters, searchText])

    const checkedClient = (item) =>{        
        dispatch(setAdminFinanceUserData(item))
    }

    const excelExportFunc = () =>{
        downloadFilePost({url:'/api/v1/administrative/finance/export-clients?', name:'Список должников', type:'.xlsx', postData: {...filters.post, ...(query? {query}: {})}, setLoading:(result)=>setExportXlsLoading(result)})
    }

    return (
        <div className={`admin__finance-container clients`}>
            <div className='admin__finance-container-top d-flex justify-between align-center mb-16'>
                <div className='admin__finance-title'>Клиенты</div>

                <div className='d-flex align-center'>
                    <button className='admin__finance-button d-flex align-center mr-8' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('FinanceClientsFilters')
                    }}>
                        <div className='admin__finance-button-label'>Фильтры</div>
                        <div className='admin__finance-button-icon filters'/>
                    </button>

                    <ExportButton name={'admin-finance-clients-export'} activeList={activeList} setActiveList={setActiveList} elems={[
                        {
                            name: 'Excel - Список должников',
                            loading: exportXlsLoading,
                            clickFunc: excelExportFunc
                        }
                    ]}/>
                </div>
            </div>

            <div className='admin__finance-table-line title d-flex justify-between'>
                <div className='admin__finance-page-clients-table-line-item name'>ФИО</div>
            </div>

            <div className='admin__finance-table'   
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchAdminFinanceClientsData({query, count: 30, page: page+1, isScrolling: true, filters: filters.post})),
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setAdminFinanceClientsScrollReset(false))
                )}
            >
                {
                    adminFinanceClientsData.map(item=><CLientsListElem item={item} key={`admin__finance-page-table-clients-list-elem-conatiner-${item.id}`} clickFunc={()=>checkedClient(item)}/>)
                }
            </div>
        </div>
    )
}

export default Clients;