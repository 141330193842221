// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__tabel-confirm {
  width: 95%; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Applications/Tabel/index.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA","sourcesContent":[".admin-popup__tabel-confirm{\n    width: 95%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
