import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userNavBarActive: (sessionStorage.getItem('userNavBarActive')? sessionStorage.getItem('userNavBarActive'): 0),
};

const userNavigations = createSlice({
  name: 'userNavigations',
  initialState,
  reducers: {
    setUserNavBarActive(state, action) {
      sessionStorage.setItem('userNavBarActive', action.payload)
      state.userNavBarActive = action.payload;
    },
  },
});

const { actions, reducer } = userNavigations;


export default reducer;

export const { 
  setUserNavBarActive,
} = actions;

