import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminProgramTeachersData } from './asyncActions';

const initialState = {
  adminProgramsTeachersData: [
  ],
};

const adminProgramTeachers = createSlice({
  name: 'AdminProgramTeachers',
  initialState,
  reducers: {
    setAdminProgramsTeachersData(state, action) {
      state.adminProgramsTeachersData = action.payload;      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProgramTeachersData.pending, (state, action) => {
      state.adminProgramsTeachersData = [];
    });
    builder.addCase(fetchAdminProgramTeachersData.fulfilled, (state, action) => {
      if(action.payload && action.payload.teachers && Array.isArray(action.payload.teachers)) state.adminProgramsTeachersData = action.payload.teachers;
    });
    builder.addCase(fetchAdminProgramTeachersData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminProgramTeachers;


export default reducer;

export const { 
    setAdminProgramsTeachersData,
} = actions;

