import './index.scss'

const DropDownList = ({listData, label='name', checkedElem, clickFunk, listName, listIsOpen, setListIsOpen, classes=''}) =>{
    const AddDropListItem = (item, key) =>{
        const {id} = item;
        return(
            <div className={`popup__filters-drop-down-list-item ${listName} ${(id === checkedElem.id || (checkedElem.id == 0 && key === 0))? 'active': ''}`} key={`user-page_drop-down-list-${listName}-${id}`} onClick={()=>clickFunk(item)}>
                {item[label]}
            </div>
        )
    }

    return (
        <div className={`popup__filters-drop-down-list-container d-flex justify-center align-center ${classes}`}>
            <div className={`popup__filters-drop-down-list-checked d-flex justify-between align-center ${listName} ${(listIsOpen===listName)? 'active': ''}`} onClick={()=>setListIsOpen((listIsOpen===listName)? '': listName)}>
                <div className={`popup__filters-drop-down-list-checked-label mr-12 ${listName}`}>{checkedElem[label]}</div>
                <div className={`popup__filters-drop-down-list-checked-arrow ${listName}`}/>
            </div>
            <div className={`popup__filters-drop-down-list ${listName} ${(listIsOpen===listName)? 'active': ''}`}>
                <div className={`popup__filters-drop-down-list-scroll ${listName}`}>
                    {
                        listData.map(AddDropListItem)
                    }
                </div>
            </div>
        </div>
    )
}

export default DropDownList;