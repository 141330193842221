import './index.scss'
import RefusalComment from './../../RefusalComment/index';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectAdminAplicationConfirm } from './../../../../../redux/admin/aplications/aplicationConfirm/selectors';

const childIsEdited = (data, formData) =>{
    let dataObj = {name: data.name, city: data.city.id, school: data.school.id, class: data.classObj.id, birthDay: data.birthDay};
    let formDataObj = {name: formData.name, city: formData.city.id, school: formData.school.id, class: formData.classObj.id, birthDay: formData.birthDay};

    return JSON.stringify(dataObj) === JSON.stringify(formDataObj)? false: true;
}

const EditChildConfirm = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()

    const { adminEditChildConfirmAplication:checkData } = useSelector(selectAdminAplicationConfirm);

    const [commentIsActive, setCommentIsActive] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [childEdited, setChildEdited] = useState(false)
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classObj: {id: 0, number: '', letter: ''},
        classNumber: '',
        classLetter: '',
        birthDay: '',
    })

    useEffect(()=>{
        setChildEdited(childIsEdited(checkData, formData))
    }, [formData])

    useEffect(()=>{
        if(checkData.id){
            setFormData({
                id: checkData.id, 
                name: checkData.name, 
                city: checkData.city, 
                school: checkData.school, 
                classObj: checkData.classObj, 
                birthDay: checkData.birthDay, 
                classNumber: checkData.classObj.number, 
                classLetter: checkData.classObj.letter
            })
        }
        else{
            setFormData({
                id: 0,
                name: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classObj: {id: 0, number: '', letter: ''},
                classNumber: '',
                classLetter: '',
                birthDay: '',
            })
        }
    }, [popupBack])

    

    return(
        <div className={`admin-popup__application-child-edited-confirm-container ${commentIsActive? 'deactive': 'active'}`}>


            {
                (childEdited)?(
                    <>
                        {/* <div className={`popup__button mb-16 ${childEdited? 'deactive-button': ''}`} onClick={editChild}>Сохранить изменения</div>
                        <div className={`popup__button cancel  ${childEdited? 'deactive-button': ''}`} onClick={cancelChanges}>Отменить изменения</div> */}
                    </>
                ):(
                    <>
                        <div className={`popup__button mb-16 ${loading? 'deactive-button': ''}`} onClick={()=>{}}>Принять</div>
                        <div className={`popup__button cancel  ${loading? 'deactive-button': ''}`} onClick={()=>{
                            setCommentIsActive()
                        }}>Отклонить</div>
                    </>
                )
            }

            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={()=>{}}/> 
            {/* (comment)=>confirmUserFunc(false, comment) */}

        </div>
    )
}

export default EditChildConfirm;