import Finance from './Finance/Finance';
import './index.scss'
import { Routes, Route } from 'react-router-dom';
import UserCard from './UserCard/UserCard';

const FinancePage = () =>{
    return(
        <div className={`admin__finance pl-sidebar`}>
            <Routes>
                <Route path='/' element={<Finance/>} exact/>
                <Route path='/user-profile-card' element={<UserCard/>} exact/>
            </Routes>
        </div>
    )
}

export default FinancePage;