import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosProxyCatchGet } from '../../../../axiosProxy';
import axiosProxy from './../../../../axiosProxy';


export const fetchAdminFinanceClientsData = createAsyncThunk(
  'AdminFinanceClients/fetchAdminFinanceClientsData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', filtres:null}) => {
    let postData = {count: dataObj.count, page: dataObj.page};
    if(dataObj.query) postData.query = dataObj.query;
    
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/programs/get-clients?`+ new URLSearchParams({...postData, ...dataObj.filters}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


