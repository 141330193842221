import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminTeachersData = createAsyncThunk(
  'AdminTeachers/fetchAdminTeachersData',
  async (dataObj={count:30, page:0, isScrolling: false, query: '', filters:null}) => {
    
    let postData = {count: dataObj.count, page: dataObj.page};
    if(dataObj.query) postData.query = dataObj.query;
    let filtersData = {}
    if(dataObj.filters){
      if(dataObj.filters.city && dataObj.filters.city.id) filtersData.cityId = dataObj.filters.city.id;
      if(dataObj.filters.school && dataObj.filters.school.id) filtersData.schoolId = dataObj.filters.school.id;
    }

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/teachers/get-teachers-list?`+ new URLSearchParams({...postData, ...dataObj.filters}))
      if(response.status < 400){
        return {data: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};                      
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


