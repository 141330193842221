import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import DocumentsListElem from './DocumentElem';
import './index.scss'
import { fetchAdminFinanceFullDocumentsData } from './../../../../../redux/admin/finance/documents/asyncActions';
import { selectAdminFinanceDocuments } from './../../../../../redux/admin/finance/documents/selectors';
import { useEffect } from 'react';


const Documents = ({setPopupType, checkedClient}) =>{
    const dispatch = useDispatch()

    const { adminFinanceFullDocumentsData:documentsData } = useSelector(selectAdminFinanceDocuments);

    useEffect(()=>{
        dispatch(fetchAdminFinanceFullDocumentsData({clientId: checkedClient.id}))
    }, [])

    return(
        <div className={`admin__finance-container documents`}>
            <div className='admin__finance-user-card-documents-top d-flex justify-between align-center mb-24'>
                <div className='admin__finance-title'>Документы</div>
                <div className={`admin__finance-user-card-documents-status red`}>Ожидают подтверждения</div>
            </div>
            <div className='admin__finance-user-card-documents-list'>
                {
                    documentsData.map(item => <DocumentsListElem item={item} key={`admin__finance-user-card-documents-list-item-container-${item.id}`}/>)
                }

                {
                    !documentsData.length? (
                        <div className='admin__finance-user-card-documents-list-not-data-container'>
                            <div className='admin__finance-user-card-documents-list-not-data'>
                                Нет документов ожидающих подтверждения
                            </div>
                        </div>
                    ): null
                }
            </div>
            <button className='admin__finance-user-card-documents-button mt-24' onClick={()=>{
                dispatch(setPopupBack('open'))
                setPopupType('FinanceDocuments')
            }}>Открыть список</button>
        </div>
    )
}

export default Documents;