import { setPopupBack } from '../../../../../redux/popup/popupSlice';
import './index.scss'
import { useDispatch } from 'react-redux';
import { setAdminProgramsCheckedSchool } from '../../../../../redux/admin/programs/schools/schoolsSlice';

const SchoolListElem = ({item, classesLinkRef, setPopupType}) =>{
    const {id, city, name} = item;
    const dispatch = useDispatch();

    return(
        <div className={`admin__programs-list-elem`} key={`admin__programs-school-list-elem-${id}`} onClick={(e)=>{
            if(!e.target.classList.contains('click-button'))
                classesLinkRef.current.click()
            dispatch(setAdminProgramsCheckedSchool(item))
        }}>
            <div className='admin__programs-list-elem-top d-flex justify-between'>
                <div className="admin__programs-list-elem-title d-flex justify-between align-center">
                    <div className="admin__programs-list-elem-title-icon schcool"/>
                    <div className="admin__programs-list-elem-title-label">{city.name}</div>
                </div>
                <div className='admin__programs-list-elem-btn-container click-button'>
                    <div className='admin__programs-list-elem-btn setting click-button' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('EditSchool')
                    }}/>
                </div>
            </div>
            <div className="admin__programs-list-elem-subtitle">{name}</div>
        </div>
    )
}

export default SchoolListElem;