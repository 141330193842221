import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';
// import axios from 'axios';





export const fetchUserChildsData = createAsyncThunk(
  'UserChildren/fetchUserChildsData',
  async () => {
    try {
      const response = await axiosProxy.get('/api/v1/parental/profile/get-students')
      if(response.status < 400){
        return {data: response.data.data}                       
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);

export const fetchUserFinanceChildsData = createAsyncThunk(
  'UserChildren/fetchUserFinanceChildsData',
  async () => {
    try {
      const response = await axiosProxy.get('/api/v1/parental/finance/get-student-finance')
      if(response.status === 200){
        return {data: response.data.data}                       
      }
      else{

      }      
    } catch (error) {
        
    }
  },
);


