export const editChildConfirmAplication = {
    id: 1,
    name: 'Name Name Name',
    city: {id: 1, name: 'Москва'},
    school: {id: 1, name: 'Моская гимназия №11'},
    classObj: {id: 1, number: 7, letter: 'A'},
    classNumber: 7,
    classLetter: 'A',
    birthDay: '12.12.2010',

    update: {
      name: 'Name Name Name',
      city: {id: 1, name: 'Москва'},
      school: {id: 1, name: 'Моская гимназия №11'},
      classObj: {id: 1, number: 7, letter: 'A'},
      classNumber: 7,
      classLetter: 'A',
      birthDay: '12.12.2010',
    }
  }