// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../img/admin-header-search-grey-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin__header {
  height: 72px;
  width: 100%;
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E4E4E4;
  background: #fff;
  padding-right: calc(60px - (100vw - 100%)); }
  .admin__header-search {
    max-width: 640px;
    width: calc(100% - 250px);
    height: 42px;
    border-radius: 12px;
    border: 1px solid #E6E6E6;
    position: relative; }
    .admin__header-search-input {
      width: 100%;
      height: 100%;
      display: block;
      padding: 10px 12px 10px 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px; }
      .admin__header-search-input::placeholder {
        font-weight: 500;
        color: #AFAFAF; }
    .admin__header-search::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
      background-size: contain;
      left: 12px;
      top: calc(50% - 10px); }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Header/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,WAAW;EACX,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;EAChB,0CAA0C,EAAA;EAE1C;IACI,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB,EAAA;IAElB;MACI,WAAW;MACX,YAAY;MACZ,cAAc;MACd,4BAA4B;MAE5B,eAAe;MACf,gBAAgB;MAChB,iBAAiB,EAAA;MARpB;QAWO,gBAAgB;QAChB,cAAc,EAAA;IApBzB;MA0BO,WAAW;MACX,kBAAkB;MAClB,WAAW;MACX,YAAY;MACZ,oEAAgF;MAChF,wBAAwB;MACxB,UAAU;MACV,qBAAqB,EAAA","sourcesContent":[".admin__header{\n    height: 72px;\n    width: 100%;\n    padding: 14px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #E4E4E4;\n    background: #fff;\n    padding-right: calc(60px - (100vw - 100%));\n\n    &-search{\n        max-width: 640px;\n        width: calc(100% - 250px);\n        height: 42px;\n        border-radius: 12px;\n        border: 1px solid #E6E6E6;        \n        position: relative;\n\n        &-input{\n            width: 100%;\n            height: 100%;\n            display: block;\n            padding: 10px 12px 10px 40px;\n            \n            font-size: 14px;\n            font-weight: 500;\n            line-height: 21px;\n\n            &::placeholder{\n                font-weight: 500;\n                color: #AFAFAF;\n            }\n\n        }\n\n        &::before{\n            content: '';\n            position: absolute;\n            width: 20px;\n            height: 20px;\n            background: url(../../../img/admin-header-search-grey-icon.svg) no-repeat center;\n            background-size: contain;\n            left: 12px;\n            top: calc(50% - 10px);\n        }                \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
