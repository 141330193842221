
const DefaultTabelLine = () =>{
    return(
        <div className='admin__tabel-table-line d-flex justify-between align-center'>
            <div className="admin__tabel-table-line-item name">
                <div className='admin__tabel-table-line-item-container name'></div>
            </div>
            <div className="admin__tabel-table-line-item lesson"/>
        </div>
    )
}

const DefaultTabel = ({classes}) =>{
    return(
        <div className={`admin__tabel-table ${classes}`}>
                    <div className='admin__tabel-table-line title d-flex justify-between align-center'>
                        <div className="admin__tabel-table-line-item name">
                            <div className='admin__tabel-table-line-item-container name'>Ученики</div>
                        </div>
                        <div className="admin__tabel-table-line-item date"/>
                        <button className='admin__tabel-table-line-title-button d-flex justify-center align-center deactive-button'>
                            <div className='admin__tabel-table-line-title-button-icon'/>
                        </button>
                    </div>
                    
                    <div className='admin__tabel-table-container default'>
                        {
                            [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(item=><DefaultTabelLine key={item}/>)
                        }
                    </div>
                    <div className="admin__tabel-table-buttons d-flex justify-end">
                        <button className={`admin__tabel-table-buttons-item deactive-button`}>
                            Отменить изменения
                        </button>
                        <button className={`admin__tabel-table-buttons-item save deactive-button`}>
                            Сохранить изменения
                        </button>
                    </div>
                </div>
    )
}

export default DefaultTabel;