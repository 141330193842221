export const programs = [
    {
        "id": "a9e80000-f060-1656-bcbe-08dce2faff71",
        "name": "Начало олимпиадной математики, ступень 3",
        "price": 13500,
        "permittedChildren": [
        ],
        "conectedChildren": [
        ],
        "awaitsDisabledChildren": [
        ],
        "awaitsConectedChildren": [
        ]
    },
    {
        "id": "a9e80000-f060-1656-71f8-08dce2faff19",
        "name": "Юный физик, ступень 3, ступень 3",
        "price": 13500,
        "permittedChildren": [
        ],
        "conectedChildren": [
        ],
        "awaitsDisabledChildren": [
        ],
        "awaitsConectedChildren": [
        ]
    }
]