import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminProgramStudentsData, fetchAdminProgramStudentsSubgroupData } from './asyncActions';
import { programsData } from './../../../../defaultData/admin/programs/programs';
import { students, studentsSubgroup } from '../../../../defaultData/admin/students/students';

const initialState = {
  adminProgramsStudentsData:[
    // ...students
  ],

  adminProgramsStudentsSubgroupData: [
  //  ...studentsSubgroup
  ],

  adminProgramStudentsCheckedTeacher: {
    firstGroupTeacher: {
        id: 0, 
        name: ''
    }, 
    secondGroupTeacher: {
        id: 0, 
        name: ''
    }
  }
};

const adminProgramStudents = createSlice({
  name: 'AdminProgramStudents',
  initialState,
  reducers: {
    setAdminProgramsStudentsData(state, action) {
      state.adminProgramsStudentsData = action.payload;      
    },
    setAdminProgramsStudentsDataEdit(state, action) {
      state.adminProgramsStudentsData = state.adminProgramsStudentsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },

    setAdminProgramsStudentsSubgroupDataEdit(state, action) {
      state.adminProgramsStudentsSubgroupData = action.payload;
    },


  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminProgramStudentsData.pending, (state, action) => {
      state.adminProgramsStudentsData = []
    });
    builder.addCase(fetchAdminProgramStudentsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.students && Array.isArray(action.payload.students)) state.adminProgramsStudentsData = action.payload.students;
    });
    builder.addCase(fetchAdminProgramStudentsData.rejected, (state, action) => {
    });



    builder.addCase(fetchAdminProgramStudentsSubgroupData.pending, (state, action) => {
      state.adminProgramsStudentsSubgroupData = [];
    });
    builder.addCase(fetchAdminProgramStudentsSubgroupData.fulfilled, (state, action) => {
      if(action.payload && action.payload.students && Array.isArray(action.payload.students)) state.adminProgramsStudentsSubgroupData = action.payload.students;
    });
    builder.addCase(fetchAdminProgramStudentsSubgroupData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminProgramStudents;


export default reducer;

export const { 
    setAdminProgramsStudentsData,
    setAdminProgramsStudentsDataEdit,

    setAdminProgramsStudentsSubgroupDataEdit
} = actions;

