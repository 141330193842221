import './index.scss'
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import { selectAdminProgramsClasses } from './../../../../../redux/admin/programs/classes/selectors';
import { setAdminProgramsClassesDataAdd, setAdminProgramsClassesDataEdit } from '../../../../../redux/admin/programs/classes/classesSlice';
import { selectAdminProgramsSchools } from './../../../../../redux/admin/programs/schools/selectors';


const ClassEdit = ({type, popupBack, closePopup, setPopupType}) =>{
    const dispatch = useDispatch()

    const { adminProgramsCheckedClass:checkClass } = useSelector(selectAdminProgramsClasses);
    const { adminProgramsCheckedSchool:checkSchool } = useSelector(selectAdminProgramsSchools);

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)


    const [formData, setFormData] = useState({
        number: '',
        letter: '',
    })

    useEffect(()=>{
        if(type === 'AddClass'){
            setFormData({
                number: '',
                letter: '',
            })
        }
        else{
            setFormData(checkClass)
        }
        
    }, [popupBack])

    const AddOrEditClassFunc = () =>{
        let postData = {
            class: `${formData.number}${formData.letter}`,
            schoolId: checkSchool.id
        }
        if(type !== 'AddClass') postData.id = formData.id;
        setError('')
        if(formData.number && formData.letter){
            let postData = {schoolId: checkSchool.id}
            if(type === 'AddClass'){
                postData = {
                    ...postData,
                    number: formData.number,
                    letter: formData.letter,
                    discount: 0,
                }
            }
            else{
                if(formData.number !== checkClass.number) postData.number = formData.number;                
                if(formData.letter !== checkClass.letter) postData.letter = formData.letter;
            }

            setLoading(true)          
            
            if(type === 'AddClass' || Object.keys(postData).length > 1){    
                axiosProxyCatchPost({
                    url: type === 'AddClass'? '/api/v1/administrative/programs/create-class': '/editClass',
                    data: postData,
                    resolved: (data)=>{
                        if(type === 'AddClass'){
                            dispatch(setAdminProgramsClassesDataAdd({
                                id: data.id,
                                number: formData.number,
                                letter: formData.letter.toUpperCase(),
                                school: checkSchool,
                                studentsLength: 0
                
                            }))
                        }
                        else{
                            dispatch(setAdminProgramsClassesDataEdit({...formData, letter: formData.letter.toUpperCase()}))
                        }
                        closePopup()
                        setLoading(false)
                    },
                    rejected: (error)=>{
                        // console.log(error);
                        setLoading(false)
                        // setError(error.data.detail)
                    }
                })
            }
            else setError('Необходимо совершить какие-то изменения перед сохранением*')
        
        }
        else setError('Необходимо заполнить все поля*')
    }

    return(
        <div className={`popup__container class-edit`} onClick={(e)=>{
            if(error){
                setError('')
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    {(type==='AddClass')? 'Создание класса': 'Настройка класса'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            <div className='popup__subtitle mb-24'>
                {checkSchool.school}
            </div>
            
            <label className='popup__label mb-6'>Название</label>
            <div className='d-flex'>
                <input type="number" value={formData.number} className='popup__input wd-50-mr-16' onChange={(e)=>{
                    if(+e.target.value<12){
                        setFormData({...formData, number:e.target.value === ''? '': +e.target.value})
                    }
                }}/>
                <input type="text" value={formData.letter} className='popup__input wd-50-mr-16' onChange={(e)=>{
                    if((e.target.value.match(/^[а-яА-Я]+$/)) || e.target.value === ''){
                        setFormData({...formData, letter: e.target.value})
                    }
                    else if(e.target.value.match(/^[а-яА-Я]+$/) === null){
                        if(typeof +e.target.value === 'number' && +e.target.value > 0 || e.target.value === '0') setError('Необходимо ввести букву*')
                        else setError('Допускаются только русские буквы*')
                    }
                }}/>
            </div>

            {/* <label htmlFor="" className='popup__label mb-4'>Скидка</label>
            <div className='popup__additionaly-input mb-16 admin-poup__class-edit-discount'>
                <input name='' type="number" placeholder='' value={formData.address} className="popup__additionaly-input-input admin-poup__class-edit-discount-input" onChange={(e) => setFormData({...formData, address: e.target.value})}/>
                <div className='popup__additionaly-input-val'>{formData.address}{formData.address? '%': ''}</div>
            </div> */}

            <div className='popup__error'>{error}</div> 

            <button className={`popup__button ${loading? 'deactive-button': ''}`} onClick={AddOrEditClassFunc}>{(type==='AddClass')? 'Добавить': 'Сохранить'}</button>
            {
                (type !== 'AddClass')? <button className={`popup__button cancel delete mt-16 ${loading? 'deactive-button': ''}`} onClick={()=>{
                    closePopup()
                    setPopupType('Delete')
                }}>Удалить класс</button>: null
            }
        </div>
    )
}

export default ClassEdit;