export const programsData = [ 
    {
        id: 1,
        name: 'Робоинженерия',
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 2,
        name: 'Робоинженерия2',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 3,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 4,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            }
        ]
    }, 
    {
        id: 5,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 6,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 7,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 8,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 9,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    {
        id: 10,
        name: 'Робоинженерия',
        summ: 49500,
        lessonsCount: 34,
        teachers: [
            {
                id: 1,
                name: 'Фаимлия Имя',
            },
            {
                id: 2,
                name: 'Фаимлия Имя',
            },
        ]
    }, 
    
]